import { FetchRest } from '@config/httpClient';

interface AddAndDeleteProductInFolderServiceProps {
  userId: string;
  products: Products[];
  lang: string;
}

interface Products {
  productId: string;
  folderId: string;
  action: 'delete' | 'add';
}

export const addAndDeleteProductInFolderService = async (
  props: AddAndDeleteProductInFolderServiceProps
) => {
  const { userId, products, lang } = props;

  const productsToDelete = products.filter(
    (product) => product.action === 'delete'
  );
  const productsToAdd = products.filter((product) => product.action === 'add');

  const uriAddToFolder = `/products/add_product_to_folder`;
  const uriDeleteFromFolder = `/products/delete_product_in_folder`;

  for (const product of productsToDelete) {
    const params = `userId=${userId}&folderId=${product.folderId}`;
    const uriFormated = `${uriDeleteFromFolder}/${product.productId}?${params}`;

    const response = await FetchRest<any>({
      uri: uriFormated,
      requireAuth: true,
      method: 'DELETE',
    });

    if (response.error) {
      const message = response.messages[lang];
      throw new Error(message);
    }
  }

  for (const product of productsToAdd) {
    const response = await FetchRest<any>({
      uri: uriAddToFolder,
      requireAuth: true,
      method: 'POST',
      body: {
        userId,
        folderId: product.folderId,
        productId: product.productId,
      },
    });

    if (response.error) {
      const message = response.messages[lang];
      throw new Error(message);
    }
  }

  return { ok: true };
};
