import * as React from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertStatus,
  Box,
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { AiOutlineClose } from 'react-icons/ai';

import {
  recoveryFormInitialValues,
  recoveryFormModel,
  recoveryFormSchema,
} from '@forms/recovery_pass';

import useStepperForm from '@hooks/useStepperForm';
import updatePassword from '@services/user/updatePassword';
import generateRecoveryToken from '@services/user/generateRecoveryToken';

import { LoadingFormPage } from '@components/LoadingFormPage';
import { SeparatorWithText } from '@components/SeparatorWithText';
import { ChangePasswordForm, VerifyToken } from '@components/RecoveryPassForm';

const { useEffect, useState } = React;

interface AlertState {
  type: AlertStatus;
  message: string;
}

export const PassRecoveryPage = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const { activeStep, isLastStep, handleNextStep, handleChangeActiveStep } =
    useStepperForm({ steps: ['email', 'message', 'verifiyng', 'change'] });

  const [searchParams] = useSearchParams();

  const [hasAlert, setHasAlert] = useState<null | AlertState>(null);
  const [userIdFromToken, setUserIdFromToken] = useState('');

  const handleSendForm = async (data: any, helpers?: FormikHelpers<any>) => {
    helpers?.setSubmitting(true);

    try {
      let formatedData = {};
      let response = null;

      if (activeStep === 0) {
        formatedData = {
          email: data.email,
          lang: i18n.language.substring(0, 2),
        };

        response = await generateRecoveryToken(formatedData);
      } else {
        formatedData = {
          email: data.email,
          lang: i18n.language.substring(0, 2),
        };
      }

      if (response?.error) {
        setHasAlert({
          type: 'error',
          message: response?.messages[i18n.language.substring(0, 2) || 'en'],
        });

        helpers?.setSubmitting(false);
        return;
      }

      helpers?.setSubmitting(false);
      handleChangeActiveStep(1);
    } catch (error) {
      const customMessages: any = {
        en: `An unexpected error occurred, please try again later.`,
        es: `Ocurrio un error inesperado, por favor intentalo de nuevo más tarde.`,
        pt: `Ocorreu um erro inesperado, tente novamente mais tarde.`,
      };

      setHasAlert({
        type: 'error',
        message: customMessages[i18n.language.substring(0, 2) || 'en'],
      });

      helpers?.setSubmitting(false);
    }
  };

  const handleChangePassword = async (
    data: any,
    helpers?: FormikHelpers<any>
  ) => {
    helpers?.setSubmitting(true);

    try {
      const formatedData = {
        userId: userIdFromToken,
        password: data.password,
        confirm: data.confirm,
      };

      const response = await updatePassword(formatedData);

      if (response.error) {
        setHasAlert({
          type: 'error',
          message: response.messages[i18n.language.substring(0, 2)],
        });

        helpers?.setSubmitting(false);
        return;
      }

      setHasAlert({
        type: 'success',
        message: response.data.messages[i18n.language.substring(0, 2)],
      });

      setTimeout(() => {
        navigate('/login', { replace: true });
      }, 2500);
    } catch (error) {
      const customMessages: any = {
        en: `An unexpected error occurred, please try again later.`,
        es: `Ocurrio un error inesperado, por favor intentalo de nuevo más tarde.`,
        pt: `Ocorreu um erro inesperado, tente novamente mais tarde.`,
      };

      setHasAlert({
        type: 'error',
        message: customMessages[i18n.language.substring(0, 2) || 'en'],
      });

      helpers?.setSubmitting(false);
    }
  };

  const onSubmitForm = (data: any, helpers: FormikHelpers<any>) => {
    handleNextStep({
      values: data,
      actions: helpers,
      sendFormInmediatly: activeStep === 0 || activeStep === 3,
      submitForm: activeStep === 3 ? handleChangePassword : handleSendForm,
    });
  };

  const handleCleanAlert = () => setHasAlert(null);

  const currentSchema = recoveryFormSchema(
    [t('emailValidation'), t('emailValidationEmpty')],
    [
      t('passValidation'),
      t('passValidationMin'),
      t('confirmValidation'),
      t('confirmValidationRequired'),
    ]
  )[activeStep];

  const textColorRequiredFields = useColorModeValue('gray', 'gray.300');

  useEffect(() => {
    if (searchParams.get('token')) handleChangeActiveStep(2);
  }, [searchParams, handleChangeActiveStep]);

  return (
    <Box mt={4}>
      <Text as="h2" fontSize="3xl" fontWeight="bold">
        {t('recoveryPassTitle')}
      </Text>
      {(activeStep === 0 || activeStep === 3) && (
        <Text
          mb={4}
          mt={1}
          as="p"
          color={textColorRequiredFields}
          fontSize="small"
          fontWeight="medium"
        >
          {t('requiredFieldsText')}
        </Text>
      )}
      <Box>
        <Formik
          initialValues={recoveryFormInitialValues}
          validationSchema={currentSchema}
          onSubmit={onSubmitForm}
        >
          {({ isValid, isSubmitting }: FormikProps<any>) => (
            <Form id={recoveryFormModel.formId}>
              {isSubmitting && (activeStep === 0 || activeStep === 3) && (
                <LoadingFormPage />
              )}
              {_renderStepContent({
                step: activeStep,
                handleChangeStep: handleChangeActiveStep,
                updateUserId: setUserIdFromToken,
              })}

              <Collapse in={!!hasAlert} animateOpacity>
                <Alert
                  status={hasAlert?.type || 'info'}
                  borderRadius={4}
                  mt={2}
                >
                  <AlertIcon />
                  <AlertDescription fontSize="sm" lineHeight={1.2}>
                    {hasAlert?.message}
                  </AlertDescription>
                  {hasAlert?.type !== 'success' && (
                    <Icon
                      as={AiOutlineClose}
                      position="absolute"
                      right="8px"
                      top="8px"
                      cursor="pointer"
                      onClick={handleCleanAlert}
                    />
                  )}
                </Alert>
              </Collapse>

              <Box
                mt={4}
                display="flex"
                justifyContent={
                  activeStep === 0 || activeStep === 3
                    ? 'flex-end'
                    : 'space-between'
                }
                alignItems="center"
              >
                {activeStep === 1 && (
                  <Button
                    isDisabled={isSubmitting}
                    variant="outline"
                    onClick={() => navigate('/login', { replace: true })}
                  >
                    {t('returnToHome')}
                  </Button>
                )}
                {(activeStep === 0 || activeStep === 3) && (
                  <Button
                    type="submit"
                    variant="solid"
                    colorScheme="brand"
                    isDisabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                  >
                    {isLastStep
                      ? t('updatePassButtonText')
                      : t('continueButtonText')}
                  </Button>
                )}
              </Box>

              <SeparatorWithText my={6} text="" />

              <Text align="center">
                {t('rememberPassTextLink')}
                <Link as={RouterLink} to="/login" ml={1} color="blue.300">
                  {t('haveAccountLink')}
                </Link>
              </Text>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

interface RenderStepContentProps {
  step: number;
  handleChangeStep: (step: number) => void;
  updateUserId: React.Dispatch<React.SetStateAction<string>>;
}

const _renderStepContent = ({
  step,
  handleChangeStep,
  updateUserId,
}: RenderStepContentProps) => {
  switch (step) {
    case 0:
      return <EmailForm />;
    case 1:
      return <EmailSended />;
    case 2:
      return (
        <VerifyToken
          updateUserId={updateUserId}
          handleChangeStep={handleChangeStep}
        />
      );
    case 3:
      return <ChangePasswordForm />;
    default:
      return <div>Step not found</div>;
  }
};

const EmailForm = () => {
  const { t } = useTranslation();

  return (
    <Field name="email">
      {({ field, form }: any) => (
        <FormControl isInvalid={form.errors.email && form.touched.email} mb={2}>
          <FormLabel htmlFor="email">{t('emailField')}*</FormLabel>
          <Input {...field} id="email" placeholder={t('emailField')} />
          <FormErrorMessage>{form.errors.email}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

const EmailSended = () => {
  const { t } = useTranslation();

  const colorText = useColorModeValue('brand.600', 'gray.300');

  return (
    <Box mt={2} color={colorText}>
      <Text>{t('emailSendedForRecoveryOne')}</Text>
      <Text mt={1}>
        {t('emailSendedForRecoveryTwo')}{' '}
        <Link href="mailto:soporte@viraly.com" target="_blank" color="blue.300">
          {t('emailSenderForRecoverySupport')}
        </Link>
      </Text>
    </Box>
  );
};
