import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';

import { PasswordInput } from '@components/PasswordInput/PasswordInput';

import { registerFormModel } from '@forms/register';

export const ChangePasswordForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Field name="password">
        {({ field, form }: any) => (
          <FormControl
            isInvalid={form.errors.password && form.touched.password}
            mb={2}
          >
            <FormLabel htmlFor="password">
              {t(registerFormModel.formField.password.label) + '*'}
            </FormLabel>
            <PasswordInput
              {...field}
              id="password"
              placeholder={t(registerFormModel.formField.password.label)}
            />
            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="confirm">
        {({ field, form }: any) => (
          <FormControl
            isInvalid={form.errors.confirm && form.touched.confirm}
            mb={2}
          >
            <FormLabel htmlFor="confirm">
              {t(registerFormModel.formField.confirm.label) + '*'}
            </FormLabel>
            <PasswordInput
              {...field}
              id="confirm"
              placeholder={t(registerFormModel.formField.confirm.label)}
            />
            <FormErrorMessage>{form.errors.confirm}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </>
  );
};
