import {
  Button,
  ButtonProps,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { IoMail } from "react-icons/io5";
import { MdOutlineContactPhone } from "react-icons/md";

export const ShowContacts = (props: ButtonProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("contact")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={4}>
            <p>{t("contactModalDescription")}</p>

            <Box display="flex" flexDirection="row" alignItems="center" mt={4}>
              <IoMail size={20} style={{ marginRight: 10 }} />{" "}
              manager.viraly@gmail.com
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
              <MdOutlineContactPhone size={20} style={{ marginRight: 10 }} />{" "}
              +57 3215330715
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Tooltip
        hasArrow
        label={t("contact")}
        bg="gray.600"
        borderRadius="md"
        color="white"
      >
        <Button
          aria-label={t("contact")}
          onClick={() => onOpen()}
          _focus={{ boxShadow: "none" }}
          w="fit-content"
          {...props}
        >
          {t("contact")}
        </Button>
      </Tooltip>
    </>
  );
};
