import httpClient from "@config/httpClient";
import { getUserTokenAndHeader } from "@context/AuthContext";

const logoutUser = async (userEmail: string) => {
  try {
    const token = await getUserTokenAndHeader();
    await httpClient.get(`/users/logout?userEmail=${userEmail}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return true;
  } catch (error) {
    return false
  }
};

export default logoutUser;