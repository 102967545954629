import { FetchRest } from "@config/httpClient";

interface DeleteProductsFolderServiceProps {
  userId: string;
  folderId: string;
  lang: string;
};

export const deleteProductsFolderService = async (props: DeleteProductsFolderServiceProps) => {
  const { userId, folderId, lang } = props;

  const params = `userId=${userId}`;
  const uriDeleteFolder = `/products/delete_folder/${folderId}?${params}`;

  const response = await FetchRest<{}>({
    uri: uriDeleteFolder,
    requireAuth: true,
    method: 'DELETE'
  });

  if (response.error) {
    const message = response.messages[lang];
    throw { message, status: response.status };
  }

  return response.data;
};