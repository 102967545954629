import { FetchRest } from "@config/httpClient";

interface GetProductMetadataProps {
  userId: string;
  productId: string;
  lang: string;
};

export const getProductMetadata = async (props: GetProductMetadataProps) => {
  const { userId, productId, lang } = props;

  const uri = `/products/get_product_metadata?userId=${userId}&productId=${productId}`;

  const response = await FetchRest<GetProductMetadataProps>({ uri, requireAuth: true });

  if (response.error) {
    const message = response.messages[lang];
    throw new Error(message);
  };

  const data = response.data.data;

  return data;

};