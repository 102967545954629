import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react';

import { registerFormModel } from '@forms/register';

export const UserPersonalInfoForm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Field name="firstName">
        {({ field, form }: any) => (
          <FormControl
            isInvalid={form.errors.firstName && form.touched.firstName}
            mb={2}
          >
            <FormLabel htmlFor="firstName">
              {t(registerFormModel.formField.firstName.label) + '*'}
            </FormLabel>
            <Input
              {...field}
              id="firstName"
              placeholder={t(registerFormModel.formField.firstName.label)}
            />
            <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="lastName">
        {({ field, form }: any) => (
          <FormControl
            isInvalid={form.errors.lastName && form.touched.lastName}
            mb={2}
          >
            <FormLabel htmlFor="lastName">
              {t(registerFormModel.formField.lastName.label) + '*'}
            </FormLabel>
            <Input
              {...field}
              id="lastName"
              placeholder={t(registerFormModel.formField.lastName.label)}
            />
            <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </>
  );
};
