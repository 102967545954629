import { addAdminUserService } from '@services/user/addAdminUser';
import { blockUserService } from '@services/user/blockUser';
import { getAllUsersService, GetAllUsersServiceProps } from '@services/user/getAllUsers';
import { loginUserService, } from '@services/user/loginUser';
import { updateReferralCodeService } from '@services/user/updateReferralCode';
import { useMutation, useQuery } from 'react-query';

const mutationLoginUserKey = 'login_user';
const getAllUsersKey = 'users';
const addUserAdminKey = 'add_user';
const blockUserKey = 'block_user';
const updateReferralCodeKey = 'update_referral_code';

export const useMutateLoginUser = () => {
  return useMutation(loginUserService, {
    mutationKey: [mutationLoginUserKey],
    retry: false
  });
};

export const useGetAllUsers = (props: GetAllUsersServiceProps) => {

  const { lang, limit, ...restProps } = props;

  return useQuery({
    queryKey: [getAllUsersKey, restProps],
    queryFn: () => getAllUsersService(props),
    staleTime: Infinity
  });
};

export const useAddUserAdmin = () => {
  return useMutation(addAdminUserService, {
    mutationKey: [addUserAdminKey],
    retry: false,
  })
};

export const useUpdateReferralCode = () => {
  return useMutation(updateReferralCodeService, {
    mutationKey: [updateReferralCodeKey],
    retry: false,
  });
}

export const useBlockUser = () => {
  return useMutation(blockUserService, {
    mutationKey: [blockUserKey],
    retry: false,
  });
};