import { AxiosError } from 'axios';

const handleHttpClientErrors = (error: AxiosError) => {

  if (error.response) {

    const data = error.response.data;

    if (data?.messages) return {
      status: error.response.status,
      messages: data?.messages
    };

    const customMessages = {
      en: `An unexpected error occurred, please try again later.`,
      es: `Ocurrio un error inesperado, por favor intentalo de nuevo más tarde.`,
      pt: `Ocorreu um erro inesperado, tente novamente mais tarde.`
    };

    return {
      status: error.response.status,
      messages: customMessages
    };

  } else if (error.request) {

    console.log('Ocurrio un error donde no se obtuvo respuesta de la petición');
    console.log('Error ocurrido: ', error.request);

    const customMessages = {
      en: `An unexpected error occurred, please try again later.`,
      es: `Ocurrio un error inesperado, por favor intentalo de nuevo más tarde.`,
      pt: `Ocorreu um erro inesperado, tente novamente mais tarde.`
    };

    return {
      status: 500,
      messages: customMessages
    };

  } else {

    console.log('Ocurrio un error que no es de Http, revisar');
    console.log('Error ocurrido: ', error.message);

    const customMessages = {
      en: `An unexpected error occurred, please try again later.`,
      es: `Ocurrio un error inesperado, por favor intentalo de nuevo más tarde.`,
      pt: `Ocorreu um erro inesperado, tente novamente mais tarde.`
    };

    return {
      status: 500,
      messages: customMessages
    };

  }

};

export default handleHttpClientErrors;