import { Suspense, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { AuthLayout } from "@layouts/AuthLayout";
import { DashboardLayout } from "@layouts/DashboardLayout";
import { LoadingScreen } from "@components/LoadingScreen";
import { useAuth } from "@context/AuthContext";
import referralsCodes from "../data/referralsCodes.json";

import {
  setCheckingSessionAction,
  removeCheckingSessionAction,
  loginUserAction,
  logoutUserAction,
} from "@actions/authActions";

import renewUser from "@services/user/renewUser";

import { routes, Route as CustomRoute } from "./routes";
import { useSetRecoilState } from "recoil";
import { userProductFoldersState } from "@store/users/atoms";

const publicRoutes = routes.filter((route) => !route.isProtected);
const privateRoutes = routes.filter((route) => route.isProtected);

export const Navigation = () => {
  const { dispatch, asyncDispatch, authState, auth0 } = useAuth();
  const setUserProductFoldersState = useSetRecoilState(userProductFoldersState);

  useEffect(() => {
    dispatch(setCheckingSessionAction());

    renewUser({ auth0 })
      .then((data) => {
        if (data.isAuthenticated) {
          dispatch(loginUserAction(data.userData, data.authType));
          setUserProductFoldersState((data.userData as any).folders);
        } else asyncDispatch(logoutUserAction());
      })
      .catch(() => asyncDispatch(logoutUserAction()))
      .finally(() => dispatch(removeCheckingSessionAction()));
  }, [asyncDispatch, auth0, dispatch, setUserProductFoldersState]);

  if (authState.isCheckingSession) return <LoadingScreen />;

  return (
    <Suspense fallback={<LoadingScreen />}>
      <BrowserRouter>
        <Routes>
          <Route element={<AuthLayout />}>
            {publicRoutes.map(({ path, Component }: CustomRoute) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
            <Route path="/*" element={<Navigate to={routes[0].to} replace />} />
          </Route>

          <Route element={<DashboardLayout />}>
            {privateRoutes.map(({ path, Component }: CustomRoute) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
            <Route path="/*" element={<Navigate to={routes[0].to} replace />} />
          </Route>

          {referralsCodes.listReferralsCodes.map((item) => {          
            return (
              <Route
                key={`${item.path}Code`}
                path={`/${item.path}`}
                element={
                  <Navigate
                    to={`/register?registerType=viraly&referralCode=${item.referralCode}`}
                    replace
                  ></Navigate>
                }
              ></Route>
            )
          })}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};
