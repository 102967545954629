import { useColorMode, Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import { BsSun, BsMoonStarsFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

export const DarkModeToggle = (props: ButtonProps) => {
  const { colorMode, setColorMode } = useColorMode();
  const { t } = useTranslation();

  const handleChangeMode = () => {
    if (colorMode === 'light') setColorMode('dark');
    if (colorMode === 'dark') setColorMode('light');
  };

  return (
    <Tooltip hasArrow label={t('themeColor')} bg='gray.600' borderRadius="md" color="white">
      <Button
        aria-label={t('themeColor')}
        onClick={() => handleChangeMode()}
        _focus={{ boxShadow: 'none' }}
        w="fit-content"
        {...props}
      >
        {colorMode === 'light' ? <BsMoonStarsFill /> : <BsSun />}
      </Button>
    </Tooltip>
  );
};
