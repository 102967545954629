import { FetchRest } from "@config/httpClient";

interface GetProductProps {
  userId: string;
  productId: string;
  lang: string;
};

export const getProductService = async (props: GetProductProps) => {
  const { userId, productId, lang } = props;

  const uri = `/products/get_product?userId=${userId}&productId=${productId}`;

  const response = await FetchRest<GetProductProps>({ uri, requireAuth: true });

  if (response.error) {
    const message = response.messages[lang];
    throw { message, status: response.status };
  }

  return response.data;
};