import * as React from 'react';
import { Lottie } from '@crello/react-lottie';

import { useSearchParams } from 'react-router-dom';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import { useAuth } from '@context/AuthContext';

import httpClient from '@config/httpClient';
import handleHttpClientErrors from '@utils/handleHttpClientErrors';

import { loginUserAction } from '@actions/authActions';

import errorAnimation from '@assets/lotties/auth_error.json';
import loginAnimation from '@assets/lotties/login_work.json';
import { useSetRecoilState } from 'recoil';
import { userProductFoldersState } from '@store/users/atoms';

const { useEffect, useState, useCallback, useRef } = React;

const loadingLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: loginAnimation,
  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
};

const errorLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: errorAnimation,
  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
};

export const VerifyAuth = () => {
  const isMounted = useRef(true);

  const { auth0, dispatch } = useAuth();
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();

  const [hasError, setHasError] = useState<null | string>(null);

  const setUserProductFoldersState = useSetRecoilState(userProductFoldersState);

  const getUserInfoCallback = useCallback(async () => {
    try {
      let baseURI = `/users/verify_auth`;
      let bodyData = {};

      await auth0.handleRedirectCallback();
      const user = await auth0.getUser();

      const authType = user?.sub?.includes('google') ? 'google' : 'facebook';
      const deviceUid = localStorage.getItem('deviceId') ?? '';

      bodyData = {
        authType,
        payload: user,
        deviceId: deviceUid,
      };

      if (searchParams.get('type') === 'register') {
        const typeUser = searchParams.get('registerType')
          ? searchParams.get('registerType') === 'affiliate_program'
            ? 'mentor'
            : 'client'
          : 'client';

        const referralCode =
          typeUser === 'client' ? '' : searchParams.get('referralCode') || '';

        baseURI = '/users/register_with_social';
        bodyData = {
          authType,
          userType: typeUser,
          referralCode,
          payload: user,
        };
      }

      const response = await httpClient.post(baseURI, bodyData);
      const data = response.data;

      if (!data.continue) {
        const errorMsg = data.messages[i18n.language.substring(0, 2)] || '';

        const redirectoToRegisterNewDevice = data.redirectNewDevice
          ? `&newDevice=${data.userId}`
          : '';

        let urlReturnTo = `${
          process.env.REACT_APP_VIRALY_URL
        }/login?error=${encodeURI(errorMsg)}${redirectoToRegisterNewDevice}`;

        if (isMounted.current) setHasError(errorMsg);

        if (data.redirectRegister)
          urlReturnTo = `${
            process.env.REACT_APP_VIRALY_URL
          }/register?error=${encodeURI(errorMsg)}`;

        setTimeout(() => {
          auth0.logout({ returnTo: urlReturnTo });
        }, 1000);
        return;
      }

      if (searchParams.get('type') === 'register')
        localStorage.setItem('deviceId', data.deviceId);

      localStorage.setItem('__authtype__', 'social');
      localStorage.setItem('__useremail__', user?.email || '');

      setUserProductFoldersState(data.userData.folders || []);
      dispatch(loginUserAction(data.userData, 'social'));
    } catch (error: any) {
      if (error?.message !== 'Invalid state') {
        const { messages } = handleHttpClientErrors(error);
        const mmessageByLanguage =
          messages[i18n.language.substring(0, 2)] || '';
        if (isMounted.current) setHasError(mmessageByLanguage);
        auth0.logout();
      }
    }
  }, [dispatch, searchParams, auth0, i18n.language]);

  const bgColor = useColorModeValue('gray.100', 'gray.800');

  useEffect(() => {
    getUserInfoCallback();
  }, [getUserInfoCallback]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Box
      alignItems="center"
      bgColor={bgColor}
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      left={0}
      pos="fixed"
      top={0}
      width="100%"
      zIndex={1}
    >
      <Box mt={-28}>
        <Lottie
          config={hasError ? errorLottieOptions : loadingLottieOptions}
          height="350px"
          width="350px"
        />
      </Box>
      {hasError && (
        <>
          <Text fontSize="3xl">Oops!</Text>
          <Box width="80%" mx="auto">
            <Text fontSize="2xl" align="center">
              {hasError}
            </Text>
          </Box>
        </>
      )}
      {!hasError && (
        <>
          <Text fontSize="2xl">
            {searchParams.get('type') === 'login'
              ? t('verifyingAuthOne')
              : t('userSignUpWithSocial')}
          </Text>
          <Text fontSize="2xl">{t('verifyingAuthTwo')}</Text>
        </>
      )}
    </Box>
  );
};
