import axios, { Method } from 'axios';
import handleHttpClientErrors from "@utils/handleHttpClientErrors";

import { getUserTokenAndHeader } from "@context/AuthContext";

interface FetchRestProps<T> {
  requireAuth: boolean;
  uri: string;
  method?: Method;
  body?: T;
  headers?: any;
};

export type QueryKeyProps<T> = [key: string, vars: T];

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URI,
  headers: {
    'Content-type': 'application/json'
  }
});

export const FetchRest = async <T>(props: FetchRestProps<T>) => {

  const { requireAuth, uri, method, body, headers } = props;

  try {

    let headerAuth = {};

    if (requireAuth) headerAuth = await getUserTokenAndHeader();

    const requestHeaders = {
      ...headers,
      ...headerAuth,
    };

    const response = await httpClient(uri, {
      method,
      headers: requestHeaders,
      data: body
    });

    const data = response.data;

    return { error: false, data };

  } catch (error: any) {
    const { messages, status } = handleHttpClientErrors(error);
    return { error: true, messages, status };
  }

};

export const FetchRestEBS = async <T>(props: FetchRestProps<T>) => {

  const { requireAuth, uri, method, body, headers } = props;

  try {

    let headerAuth = {};

    if (requireAuth) headerAuth = await getUserTokenAndHeader();

    const requestHeaders = {
      ...headers,
      ...headerAuth,
    };

    const response = await axios(uri, {
      method,
      headers: requestHeaders,
      data: body
    });

    const data = response.data;

    return { error: false, data };

  } catch (error: any) {
    const { messages, status } = handleHttpClientErrors(error);
    return { error: true, messages, status };
  }

};

export const formatErrorMessageQuery = (error: any, lang: string) => {
  let message = '';

  if (error?.message) {
    message = error.message;
  } else if (typeof error === 'string') {
    message = error;
  } else {
    const messages: any = {
      es: 'Ocurrió un error guardando el producto, asegúrate de que la información es correcta.',
      en: 'An error occurred saving the product, make sure the information is correct.',
      pt: 'Ocorreu um erro ao salvar o produto, verifique se as informações estão corretas.',
    };

    const meessageError = messages[lang];

    message = meessageError;
  }

  return message;
};

export default httpClient;