const getDefaultProducType = (allowedTypes: string) => {
  const typesArray = allowedTypes.split(',');

  if (typesArray.length === 0) return 'facebook';

  if (typesArray.length === 1) return typesArray[0];

  if (typesArray.length > 1) {
    if (typesArray.includes('facebook')) return 'facebook';
    if (typesArray.includes('google')) return 'google';
    else return typesArray[0];
  }

  if (typesArray.length > 2) return 'facebook';

  return 'facebook';
};

export default getDefaultProducType;