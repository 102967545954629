import httpClient from "@config/httpClient";
import handleHttpClientErrors from "@utils/handleHttpClientErrors";

interface NewDeviceRegisterProps {
  userId: string;
  language: string;
  deviceIdRegistered: string;
};

const requestNewDeviceRegister = async (userData: NewDeviceRegisterProps) => {

  try {

    const response = await httpClient('/users/request_new_device_register', {
      method: 'POST',
      data: userData
    });

    const data = response.data;

    return { error: false, data };

  } catch (error: any) {
    const { messages, status } = handleHttpClientErrors(error);
    return { error: true, messages, status };
  }

};

export default requestNewDeviceRegister;