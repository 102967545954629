import { FetchRest } from "@config/httpClient";

interface DeleteProductFromStoreServiceProps {
  userId: string;
  productId: string;
  lang: string;
  langInterface: string;
};

export const deleteProductFromStoreService = async (props: DeleteProductFromStoreServiceProps) => {
  const { userId, productId, lang, langInterface } = props;

  const params = `userId=${userId}&language=${lang}`;
  const uriDeleteFolder = `/stores/delete_store_product/${productId}?${params}`;

  const response = await FetchRest<{}>({
    uri: uriDeleteFolder,
    requireAuth: true,
    method: 'DELETE'
  });

  if (response.error) {
    const message = response.messages[langInterface];
    throw { message, status: response.status };
  }

  return response.data;
};