import registerFormModel from './registerFormModel';

export interface RegisterFormInitialValuesInterface {
  [x: string]: any
};

const { formField: { firstName, lastName, email, password, confirm, referralCode, terms } } = registerFormModel;

const registerFormInitialValues: RegisterFormInitialValuesInterface = {
  [firstName.name]: '',
  [lastName.name]: '',
  [email.name]: '',
  [password.name]: '',
  [confirm.name]: '',
  [referralCode.name]: '',
  [terms.name]: false,
};

export default registerFormInitialValues;