import * as React from 'react';

import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@context/AuthContext';
import { SideBarMenu } from '@components/Sidebar';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useQueryGetFolders } from '@hooks/useProduct';
import { useSetRecoilState } from 'recoil';
import { userProductFoldersState } from '@store/users/atoms';

const { useEffect } = React;

export const DashboardLayout = () => {
  const { i18n } = useTranslation();
  const { authState } = useAuth();

  const location = useLocation();
  const setUserProductFoldersState = useSetRecoilState(userProductFoldersState);

  const lang = i18n.language.substring(0, 2);
  const userId = authState.user?.userId || '';

  const { data, isFetched } = useQueryGetFolders({ lang, userId });

  const bgColor = useColorModeValue('gray.100', 'gray.800');

  useEffect(() => {
    if (data && isFetched) setUserProductFoldersState(data.folders || []);
  }, [data, isFetched, setUserProductFoldersState]);

  if (!authState.isAuthenticated && !authState.isCheckingSession)
    return (
      <Navigate
        state={{ lastLocation: `${location.pathname}${location.search}` }}
        to="/login"
      />
    );

  return (
    <Box width="100%" height="100%" bg={bgColor}>
      <SideBarMenu>
        <Outlet />
      </SideBarMenu>
    </Box>
  );
};
