import { Auth0Client } from "@auth0/auth0-spa-js";

import { UserDataInterface } from "interfaces/UserDataInterface";
import httpClient from "@config/httpClient";

import { getUserTokenAndHeader } from "@context/AuthContext";

interface renewUserProps {
  auth0: Auth0Client;
};

interface ReturnProps {
  authType: string;
  isAuthenticated: boolean;
  userData: null | UserDataInterface;
};

const renewUser = async (userData: renewUserProps): Promise<ReturnProps> => {

  const { auth0 } = userData;

  try {

    let email = '';
    let baseUri = '/users/renew_user';

    const headerAuth = await getUserTokenAndHeader();

    const authType = localStorage.getItem('__authtype__');

    if (authType === 'social') {

      const isAuthenticated = await auth0.isAuthenticated();

      if (!isAuthenticated) {
        return {
          authType: '',
          isAuthenticated: false,
          userData: null
        }
      }

      email = localStorage.getItem('__useremail__') || '';
    }

    if (authType === 'password') {

      const userTokenInfo = localStorage.getItem('__token__') || '';

      if (!userTokenInfo) {
        return {
          authType: '',
          isAuthenticated: false,
          userData: null
        }
      }

      const parsedData = JSON.parse(userTokenInfo);
      email = parsedData['@user_email'];

    }

    if (authType !== 'social' && authType !== 'password') {
      return {
        authType: '',
        isAuthenticated: false,
        userData: null
      }
    }

    const URI = `${baseUri}?userEmail=${email}`;

    const response = await httpClient(URI, { headers: { ...headerAuth } });
    const data = response.data;

    return {
      authType,
      isAuthenticated: true,
      userData: data.userData
    }

  } catch (error: any) {
    return {
      authType: '',
      isAuthenticated: false,
      userData: null
    }
  }

};

export default renewUser;