import { lazy, LazyExoticComponent } from 'react';

import { LoginPage } from '@pages/Login';
import { PassRecoveryPage } from '@pages/PassRecovery';
import { RegisterPage } from '@pages/Register';
import { VerifyAuth } from '@pages/VerifyAuth';
import { RegisterDevicePage } from '@pages/RegisterDevice';
import { TermsAndConditions } from "@pages/TermsAndConditions";

type JSXComponent = () => JSX.Element;

export interface Route {
  to: string;
  path: string;
  Component: LazyExoticComponent<JSXComponent> | JSXComponent | any;
  name: string;
  isProtected: boolean;
};

const LazyDashboard = lazy(() => import(/* webpackChunkName: "AppDashboard" */ './DashboardNavigation'));

export const routes: Route[] = [
  {
    to: '/login',
    path: 'login',
    Component: LoginPage,
    name: 'Login',
    isProtected: false
  },
  {
    to: '/verify-auth',
    path: 'verify-auth',
    Component: VerifyAuth,
    name: 'Check Auth',
    isProtected: false
  },
  {
    to: '/register',
    path: 'register',
    Component: RegisterPage,
    name: 'Register',
    isProtected: false
  },
  {
    to: '/register-device/:userId,:userEmail',
    path: 'register-device/:userId,:userEmail',
    Component: RegisterDevicePage,
    name: 'Register Device',
    isProtected: false
  },
  {
    to: '/pass-recovery',
    path: 'pass-recovery',
    Component: PassRecoveryPage,
    name: 'Password Recovery',
    isProtected: false
  },
  {
    to: '/terms_and_conditions_of_use',
    path: 'terms_and_conditions_of_use',
    Component: TermsAndConditions,
    name: 'Terms and conditions of use',
    isProtected: false
  },
  {
    to: '/app',
    path: 'app/*',
    Component: LazyDashboard,
    name: 'Application Dashboard',
    isProtected: true
  },
];