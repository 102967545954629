import * as React from 'react';

import { Box, Progress, BoxProps, useColorModeValue } from '@chakra-ui/react';

const { useEffect } = React;

interface Props extends BoxProps {
  children?: JSX.Element;
  blockScroll?: boolean;
}

export const LoadingFormPage = ({ children, blockScroll, ...rest }: Props) => {
  const bgBox = useColorModeValue(
    'rgba(255, 255, 255, .4)',
    'rgba(0, 0, 0, .4)'
  );

  useEffect(() => {
    if (blockScroll) document.body.style.overflow = 'hidden';

    return () => {
      if (blockScroll) document.body.style.overflow = 'auto';
    };
  }, [blockScroll]);

  return (
    <Box
      bg={bgBox}
      height="100%"
      left="0"
      position="absolute"
      top="0"
      width="100%"
      zIndex={10}
      overflow="hidden"
      {...rest}
    >
      <Progress size="xs" isIndeterminate />
      {children}
    </Box>
  );
};
