import { FetchRest } from "@config/httpClient";

interface ProductsDataProps {
  userId: string;
  lang: string;
  limit?: number;
  page?: number;
  filters?: ProductDataFilters
};

export interface ProductDataFilters {
  productType?: string;
  title?: string;
  orderBy?: string;
  category?: string;
  language?: string;
  folderId?: string | null;
  myProducts?: string;
  status?: string;
}

export const getAllProducts = async (
  userId: string,
  lang: string,
  page: number,
  limit?: number,
  filters?: ProductDataFilters
) => {

  const limitOfRows = limit || 16;
  const actualPage = page && page > 0 ? page : 1;

  let queryParams = `userId=${userId}&limit=${limitOfRows}&page=${actualPage}`;

  if (filters && filters.category)
    queryParams += `&category=${filters.category}`;

  if (filters && filters.language && filters.language !== 'any')
    queryParams += `&language=${filters.language}`;

  if (filters && filters.productType && filters.productType !== 'all')
    queryParams += `&productType=${filters.productType}`;

  if (filters && filters.title)
    queryParams += `&title=${filters.title}`;

  if (filters && filters.orderBy)
    queryParams += `&orderBy=${filters.orderBy}`;

  if (filters && filters.folderId)
    queryParams += `&folderId=${filters.folderId}`;

  if (filters && filters.myProducts)
    queryParams += `&myProducts=${filters.myProducts}`;

  if (filters && filters.status)
    queryParams += `&status=${filters.status}`;

  const uri = `/products/get_all_products?${queryParams}`;
  
  const response = await FetchRest<ProductsDataProps>({ uri, requireAuth: true });
  
  if (response.error) {
    const message = response.messages[lang];
    throw new Error(message);
  }

  return response.data;

};