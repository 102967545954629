import { FetchRest } from '@config/httpClient';

export interface GetAllUsersServiceProps {
  userId: string;
  lang: string;
  page: number;
  limit: number;
  roleIdFilter?: number;
  emailFilter?: string;
  subscriptionFilter?: string;
  statusFilter?: string;
};

export const getAllUsersService = async (props: GetAllUsersServiceProps) => {

  const { userId, lang, page, limit, roleIdFilter, statusFilter, emailFilter, subscriptionFilter } = props;

  let uri = `/users?userId=${userId}&page=${page}&limit=${limit}`;

  if (roleIdFilter) uri = uri.concat(`&roleId=${roleIdFilter}`);

  if (statusFilter && statusFilter !== 'all') uri = uri.concat(`&status=${statusFilter}`);

  if (emailFilter) uri = uri.concat(`&email=${emailFilter}`);

  if (subscriptionFilter && subscriptionFilter !== 'all') uri = uri.concat(`&subscription=${subscriptionFilter}`);

  const response = await FetchRest({
    requireAuth: true,
    uri,
    method: 'GET'
  });

  if (response.error) {
    const message = response.messages[lang];
    throw new Error(message);
  }

  const data = response.data;
  
  return data;

};