import * as React from 'react';

import {
  Box,
  Text,
  Flex,
  Button,
  Icon,
  Spinner,
  HStack,
  PinInput,
  PinInputField,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { MdSend } from 'react-icons/md';

import { PageMetadata } from '@components/PageMetadata';

import requestNewDeviceRegister from '@services/user/requestNewDeviceRegister';
import updateDeviceRegisterCode from '@services/user/updateDeviceRegisterCode';

const { useState, useCallback, useEffect, useRef } = React;

interface DataResponseInterface {
  device: {
    id: string;
    os: string;
    browser: string;
  };
  deviceRequestId: string;
}

export const RegisterDevicePage = () => {
  const { t, i18n } = useTranslation();

  const isMounted = useRef(true);
  const params = useParams();
  const navigate = useNavigate();

  const lang = i18n.language.substring(0, 2);

  const [isLoading, setIsLoading] = useState(true);

  const [hasError, setHasError] = useState('');
  const [dataResponse, setDataResponse] =
    useState<DataResponseInterface | null>(null);

  const [emailCode, setEmailCode] = useState('');
  const [codeSended, setCodeSended] = useState(false);

  const handleCancelOrBack = () => navigate('/login', { replace: true });

  const handleVerifyCode = async () => {
    setIsLoading(true);

    try {
      const response = await updateDeviceRegisterCode({
        userId: params?.userId || '',
        deviceRequestId: dataResponse?.deviceRequestId || '',
        emailCode,
      });

      if (response.error) {
        const message = response.messages[i18n.language.substring(0, 2)];
        setHasError(message);
        setIsLoading(false);
        return;
      }

      setCodeSended(true);
      setIsLoading(false);
    } catch (error) {
      const customMessages: any = {
        en: `An unexpected error occurred, please try again later.`,
        es: `Ocurrio un error inesperado, por favor intentalo de nuevo más tarde.`,
        pt: `Ocorreu um erro inesperado, tente novamente mais tarde.`,
      };

      setHasError(customMessages[i18n.language.substring(0, 2) || 'en']);
      setIsLoading(false);
    }
  };

  const requestDeviceRegister = useCallback(async () => {
    if (!isMounted.current) return;

    try {
      const userId = params?.userId || '';
      const deviceIdRegistered = localStorage.getItem('deviceId') || '';

      const response = await requestNewDeviceRegister({
        userId,
        language: lang,
        deviceIdRegistered: deviceIdRegistered,
      });

      if (response.error) {
        const message = response.messages[i18n.language.substring(0, 2)];
        if (isMounted.current) {
          setHasError(message);
          setIsLoading(false);
        }
        return;
      }

      const data = response.data;

      if (data.exists) {
        const { phase, observation } = data?.data;

        if (phase === 'revision') {
          const messagesRevision: any = {
            en: 'There is already a request with this device, please wait for the administrators to give an answer, you will be notified with an email.',
            es: 'Ya existe una solicitud con este dispositivo, por favor espera que los administradores den una respuesta, seras notificado con un electronico.',
            pt: 'Já existe uma solicitação com este dispositivo, aguarde a resposta dos administradores, você será notificado com um e-mail.',
          };

          const messagesSelected = messagesRevision[lang];
          if (isMounted.current) {
            setHasError(messagesSelected);
            setIsLoading(false);
          }
        }

        if (phase === 'rejected') {
          const messagesRevision: any = {
            en: `The device registration request was rejected with the following remark: ${observation}`,
            es: `La solicitud de registro del dispositivo fue rechazada con la siguiente observación: ${observation}`,
            pt: `A solicitação de registro do dispositivo foi rejeitada com a seguinte observação: ${observation}`,
          };

          const messagesSelected = messagesRevision[lang];

          if (isMounted.current) {
            setHasError(messagesSelected);
            setIsLoading(false);
          }
        }

        return;
      }

      const { device, deviceRequestId } = response.data?.data;

      if (isMounted.current) {
        setDataResponse({ device, deviceRequestId });
        setIsLoading(false);
      }

      const emailKeyStorage = `device${params?.userEmail}`;
      
      localStorage.setItem(emailKeyStorage, device.id);
      localStorage.setItem('deviceId', device.id || '');
    } catch (error) {
      console.log(error);
      const customMessages: any = {
        en: `An unexpected error occurred, please try again later.`,
        es: `Ocurrio un error inesperado, por favor intentalo de nuevo más tarde.`,
        pt: `Ocorreu um erro inesperado, tente novamente mais tarde.`,
      };

      if (isMounted.current) {
        setHasError(customMessages[i18n.language.substring(0, 2) || 'en']);
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    requestDeviceRegister();

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Box mt={4}>
      <PageMetadata title={t('registerDeviceTitle')} />
      <Text mb={2} as="h2" fontSize="3xl" fontWeight="bold">
        {t('registerDeviceTitle')}
      </Text>
      <Flex my={4} direction="column" align="center" justify="center">
        {isLoading && <Spinner size="xl" />}
        {hasError && (
          <Alert status="error">
            <AlertIcon />
            {hasError}
          </Alert>
        )}
        {!isLoading && dataResponse && !codeSended && (
          <Box width="100%">
            <Text>{t('registerDeviceMessageSend')}</Text>
            <Box borderTopWidth="1px" mt={4} pt={4}>
              <Text fontWeight="semibold">{dataResponse?.device?.os}</Text>
              <Text fontSize="sm">
                <Text as="span" fontWeight="semibold">
                  Client:{' '}
                </Text>{' '}
                {dataResponse?.device?.browser}
              </Text>
            </Box>
            <HStack borderTopWidth="1px" mt={4} pt={4}>
              <PinInput otp onComplete={(code) => setEmailCode(code)}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </Box>
        )}
        {!isLoading && codeSended && (
          <Box width="100%">
            <Text mb={2}>{t('registerDeviceSuccessMessageOne')}</Text>
            <Text>{t('registerDeviceSuccessMessageTwo')}</Text>
          </Box>
        )}
      </Flex>
      <Flex direction="row" align="center" justify="space-between">
        <Button
          onClick={handleCancelOrBack}
          variant="outline"
          isDisabled={isLoading}
        >
          {codeSended ? t('returnToHome') : t('prfilePMCancelButton')}
        </Button>
        {!codeSended && (
          <Button
            rightIcon={<Icon as={MdSend} />}
            colorScheme="brand"
            variant="solid"
            isDisabled={isLoading || !!hasError || !emailCode}
            isLoading={isLoading}
            onClick={handleVerifyCode}
          >
            {t('registerDeviceSendButtonText')}
          </Button>
        )}
      </Flex>
    </Box>
  );
};
