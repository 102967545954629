export interface LoginFormModelInterface {
  formId: string;
  formField: {
    [x: string]: { [y: string]: any }
  }
};

const loginFormModel = {
  formId: 'loginForm',
  formField: {
    email: {
      name: 'email',
      label: 'emailField'
    },
    password: {
      name: 'password',
      label: 'passField'
    }
  }
};

export default loginFormModel;