import httpClient from "@config/httpClient";
import handleHttpClientErrors from "@utils/handleHttpClientErrors";

import { getUserTokenAndHeader } from "@context/AuthContext";

interface GenerateRecoveryTokenProps {
  email: string;
  lang: string;
};

const generateRecoveryToken = async (userData: GenerateRecoveryTokenProps | any) => {

  try {

    const headerAuth = await getUserTokenAndHeader();

    const response = await httpClient('/users/generate_recovery_token', {
      method: 'POST',
      headers: { ...headerAuth },
      data: userData
    });

    const data = response.data;

    return { error: false, data };

  } catch (error: any) {
    const { messages, status } = handleHttpClientErrors(error);
    return { error: true, messages, status };
  }

};

export default generateRecoveryToken;