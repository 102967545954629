import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Text,
  Spinner,
  useColorModeValue,
  Collapse,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';

import validateRecoveryToken from '@services/user/validateRecoveryToken';

const { useState, useEffect, useCallback } = React;

interface Props {
  handleChangeStep: (step: number) => void;
  updateUserId: React.Dispatch<React.SetStateAction<string>>;
}

export const VerifyToken = ({ handleChangeStep, updateUserId }: Props) => {
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState<string | null>(null);

  const handleVerifyToken = useCallback(async () => {
    setIsLoading(true);

    try {
      const token = searchParams.get('token') || '';
      const response = await validateRecoveryToken(token);

      if (response.error) {
        const message = response.messages[i18n.language.substring(0, 2)] || '';

        setHasError(message);
        setIsLoading(false);
        return;
      }

      updateUserId(response.data.userId);
      handleChangeStep(3);
    } catch (error) {
      const customMessages: any = {
        en: `An unexpected error occurred, please try again later.`,
        es: `Ocurrio un error inesperado, por favor intentalo de nuevo más tarde.`,
        pt: `Ocorreu um erro inesperado, tente novamente mais tarde.`,
      };

      setHasError(customMessages[i18n.language.substring(0, 2) || 'en']);

      setIsLoading(false);
    }
  }, [handleChangeStep, i18n.language, searchParams, updateUserId]);

  const emptyColorSpinner = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    handleVerifyToken();
  }, [handleVerifyToken]);

  return (
    <Box mt={1} textAlign="center">
      <Text mb={2}>{t('messageVerifyingToken')}</Text>

      <Collapse in={!!hasError} animateOpacity>
        <Alert status="error" borderRadius={4} mt={2}>
          <AlertIcon />
          <AlertDescription fontSize="sm" lineHeight={1.2}>
            {hasError}
          </AlertDescription>
        </Alert>
      </Collapse>

      {isLoading && (
        <Spinner
          thickness="4px"
          speed="0.85s"
          emptyColor={emptyColorSpinner}
          color="blue.500"
          size="xl"
        />
      )}
    </Box>
  );
};
