import * as Actions from '@actions/authActions';
import { AuthAction, AuthStateInterface } from '@interfaces/AuthStateInterface';

export const authReducer = (state: AuthStateInterface, action: AuthAction): AuthStateInterface => {

  if (action.type === Actions.LOGIN_USER) {
    return {
      ...state,
      user: action.payload?.user,
      authType: action.payload?.authType,
      isAuthenticated: true,
    };
  }

  if (action.type === Actions.UPDATE_USER) {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.payload
      }
    };
  }

  if (action.type === Actions.LOGOUT_USER) {
    return {
      ...state,
      user: null,
      authType: '',
      isAuthenticated: false
    };
  }

  if (action.type === Actions.SET_CHECKING_SESSION) {
    return {
      ...state,
      isCheckingSession: true
    };
  }

  if (action.type === Actions.REMOVE_CHECKING_SESSION) {
    return {
      ...state,
      isCheckingSession: false
    };
  }

  return state;

};