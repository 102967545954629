import * as Yup from 'yup';
import recoveryFormModel from './recoveryFormModel';

const { formField: { email, password, confirm } } = recoveryFormModel;

const recoveryFormSchema = (
  emailErrors: string[], 
  passwordErrors: string[]
) => ([
  Yup.object().shape({
    [email.name]: Yup.string()
      .email(emailErrors[0])
      .required(emailErrors[1]),
  }),
  Yup.object().shape({}),
  Yup.object().shape({}),
  Yup.object().shape({
    [password.name]: Yup.string().required(passwordErrors[0]).min(8, passwordErrors[1]),
    [confirm.name]: Yup.string().required(passwordErrors[2]).oneOf([Yup.ref(password.name), null], passwordErrors[3]),
  })
]);

export default recoveryFormSchema;