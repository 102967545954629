import httpClient from "@config/httpClient";
import handleHttpClientErrors from "@utils/handleHttpClientErrors";

import { getUserTokenAndHeader } from "@context/AuthContext";

const validateRecoveryToken = async (token: string) => {

  try {

    const headerAuth = await getUserTokenAndHeader();

    const response = await httpClient(`/users/validate_recovery_token?token=${token}`, {
      headers: { ...headerAuth }
    });

    const data = response.data;

    return { error: false, data };

  } catch (error: any) {
    const { messages, status } = handleHttpClientErrors(error);
    return { error: true, messages, status };
  }

};

export default validateRecoveryToken;