import * as React from 'react';

import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import { SidebarContent } from './SidebarContent';
import { SidebarMobile } from './SidebarMobile';
import { RenewPlan } from './RenewPlan';

interface Props {
  children: JSX.Element;
}

const { useState } = React;

export const SideBarMenu = ({ children }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  const bgBox = useColorModeValue('gray.100', 'gray.800');
  const bgContent = useColorModeValue('gray.100', 'gray.800');

  const mlContentBox = isMenuCollapsed ? '95px' : '250px';

  const handleCollapseMenu = () => setIsMenuCollapsed((prev) => !prev);

  return (
    <Box minH="100vh" bg={bgBox}>
      <SidebarContent
        isMenuCollapsed={isMenuCollapsed}
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isFullHeight
        isOpen={isOpen}
        lockFocusAcrossFrames
        onClose={onClose}
        onOverlayClick={onClose}
        placement="left"
        returnFocusOnClose={false}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent height="100%" width="100%">
          <SidebarContent isMenuCollapsed={isMenuCollapsed} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <SidebarMobile
        isCollapsedMenu={isMenuCollapsed}
        handleCollapseMenu={handleCollapseMenu}
        onOpen={onOpen}
      />
      <Box
        transition="margin .3s ease"
        bg={bgContent}
        ml={{ base: 0, md: mlContentBox }}
        px={{ base: 4, md: 8 }}
      >
        {children}
        <RenewPlan mlContentBox={mlContentBox} />
      </Box>
    </Box>
  );
};
