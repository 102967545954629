import * as Yup from 'yup';
import loginFormModel from './loginFormModel';

const { formField: { email, password } } = loginFormModel;

const loginFormSchema = (emailErrors: string[], passwordErrors: string[]) => ([
  Yup.object().shape({
    [email.name]: Yup.string()
      .email(emailErrors[0])
      .required(emailErrors[1]),
  }),
  Yup.object().shape({
    [password.name]: Yup.string().required(passwordErrors[0])
  })
]);

export default loginFormSchema;