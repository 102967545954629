import {
  Box,
  Button,
  Text,
  useColorModeValue,
  BoxProps,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "@context/AuthContext";
import dayjs from "dayjs";

interface Props extends BoxProps {
  mlContentBox: string;
}

export const RenewPlan = ({ mlContentBox, ...rest }: Props) => {
  const { t } = useTranslation();
  const { authState, isUserAdmin } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  const bgUpdatePlanBox = useColorModeValue("brand.800", "brand.700");
  const colorUpdatePlanBox = useColorModeValue("whiteAlpha.900", "");

  const handleGoToBilling = () => navigate(`/app/profile/update_subscription`);
  const subClientName = authState.user?.subscription.subClientName;
  
  let renewPlanMessage = subClientName === "Free trial"
    ? t("renewPlanBarTitleTrial") : subClientName === "Free"
    ? t("renewPlanBarTitle") : subClientName === "MBA"
    ? t("renewPlanBarTitleMBA") : '';
  
  const activeUntil = authState.user?.subscription.activeUntil;
  const expirationDate = dayjs(activeUntil);
  const currentDate = dayjs();
  const remainingDays = expirationDate.diff(currentDate, "days");
  renewPlanMessage = renewPlanMessage.replace("10", activeUntil != undefined ? remainingDays.toString() : "10");

  if (
    (authState.user?.subscription.subName !== "free" &&
      subClientName !== "Free trial" &&
      subClientName !== "MBA") ||
    isUserAdmin
  ) {
    return null;
  }

  if (location.pathname.includes("update_subscription")) return null;

  return (
    <Box
      alignItems="center"
      bg={bgUpdatePlanBox}
      bottom={0}
      display="flex"
      flexDir={{ base: "column", md: "row" }}
      justifyContent="center"
      left={{ base: 0, md: mlContentBox }}
      position="fixed"
      py={4}
      transition="all .3s ease"
      width={{ base: "100%", md: `calc(100% - ${mlContentBox})` }}
      zIndex={1000}
      {...rest}
    >
      <Text
        color={colorUpdatePlanBox}
        textAlign={{ base: "center", md: "inherit" }}
      >
        {renewPlanMessage}
      </Text>
      <Button
        ml={{ base: 0, md: 3 }}
        mt={{ base: 2, md: 0 }}
        colorScheme="orange"
        onClick={handleGoToBilling}
      >
        {t("renewPlanBarButton")}
      </Button>
    </Box>
  );
};
