import * as React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

import {
  Avatar,
  Box,
  Text,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  useColorMode,
  useBreakpointValue,
} from "@chakra-ui/react";

import { useAuth } from "@context/AuthContext";
import { logoutUserAction } from "@actions/authActions";

import { DarkModeToggle } from "@components/DarkModeToggle";
import { SelectLanguaje } from "@components/SelectLanguage";

import { HiOutlineLogout } from "react-icons/hi";
import { UserStore } from "./UserStore";
import { useSetRecoilState } from "recoil";
import { userMainStoreState } from "@store/stores/atoms";
import { ShowContacts } from "@components/ShowContacts";

const { useEffect } = React;

export const Header = () => {
  const { asyncDispatch, dispatch, authState } = useAuth();
  const { colorMode } = useColorMode();

  const setUserMainStore = useSetRecoilState(userMainStoreState);

  const showProfileInfo = useBreakpointValue({ base: true, md: false });
  const showUserStore = useBreakpointValue({ base: false, md: true });

  const borderDividerColor =
    colorMode === "dark" ? "whiteAlpha.300" : "gray.200";

  const handleCloseSession = () => asyncDispatch(logoutUserAction(dispatch));

  useEffect(() => {
    setUserMainStore(authState.user?.store || null);
  }, [authState.user, setUserMainStore]);

  return (
    <Box display="flex" alignItems="center" w="100%" h="100%" bg="transparent">
      {showUserStore && <UserStore />}
      <Box display="flex" alignItems="center" ml="auto" mr={{ base: 0, md: 4 }}>
        <ShowContacts mr={2} size="sm" variant="outline" colorScheme="brand" />
        <DarkModeToggle
          mr={2}
          size="sm"
          variant="outline"
          colorScheme="brand"
        />
        <SelectLanguaje />

        {showProfileInfo && (
          <>
            <Box
              mx={4}
              borderLeftWidth="1px"
              borderLeftStyle="solid"
              height="35px"
              borderLeftColor={borderDividerColor}
            />

            <LinkBox>
              <LinkOverlay
                display="flex"
                alignItems="center"
                as={ReactRouterLink}
                to={`/app/profile/${authState.user?.userId}`}
              >
                <Avatar
                  referrerPolicy="no-referrer"
                  src={authState.user?.avatarUrl}
                  alt="User Avatar"
                  width="35px"
                  height="35px"
                />
                <Box ml={1.5} lineHeight={1.2}>
                  <Text fontSize="small" fontWeight="bold">
                    {authState.user?.firstName}
                  </Text>
                  <Text fontSize="small">
                    {authState.user?.subscription?.subClientName}
                  </Text>
                </Box>
              </LinkOverlay>
            </LinkBox>

            <IconButton
              ml={4}
              aria-label="Boton Cerrar Sesión"
              onClick={handleCloseSession}
              icon={<Icon as={HiOutlineLogout} />}
              variant="ghost"
              size="md"
            />
          </>
        )}
      </Box>
    </Box>
  );
};
