import {
    Box,
    Text,
    useColorModeValue,
    List,
    ListItem,
    Link,
} from "@chakra-ui/react";

import { SeparatorWithText } from "@components/SeparatorWithText";
import { Link as RouterLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Lottie } from '@crello/react-lottie';
import terms_and_conditions from '@assets/lotties/terms_and_conditions.json';
import { PageMetadata } from "@components/PageMetadata";

export const TermsAndConditions = () => {
  const { t } = useTranslation();

  const loadingLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: terms_and_conditions,
    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
  };

  const bgContainer = useColorModeValue('white', 'gray.700');

  return (
    <Box
      bg={bgContainer}
      pos="relative"
      mt={4}
      textAlign={"center"}
    >
      <PageMetadata title={t("termsAndConditions")} />
      <Box mx="auto" width={{ base: "60%", md: "50%", lg:  "40%" }}>
        <Lottie config={loadingLottieOptions} />
      </Box>
      <Text
        fontSize={{ base: "2xl", md: "3xl" }} fontWeight="bold"
      >
        { t('termsTitle') }
      </Text>
      <Box
        w={{ base: "90%" }}
        mx={"auto"}
      >
        <Text
          fontSize="md"
          textAlign={"justify"}
          mt={6}
        >
          {t('termsIntro')}
        </Text>
        <Text
          fontSize={{ base: "md" }}
          textAlign={"justify"}
          mt={2}
        >
          {t("termsValidity")}
        </Text>
        <Box
          mt={4}
          ml={4}
        >
          <List spacing={3} textAlign="justify" styleType="decimal">
            <ListItem>
              { t('termsItemOne') }
            </ListItem>
            <ListItem>
              { t('termsItemTwo') }
            </ListItem>
            <ListItem>
              { t('termsItemThree') }
            </ListItem>
            <ListItem>
              { t('termsItemFour') }
            </ListItem>
            <ListItem>
              { t('termsItemFive') }
            </ListItem>
            <ListItem>
              { t('termsItemSix') }
            </ListItem>
            <ListItem>
              { t('termsItemSeven') }
            </ListItem>
            <ListItem>
              { t('termsItemEight') }
            </ListItem>
            <ListItem>
              { t('termsItemNine') }
            </ListItem>
            <ListItem>
              { t('termsItemTen') }
            </ListItem>
            <ListItem>
              { t('termsItemEleven') }
            </ListItem>
          </List>
        </Box>
        <Text
          fontSize="lg"
          textAlign={"justify"}
          mt={4}
          fontWeight="bold"
        >
          {t('termsFinalNote')}
        </Text>
      </Box>
    </Box>
  );
};
