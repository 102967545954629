import { Avatar, Flex, Tag, Text } from '@chakra-ui/react';
import { useAuth } from '@context/AuthContext';

interface Props {
  isMenuCollapsed: boolean;
}

export const UserDataHeader = ({ isMenuCollapsed }: Props) => {
  const { authState } = useAuth();

  return (
    <Flex
      direction="column"
      width="100%"
      justify="center"
      align="center"
      px={4}
      pb={isMenuCollapsed ? 3 : 0}
      mb={isMenuCollapsed ? 2 : 0}
      borderBottomWidth={isMenuCollapsed ? '1px' : 0}
    >
      <Avatar
        referrerPolicy="no-referrer"
        src={authState.user?.avatarUrl}
        alt="User Avatar"
        width={isMenuCollapsed ? '35px' : '75px'}
        height={isMenuCollapsed ? '35px' : '75px'}
      />
      <Flex
        display={isMenuCollapsed ? 'none' : 'flex'}
        lineHeight={1.2}
        pb={4}
        mb={2}
        mt={1.5}
        borderBottomWidth="1px"
        width="100%"
        direction="column"
        justify="center"
        align="center"
      >
        <Text
          textAlign="center"
          fontSize={{ base: 'xl', md: 'md' }}
          fontWeight="semibold"
        >
          {authState.user?.firstName} {authState.user?.lastName}
        </Text>
        <Tag mt={1} mx="auto" colorScheme="brand" variant="solid" size="sm">
          {authState.user?.subscription?.subClientName}
        </Tag>
      </Flex>
    </Flex>
  );
};
