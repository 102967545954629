import {
  Box,
  BoxProps,
  CloseButton,
  Flex,
  Image,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { UserDataHeader } from '@components/Header/UserDataHeader';

import { MenuItems } from './MenuItems';
import { NavItem } from './NavItem';
import { LogoutNavButton } from './LogoutNavButton';
import { useAuth } from '@context/AuthContext';

import logoWhite from '@assets/images/logo_white.png';
import logoDark from '@assets/images/logo_black.png';
import logoMiniWhite from '@assets/images/logo192.png';
import logoMiniBlack from '@assets/images/logo192_black.png';
import { UserStore } from '@components/Header/UserStore';

interface Props extends BoxProps {
  onClose: () => void;
  isMenuCollapsed: boolean;
}

export const SidebarContent = ({
  onClose,
  isMenuCollapsed,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { isUserAdmin, authState } = useAuth();

  const widthMenu = isMenuCollapsed ? '95px' : '250px';
  const bgBox = useColorModeValue('white', 'gray.700');
  const isMobile = useBreakpointValue({ base: true, md: false });

  const userSubName = authState.user?.subscription.subName || '';
  const menuItemsToRender = formatMenuItems(isUserAdmin, userSubName);

  const logoFullByTheme = useColorModeValue(logoDark, logoWhite);
  const logoMiniByTheme = useColorModeValue(logoMiniBlack, logoMiniWhite);
  const logoSrc = isMenuCollapsed ? logoMiniByTheme : logoFullByTheme;

  const handleCloseMenuOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, pageName: string) => {
    if(isMobile) setTimeout(onClose, 300);    
    if(pageName === 'community'){
      e.preventDefault();
      window.open("https://www.skool.com/viraly", "_blank");
    }
  }

  return (
    <Box
      {...rest}
      h="100vh"
      overflowX="hidden"
      pos="fixed"
      transition="width .3s ease"
      w={{ base: '100%', md: widthMenu }}
      bg={bgBox}
    >
      <Box>
        <Flex justifyContent="center" mb={4} pt={4}>
          <Image
            width={isMenuCollapsed ? '30px' : '120px'}
            height={isMenuCollapsed ? '30px' : 'auto'}
            mx={{ base: 0, lg: 'auto' }}
            src={logoSrc}
          />
          <CloseButton
            display={{ base: 'flex', md: 'none' }}
            onClick={onClose}
            position="absolute"
            top={1}
            right={1}
          />
        </Flex>
        <UserDataHeader isMenuCollapsed={isMenuCollapsed} />
        {isMobile && <UserStore />}
      </Box>
      <Box overflowY="auto">
        {menuItemsToRender.map((link) => (
          <NavItem
            isMenuCollapsed={isMenuCollapsed}
            key={link.name}
            icon={link.Icon}
            path={link.path}
            onClick={(e) => handleCloseMenuOnClick(e, link.name)}
          >
            {`${t(link.i18Key)}`}
          </NavItem>
        ))}
        {!isMobile && (
          <LogoutNavButton isMenuCollapsed={isMenuCollapsed} width="100%" />
        )}
      </Box>
    </Box>
  );
};

const formatMenuItems = (isAdmin: boolean, sub: string) => {
  if (isAdmin) {
    const itemsToShow = MenuItems.filter((item) => item.showToAdmin);
    return itemsToShow;
  } else {
    const noAdminItems = MenuItems.filter((item) => !item.isAdmin);
    const filteredBySub = noAdminItems.filter((item) => {
      if (item.subsCanAccess.includes('all')) return true;

      if (item.subsCanAccess.includes(sub)) return true;
      else return false;
    });

    const itemsToShow = filteredBySub;

    return itemsToShow;
  }
};
