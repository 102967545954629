import { FetchRest } from "@config/httpClient";

interface CreateFolderServiceProps {
  userId: string;
  folderName: string;
  addProduct?: boolean;
  productId?: string;
  lang: string;
};

interface CreateFolderProps {
  userId: string;
  folderName: string;
};

interface AddProductToFolder {
  userId: string;
  folderId: string;
  productId: string;
};

export const createFolderService = async (props: CreateFolderServiceProps) => {
  const { userId, folderName, addProduct, productId, lang } = props;

  const uriAddFolder = `/products/create_folder`;

  const response = await FetchRest<CreateFolderProps>({
    uri: uriAddFolder,
    requireAuth: true,
    method: 'POST',
    body: { userId, folderName }
  });

  if (response.error) {
    const message = response.messages[lang];
    throw new Error(message);
  }

  if (!addProduct) return response.data;

  const folderId = response.data.folderId;
  const uriAddProduct = `/products/add_product_to_folder`;

  const responseAddProduct = await FetchRest<AddProductToFolder>({
    uri: uriAddProduct,
    requireAuth: true,
    method: 'POST',
    body: { userId, folderId, productId: productId ?? '' }
  });

  if (responseAddProduct.error) {
    const message = response.messages[lang];
    throw new Error(message);
  }

  return responseAddProduct.data;
};