import httpClient from "@config/httpClient";
import handleHttpClientErrors from "@utils/handleHttpClientErrors";

import { getUserTokenAndHeader } from "@context/AuthContext";

interface ReferralEmailProps {
    userId: string;
    lang: string;
};

const sendReferralEmail = async (userData: ReferralEmailProps) => {

    try {

        const headerAuth = await getUserTokenAndHeader();

        const response = await httpClient('/users/send_referral_email', {
            method: 'POST',
            headers: { ...headerAuth },
            data: { ...userData, language: userData.lang }
        });

        const data = response.data;

        return { error: false, data };

    } catch (error: any) {
        const { messages, status } = handleHttpClientErrors(error);
        return { error: true, messages, status };
    }

};

export default sendReferralEmail;