import { Icon, Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';

import { HiOutlineLogout } from 'react-icons/hi';

import { useTranslation } from 'react-i18next';
import { useAuth } from '@context/AuthContext';
import { logoutUserAction } from '@actions/authActions';

interface Props extends FlexProps {
  isMenuCollapsed: boolean;
}

export const LogoutNavButton = ({ isMenuCollapsed, ...rest }: Props) => {
  const { t } = useTranslation();
  const { asyncDispatch, dispatch } = useAuth();

  const colorItem = useColorModeValue('brand.600', 'whiteAlpha.900');
  const colorHoverItem = useColorModeValue(
    'rgb(124 148 166 / 10%)',
    'rgb(159 178 193 / 10%)'
  );

  const handleCloseSession = () => asyncDispatch(logoutUserAction(dispatch));

  return (
    <Flex
      _hover={{
        bg: colorHoverItem,
        color: colorItem,
      }}
      align="center"
      bg="transparent"
      cursor="pointer"
      fontSize={{ base: 'xl', md: 'inherit' }}
      justify={isMenuCollapsed ? 'center' : 'inherit'}
      onClick={() => handleCloseSession()}
      p="4"
      pl={isMenuCollapsed ? 4 : 8}
      role="group"
      transition="all .2s ease"
      {...rest}
    >
      <Icon
        mr={isMenuCollapsed ? 0 : 4}
        fontSize={{ base: 'xl', md: '20' }}
        as={HiOutlineLogout}
      />
      {!isMenuCollapsed && t('navlinkLogout')}
    </Flex>
  );
};
