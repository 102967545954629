import {
  Box,
  Divider,
  Text,
  BoxProps,
  TextProps,
  useColorModeValue,
} from '@chakra-ui/react';

interface Props extends BoxProps, TextProps {
  text?: string;
}

export const SeparatorWithText = ({ text = '', ...rest }: Props) => {
  const bgText = useColorModeValue('white', 'gray.700');

  return (
    <Box width="100%" height={1} position="relative" {...rest}>
      <Divider variant="solid" />
      {text && (
        <Text
          px="12px"
          bg={bgText}
          top="-300%"
          left="calc(50% - 12px)"
          position="absolute"
        >
          {text}
        </Text>
      )}
    </Box>
  );
};
