import { atom } from 'recoil';

export interface StateFormStore {
  open: boolean;
  formType: 'shopify' | 'woocommerce' | '';
};

export interface StateUserStores {
  maximumAllowedStores: number;
  stores: Store[];
};

export interface Store {
  id: string;
  storeName: string;
  storeUrl: string;
  storeType: string;
  updatedAt: string;
  status: string;
};

export interface DialogDisableStoreProps {
  isOpen: boolean;
  storeName: string;
  storeId: string;
};

export interface DialogAddToStoreProps {
  isOpen: boolean;
  productId: string;
  productTitle: string;
  langsAvailable: LangAvailable[];
};

export interface ProductInStore {
  storeId: string;
  storeOrUrl: string;
  storeType: string;
}

interface LangAvailable {
  lang: string;
  stores: { inShopify: boolean; inWoocommerce: boolean; productInStores?: ProductInStore[] }
};

const defaultStateFormStore: StateFormStore = {
  open: false,
  formType: 'shopify'
};

const defaultStateUserStores: StateUserStores = {
  maximumAllowedStores: 0,
  stores: []
};

const defaultDialogDisableStoreState: DialogDisableStoreProps = {
  isOpen: false,
  storeId: '',
  storeName: ''
};

const defaultDialogAddToStoreState: DialogAddToStoreProps = {
  isOpen: false,
  langsAvailable: [],
  productId: '',
  productTitle: ''
};

export const userMainStoreState = atom<Store | null>({
  key: 'userMainStoreState',
  default: null
});

export const userStoresState = atom({
  key: 'userStores',
  default: defaultStateUserStores
});

export const formStoreState = atom({
  key: 'formStore',
  default: defaultStateFormStore
});

export const dialogDisableStoreState = atom({
  key: 'dialogDisableStore',
  default: defaultDialogDisableStoreState
});

export const dialogAddToStoreState = atom({
  key: 'dialogAddToStore',
  default: defaultDialogAddToStoreState
});