const ptTranslations = {
  language: "Idioma",
  english: "Inglês",
  spanish: "Espanhol",
  portuguese: "Português",
  loginWelcome: "Bem-vindo ao",
  loginTitle: "Iniciar sessão",
  emailValidation: "Deve ser um correio eletrônico válido",
  emailValidationEmpty: "Correio eletrônico é um campo obrigatório",
  passValidation: "Senha é um campo obrigatório",
  passValidationMin: "A senha deve conter pelo menos 8 caracteres",
  confirmValidation: "As senhas devem corresponder",
  confirmValidationRequired: "A confirmação da senha é necessária",
  emailField: "Correio eletrônico",
  passField: "Senha",
  confirmField: "ConfirmaÇão Da Senha",
  phoneField: "Celular",
  selectCountryCode: "Seleccione corretamente o código do seu país para garantir uma boa comunicação.",
  loginButtonText: "Entrar",
  forgotPassText: "Você esqueceu sua senha?",
  noAccountText: "Você não tem uma conta?",
  noAccountLink: "Registro",
  haveAccountText: "Você tem uma conta?",
  haveAccountLink: "entrar",
  rememberUser: "lembrar usuário",
  registerTitle: "Novo registro",
  firstNameField: "Nomes",
  lastNameField: "Sobrenomes",
  firstNameValiationEmpty: "O primeiro nome é um campo obrigatório",
  firstNameValiationMin: "o primeiro nome não pode conter menos de 3 caracteres",
  firstNameValiationMax: "o primeiro nome não pode conter mais de 50 caracteres",
  lastNameValiationEmpty: "O sobrenome é um campo obrigatório",
  lastNameValiationMin: "o sobrenome não pode conter menos de 3 caracteres",
  lastNameValiationMax: "o sobrenome não pode conter mais de 50 caracteres",
  referralCodeField: "Código de Referencia",
  copyReferralCode: "Copiar código de referência",
  copyReferralLink: "Copiar link de referência",
  withoutReferralCode: "Sem Código de Referencia",
  termsTextOne: "Ao se registrar, você concorda que concorda com nossos",
  registerButtonText: "Registro",
  successUserRegister: "Utilizador registado com sucesso",
  verifyingAuthOne: "Estamos verificando seu nome de usuário.",
  verifyingAuthTwo: "Isso levará alguns segundos ..",
  userSignUpWithSocial: "Estamos criando seu usuário no Viraly.",
  requiredFieldsText: "Os campos marcados com * são obrigatórios",
  backButtonText: "Para retornar",
  continueButtonText: "Prosseguir",
  signupGoogle: "Inscreva-se com o Google",
  signupFacebook: "Inscreva-se com o Facebook",
  signupEmail: "Inscreva-se com o email",
  orOption: "ou",
  signinGoogle: "Faça login no Google",
  signinFacebook: "Faça login no Facebook",
  linkToMentorForm: "Quer participar do nosso programa de afiliados?",
  joinHere: "Junte-se aqui",
  affiliateTag: "AFILIADO",
  recoveryPassTitle: "Recuperação de senha",
  rememberPassTextLink: "Você se lembrou da sua senha?",
  returnToHome: "De volta ao topo",
  emailSendedForRecoveryOne: "Se o e-mail corresponder a uma conta existente, enviaremos um e-mail de redefinição de senha em alguns segundos.",
  emailSendedForRecoveryTwo: "Se você não recebeu o e-mail, verifique sua pasta de spam ou",
  emailSenderForRecoverySupport: "entre em contato com o Suporte ao Cliente.",
  messageVerifyingToken: "Estamos verificando o token, aguarde.",
  updatePassButtonText: "Atualizar",
  paymentFormTitle: "Formulário de pagamento",
  paymentDate: "Data de pagamento",
  paymentFormPrice: "Preço",
  paymentFormPlan: "Inscrição",
  paymentFormPayButton: "Pagar",
  paymentFormCardNumberField: "Número do cartão",
  paymentFormExpirationDateField: "Data de validade",
  paymentFormStripeProtect: "Todos os seus pagamentos são protegidos e gerenciados pelo",
  paymentFormTerms: "Ao continuar você concorda com nossos",
  paymentFormTermsLink: "termos e condições",
  paymentFormMessageProcessing: "Estamos processando seu pagamento, aguarde.",
  invoiceDetailsPaymentTitle: "Pagamento bem sucedido",
  invoiceDetailsID: "ID da fatura",
  invoiceDetailsSummary: "Resumo",
  invoiceDetailsDownload: "Baixe o Invoice",
  pricingTitle: "Planos que atendem a sua necessidade",
  pricingButton: "Escolha Plano",
  mostPopularText: "Mais popular",
  menuItemDashboard: "Dashboard",
  menuItemProducts: "Produtos Viraly",
  menuItemFrequentQuestions: "Perguntas frequentes",
  menuItemPlatforms: "Plataformas",
  menuItemStores: "Minhas lojas",
  menuItemAds: "Viraly Ads",
  menuItemArticles: "Artigos",
  menuItemUsers: "Comercial",
  menuItemProfile: "Perfil",
  menuItemReferrals: "referências",
  menuItemSuggestProducts: "Sugerir produtos",
  menuItemSuggestedProducts: "Produtos sugeridos",
  month: "Mês",
  topProductsTitle: "Principais produtos da semana",
  referralTagTransactions: "Transacções",
  referralTagList: "Referências",
  totalReferrals: "Total de referências",
  activeReferrals: "total de activos",
  referralTagWithdraws: "Retiradas",
  copiedCode: "Código copiado",
  profileTabPersonalInfo: "Dados pessoais",
  profileTabSubscription: "Inscrição",
  profileTabConfig: "Dispositivos",
  documentTypeField: "Tipo de documento",
  documentNumberField: "Número do documento",
  phoneNumberField: "Número de telefone",
  countryField: "País",
  cityField: "Cidade",
  zipCodeField: "Código postal",
  profileTextNonEditableFields: "Nos campos desabilitados não é possível modificar as informações para manter sua integridade, caso deseje atualizar essas informações entre em contato com o suporte.",
  textButtonSave: "Guarda",
  profileSubCurrentBill: "Fatura atual",
  profileSubNextPayment: "Próximo pagamento",
  profileSubPlan: "Plano atual",
  discountCoupon: "Tem um cupão de desconto de",
  profileSubPMTitle: "Métodos de Pagamento",
  profileSubAddPM: "Adicionar forma de pagamento",
  profileSubBillingText: "Histórico de cobrança",
  profileSubBillingMessage: "Fazemos upload apenas das últimas 8 faturas",
  profileSubIsDefault: "Predefinição",
  profileSubEdit: "Editar",
  profileSubExpires: "Expira",
  profileSubInvoiceDate: "Encontro: Data",
  profileAddPMPerson: "Nome do Titular",
  profileAddPMisDefault: "Definir como padrão",
  profileAddPMTitle: "Adicionar forma de pagamento",
  profileUpdatePMTitle: "Atualizar forma de pagamento",
  profilePMNote: "Observação",
  profilePMNoteMessage: "Ao adicionar uma forma de pagamento, não podemos garantir que ela funcionará corretamente para pagamentos futuros, por favor, certifique-se de que seu cartão funcione corretamente.",
  prfilePMCancelButton: "Cancelar",
  profilePMTitleToast: "Dados atualizados",
  maintenanceTitle: "Manutenção programada",
  maintenanceMessage: "Estamos fazendo melhorias em nosso sistema, pode haver algumas falhas durante o sábado 13 e o domingo 14 de abril. Agradecemos sua compreensão.",
  community: "Comunidade Skool",
  communityInvitationTitle: "Participe de nossa comunidade Skool",
  communityMessageInvitation: "Nós o convidamos a participar de nossa comunidade Skool! Lá você encontrará treinamentos, perguntas frequentes, recomendações e todas as informações relevantes sobre o Viraly para aprimorar seu processo de vendas.",
  goToCommunity: "Ir para a comunidade",

  // Default
  close: "Fechar",
  actions: "Ações",
  income: "Receitas",
  incomeSingle: "Receita",
  expenses: "Despesas",
  expenseSingle: "Despesa",
  type: "Tipo",
  amount: "Montante",
  description: "Descrição",
  motive: "Razão",
  date: "Data",
  name: "Nome",
  deleteTitle: "Eliminar",
  deleteBody: "¿Tem a certeza que quer apagar este item?",
  deleteIdentifier: "¿Tem a certeza de que quer remover o identificador?",
  deleteCancelButton: "Cancelar",
  titleAcquirePlan: "Adquira o seu plano",
  bodyAcquirePlan: "Adquira o nosso plano e descubra o nosso catálogo completo",
  headerEmail: "Correspondência",
  headerStatus: "Estado",
  referralStatusRegistered: "Registado",
  referralStatusActived: "Subscrito",
  referralStatusCancelled: "Cancelado",
  commission: "Comissão",
  withdrawal: "Retirada",
  accepted: "Aceites",
  confirmationMessage: "Favor confirmar a acção",
  thankYouTitle: "obrigado por fazer parte da nossa comunidade",
  thankYouBody: "Com a sua assinatura, todos os serviços premium foram desbloqueados, para aceder a eles pode utilizar o painel principal à esquerda.",
  termsAndConditions: "Termos e condições",
  themeColor: "Cor do tema",
  new: "Novo",
  contact: "Contato",
  contactModalDescription: "Se tiver alguma dúvida, não hesite em entrar em contato conosco.",

  /* Validaciones para el formulario de personal Info dentro del perfil */
  piDocumentTypeOneOf: "Você deve selecionar uma das opções.",
  piDocumentNumberMin: "O campo deve conter pelo menos 5 caracteres.",
  piDocumentNumberMax: "O campo deve conter no máximo 15 caracteres.",
  piCountryMin: "O campo deve conter pelo menos 4 caracteres.",
  piCountryMax: "O campo deve conter no máximo 50 caracteres.",
  piCityMin: "O campo deve conter pelo menos 4 caracteres.",
  piCityMax: "O campo deve conter no máximo 50 caracteres.",
  piZipCodeMin: "O campo deve conter pelo menos 3 caracteres.",
  piZipCodeMax: "O campo deve conter no máximo 6 caracteres.",
  piPhoneNumberCodeMin: "O campo deve conter pelo menos 1 caracteres.",
  piPhoneNumberCodeMax: "O campo deve conter no máximo 3 caracteres.",
  piPhoneNumberCodeReq: "O código do país é necessário se um número de telefone for indicado.",
  piPhoneNumberMin: "O campo deve conter pelo menos 8 caracteres.",
  piPhoneNumberMax: "O campo deve conter no máximo 14 caracteres.",
  billingBoxesTimezone: "Conversão de tempo",
  billingBoxesChangePlan: "Alterar Plano",
  billingBoxesChangePlanAsAdmin: "Alterar Plano como Admin",
  changeSubscriptionAsAdminMessageHelper: "De onde pode alterar a subscrição dos usuários. Se não for indicado uma data de finalização, a subscrição será permanente.",
  subscriptionRequired: "Selecione uma subscrição",
  subscriptionActiveUntil: "Data de finalização",
  subscriptionActiveUntilMinDateValidation: "A data de finalização deve ser maior que a data atual.",
  subscriptionActiveUntilHelper: "Deixe este campo vazio se a subscrição for permanente.",
  subscriptionActiveUntilHelper2: "Se não for indicado uma data de finalização, a subscrição será permanente.",
  billingBoxedActiveFrom: "Ativo Desde",

  profileHeaderTitle: "Perfil",
  profileHeaderActiveFrom: "Membro desde",
  profileHeaderAuthType: "Registrado com",
  profileHeaderActiveSessions: "Sessões ativas",
  profileHeaderUserRole: "Papel do usuário",
  profileHeaderChangeAvatar: "Mudar Avatar",
  profileHeaderChangePassword: "Mudar senha",
  profileHeaderBlockUser: "Bloquear usuário",
  profileViewReferralPayments: "Ver pagamentos",

  /* Products */
  productsFilterOrderField: "Organizar por",
  productsFilterCategoryField: "Filtrar por Categoria",
  productsFilterSearchField: "Pesquisar Por Título",
  productsFilterLangField: "Filtrar por idioma",
  productsFilterStatusField: "Filtrar por Estado",
  productsFilterAnyLang: "Qualquer língua",
  productsFilterAllLang: "Todos",
  productsFilterAlertMessage: "Si un projeto te gosta e não encontra-se o ID do seu país, você pode pesquisar pelo nome; provavelmente está disponível na plataforma",

  /* Toolbar Tooltips editor */
  toolbarParagraph: "Parágrafo",
  toolbarHeading: "Cabeçallho",
  toolbarBold: "negrito",
  toolbarItalic: "itálico",
  toolbarStrike: "Tachado",
  toolbarHighlight: "Realçar",
  toolbarBulletList: "lista de marcadores",
  toolbarOrderedList: "lista ordenada",
  toolbarBlockQuote: "Bloco de cotação",
  toolbarHorizontalRule: "régua horizontal",
  toolbarLink: "Link",
  toolbarImage: "Adicionar imagem",
  toolbarUndo: "Desfazer",
  toolbarRedo: "Refazer",
  toolbarAlignLeft: "Alinhar à esquerda",
  toolbarAlignCenter: "Centralizar",
  toolbarAlignRight: "Alinhar à direita",
  toolbarAlignJustify: "Justificar",

  /* Profile Header Extras */
  profileHeaderPassRecoveryTitle: "Mudar senha",
  profileHeaderPassRecoveryCurrent: "Senha atual",
  profileHeaderPassRecoveryNew: "Nova senha",
  profileHeaderPassRecoveryNewConfirm: "Nova confirmação de senha",
  profileHeaderPassRecoveryNewMessageHelper: "Para segurança das informações, sua sessão atual será encerrada assim que a senha for atualizada.",
  profileHeaderConfirmEmailTextLink: "Enviar e-mail de confirmação",
  profileHeaderConfirmEmailIsSending: "Estamos enviando o código para seu e-mail.",
  profileHeaderConfirmEmailIsCodeSended: "Digite o código de 9 dígitos enviado para o seu e-mail",
  profileHeaderConfirmEmailIsCodeSendedTip: "você pode copiar e colar o código.",

  contentNotFoundTitle: "Página não encontrada",
  contentNotFoundDesc: "A página que você está procurando parece não existir",
  contentNotFoundButton: "Vamos para Dashboard",

  renewPlanBarTitle: "Você está usando o Viraly gratuitamente, atualize seu plano para obter todos os benefícios",
  renewPlanBarTitleMBA: "Como membro do MBA tem acesso a viral durante 10 dias gratuitamente. Actualize o seu plano quando esta oferta expirar",
  renewPlanBarTitleTrial: "Desfrute de todos os benefícios da viralidade por 24 horas",
  renewPlanBarButton: "Atualize seu plano",
  frequencyPlan: "Selecione a frequência do plano",
  monthlyPlan: "Mensal",
  biannualPlan: "Semestral",
  annualPlan: "Anual",
  saveYourselfTextBegin: "Economize",
  saveYourselfTextEnd: "pagando o ano inteiro",

  pricingPageTitleCancel: "Cancelar plano",
  pricingSelectPlanRecurrency: "mês",
  pricingSelectPlanSelected: "Selecionado",
  pricingSelectPlanCurrent: "Plano atual",
  bronze: "Bronze",
  silver: "Prata",
  gold: "Ouro",

  checkoutFormCreatingPaymentIntent: "Estamos gerando sua ordem de pagamento, tenha paciência...",
  checkoutFormPaymentSuccessfuly: "Seu pagamento foi bem-sucedido, atualizaremos a página para obter seus dados atualizados. ✅",

  changePlanResume: "Retomar",
  changePlanInfoText: "Ao alterar os planos, é aplicado um rateio para ajustar o saldo com a alteração da assinatura.",
  changePlanItem: "Descrição do artigo",
  changePlanAmount: "Resultar",
  changePlanNextPayment: "Próxima data de pagamento",
  chnagePlanSelectCard: "Selecione um método de pagamento",
  changePlanNoteText: "A nova assinatura será ativada imediatamente e os rateios serão cobrados na próxima data de pagamento.",

  profileModalDeleteCardTitle: "Excluir cartão",
  profileModalDeleteCardMessageOne: "Você tem certeza de eliminar o cartão: ",
  profileModalDeleteCardMessageTwo: "? Você não pode desfazer esta ação mais tarde.",
  profileModalDeleteCardMessageIsDefault: "Para excluir este cartão, você deve selecionar um novo método de pagamento padrão antes.",
  profileModalDeleteCardButton: "Remover",

  profileCancelSubTitle: "Cancelar assinatura",
  profileCancelSubMessage: "Quando você cancela sua assinatura, você parará de acessar as funcionalidades que também oferece a você.",

  devicesCardTitle: "Dispositivos registrados",
  devicesCardClient: "Cliente",
  devicesCardClientV: "Versão do cliente.",
  devicesCardInUse: "Atual em uso",

  registerDeviceTitle: "Registrar dispositivo",
  registerDeviceMessageSend: "Digite o código de 6 dígitos enviado para o seu e-mail.",
  registerDeviceSendButtonText: "Mandar",
  registerDeviceSuccessMessageOne: "O registro do dispositivo está completo",
  registerDeviceSuccessMessageTwo: "Agora você pode fazer login novamente",

  profileEmptyResultsPM: "Você ainda não tem métodos de pagamento associados.",
  profileEmptyResultsInvoices: "Você ainda não tem faturas em seu nome.",

  cancelSubCancelText: "Obrigado por passar um tempo conosco. Você é sempre bem-vindo de volta. Conclua seu cancelamento abaixo.",
  cancelSubListOne: "O cancelamento entrará em vigor no final do seu período de cobrança atual em",
  cancelSubListSecond: "Volte quando quiser. todas as suas preferências, produtos, dispositivos estarão esperando por você.",
  cancelWarningAd: "Lembre-se que a alteração do plano exclui os dias restantes do plano atual.",
  cancelSubFinishButtonText: "Cancelamento final",
  cancelSubChangePlanTitle: "Alterar Plano",
  cancelSubChangePlanText: "Mudar de plano é fácil, você pode alterar seu plano por um preço mais baixo abaixo.",
  cancelSubNoPlansText: "Deseja continuar com sua assinatura?",

  reactivationMessageText: "Sua assinatura termina a próxima data de corte, você pode reativar sua assinatura e continuar com seus pagamentos mensais.",
  reactivationMessageButtonText: "Reativar",
  reactivationMessageLoadingText: "Estamos reativando sua assinatura",

  /* Products Orden */
  productsFilterOrderNewer: "Mais novo",
  productsFilterOrderOldest: "Mais antigo",
  productsFilterOrderTitleAsc: "Título - Ascendente",
  productsFilterOrderTitleDesc: "Título - Descendente",

  productsFilterNoResults: "Sem opções",

  productsListTitleResults: "Resultados",
  productsListFiltersTitleCategory: "Categoria",
  productsListFiltersTitleLang: "Idioma",
  productsListFiltersTitleSort: "Pedido",

  productsHeaderProductType: "Tipo Producto",

  productsFiltersKeyFormatedSortBy: "Pedido",
  productsFiltersKeyFormatedCategory: "Categoria",
  productsFiltersKeyFormatedLang: "Idioma",

  productsAdminButtonMyProductsText: "Meus produtos",
  productsAdminButtonChangeViewText: "Alternar visualização",
  productsAdminButtonCategoriesText: "Categorias",
  productsAdminButtonNewProductText: "Adicionar produto",

  listResultsOf: "de",

  productCategoriesAddTitle: "Adicionar categoria",
  productCategoriesAddCategoryName: "Nome da Categoria",

  productEditTitleNew: "Novo produto",
  productEditTitleUpdate: "Atualizar produto",
  productEditProductType: "Tipo de produto (Viraly)",
  productEditProductCategory: "Categoria de Produto (Viraly)",
  dropshippingOrEcommerceType: "Tipo de produto",
  addIdentifiers: "Adicionar identificadores",
  identifier: "Identificador",
  productEditTitleProductDesc: "Descrição do produto",
  productEditTitleProductDescText: "Você pode criar diferentes descrições do produto, para cada um dos idiomas disponíveis, por sua vez você pode indicar quais das versões serão publicadas e quais não serão.",
  productEditControlsStatus: "Status do produto",
  productEditControlsStatusPublicText: "O produto ficará visível para os usuários.",
  productEditMediaElementsTitle: "Elementos de mídia",
  productEditMediaElementsByLang: "Por idioma",
  productEditMediaElementsForEveryone: "o mesmo em todas as línguas",
  productEditPreviewButtonText: "Visualizar",
  productEditSaveAllButtonText: "Salvar tudo",
  productEditDescStatusLabel: "STATUS",
  productEditDescChangeStatus: "Alterar status",
  productEditDescChangeStatusDraft: "Borracha",
  productEditDescChangeStatusPublic: "Publico",
  productEditDescProductName: "Nome do produto",
  productEditDescProductDesc: "Descrição do corpo do produto",
  productEditMediaElementsTitleDnD: "Arraste e solte alguns arquivos aqui ou clique para selecionar os arquivos",
  productEditMediaElementsTitleDnDDrop: "Solte os arquivos aqui.",
  productEditMediaElementsUploadingError: "Erro ao fazer upload do arquivo",
  productEditMediaElementsUploadRejected: "Apenas os seguintes tipos de arquivo são permitidos",
  productEditProductOrg: "Organização do produto",
  productEditProductOrgType: "Tipo de produto",
  productEditProductOrgTags: "Rótulos de produtos",
  productEditProductOrgTagsPla: "Adicionar novas etiquetas",
  productEditProductSEOTitle: "SEO do produto",
  productEditProductSEOTitlePage: "Título da página",
  productEditProductSEOCharsUsed: "caracteres usados",
  productEditProductSEOMetaDesc: "Descrição do alvo",

  productEditMediaElementsDeleteFileTitle: "Excluir arquivo",
  productEditMediaElementsDeleteFileDesc: "Está seguro? Você não pode desfazer esta ação mais tarde.",

  productEditTranferMediaTitle: "Transferir arquivos",
  productEditTranferMediaText: "Foi detectado que você possui elementos multimídia em algumas das descrições, os seguintes arquivos serão transferidos.",
  productEditTranferMediaButtonText: "Transferir",

  productEditVariantsTitle: "Variantes de produtos",
  productEditVariantsCheckbox: "Este produto tem variantes",
  productEditVariantsOptionsTitle: "Opções",
  productEditVariantsOptionsButtonAdd: "Adicionar outra opção",
  productEditVariantsOptionsInfoMessage: "Cada valor de cada opção deve ser separado por uma vírgula",
  productEditVariantsOptionsAddName: "Nome da opção",
  productEditVariantsOptionsAddNamePlaceholder: "Cor",
  productEditVariantsOptionsAddValues: "Valores das opções",
  productEditVariantsOptionsAddValuesPlaceholder: "Vermelho, Verde, Laranja, etc.",
  productEditVariantsListTitle: "Variantes",
  productEditVariantsListMessage: "Depois de salvar o produto, você poderá adicionar uma imagem a cada variante.",

  productEditCopyTitle: "Copy / Elementos de Marketing",
  productEditCopyDescription: "Texto - Descrição",
  productEditCopyDescriptionPlaceholder: "Escreva o texto aqui, você pode usar emojis.",
  productEditCopyMediaTitle: "Miniaturas / Videos",

  productEditUnsavedChangesMessage: "Você tem alterações não salvas, deseja sair da página?",

  productEditInfoModalButtonTextTitle: "Ajuda - Informações",
  productEditInfoModalWarning: "Descrições com status em branco ou que não contenham os campos: Título, Corpo - Descrição, Multimídia (se necessário) serão ignoradas e não serão salvas.",
  productEditInfoModalPrimaryText: "Nesta seção você encontrará as informações necessárias para criar um produto corretamente. Ao salvar um produto, alguns parâmetros listados abaixo devem ser atendidos.",
  productEditInfoModalFirstTitle: "Produto Geral - Digital",
  productEditInfoModalSecondTitle: "Produto Google",
  productEditInfoModalThirdsTitle: "Produto Facebook",
  productEditInfoModalFirstText: "Todos os produtos devem conter os seguintes campos preenchidos para poder salvar o referido produto",
  productEditInfoModalFirstItem1: "Tipo de produto, Categoria",
  productEditInfoModalFirstItem2: "Pelo menos uma das descrições deve conter: Título, Corpo - Descrição, pelo menos um arquivo multimídia",
  productEditInfoModalFirstItem3: "No caso de querer publicar o produto para acesso do cliente, uma das descrições com as características mencionadas deve ter seu status em",
  productEditInfoModalFirstItem4: "Caso a mídia seja única para todos os idiomas, deve conter no mínimo um arquivo de mídia",
  productEditInfoModalSecondText1: "Para salvar um produto do tipo Google no modo",
  productEditInfoModalSecondText2: "deve conter as características de um produto geral, caso queira publicá-lo, deve ter algumas características adicionais.",
  productEditInfoModalTextOthersItems: "Todas as características de um produto geral",
  productEditInfoModalSecondItem: "Os dados devem estar completos",
  productEditInfoModalThirdText1: "Para salvar um produto do tipo Facebook no modo",
  productEditInfoModalThirdItem: "Os dados devem estar completos",

  productSavingMessage: "Estamos salvando informações do produto ...",

  productEditTitle: "Editar Produto",
  productEditionModeLabel: "Modo de edição",
  productEditMetadataTitle: "Metadados do Produto",
  productEditMetadataCreatedAt: "Criou o",
  productEditMetadataUpdatedAt: "Última Atualização",
  productEditMetadataCreatedBy: "Criado pela",
  productEditMetadataUpdatedBy: "Última atualização por",
  productEditDescMetadataTitle: "Descrição Metadados",

  productEditNotFoundButton: "Ir a productos",

  productEditVariantsSelectImageTitle: "Atualizar imagem variante",
  productEditVariantsSelectImageButtonSelect: "Selecionar",
  productEditVariantsWarningText: "Alterar os valores das opções reconstrói variantes. As imagens adicionadas serão excluídas.",

  productDetailsCopyButtonText: "Copiar Texto",
  productDetailsCopiedButtonText: "Copiado",

  productListFilterButtonText: "Filtros",
  productListEmptyResults: "Nenhum resultado foi obtido, tente outro tipo de filtro.",
  productListCardSeeDetails: "Veja detalhes",

  productDetailsLoadingText: "Estamos carregando o produto...",
  productDetailsReportError: "relatar um problema com o produto",
  productDetailsButtonTextAddToMyStores: "Adicionar à minha loja",
  productDetailsButtonTextRemoveToMyStores: "Remover das minhas lojas",
  productDetailsCopyTitle: "Copy para o Facebook",
  productDetailsCopyVideos: "Vídeos promocionais",
  productDetailsCopyThumbs: "Miniaturas",

  // Productos sugeridos
  suggestProduct: "Sugerir um produto",
  suggestProductProductName: "Nome do Produto",
  suggestProductProductUrl: "URL do Produto",
  suggestProductProductImages: "Imagens do Produto",
  suggestProductProductNameIsRequired: "Nome do produto é obrigatório",
  suggestProductProductUrlIsRequired: "URL do produto é obrigatório",
  suggestProductProductUrlIsInvalid: "URL do produto é inválido",
  suggestProductProductImagesIsRequired: "Imagens do produto são obrigatórias",
  suggestProductProductImagesMinOne: "Pelo menos uma imagem é obrigatória",
  suggestProductSuggestSendedSuccess: "Sugestão enviada com sucesso. Obrigado por sua sugestão!",
  suggestProductMustIncludeMinOne: "Deve incluir pelo menos uma imagem",
  suggestProductSuggestSendedError: "Ocorreu um erro ao enviar a sugestão. Por favor, tente novamente.",
  suggestProductClassifedBy: "Sugerido por",
  suggestProductMarkAsResolved: "Marcar como resolvido",

  planSelecctionTypesTitle: "Selecionar tipos",
  planSelecctionTypesFirstTextOneType: "Selecione 1 tipo de produto para visualizar com seu plano.",
  planSelecctionTypesFirstTextTwoType: "Selecione 2 tipos de produtos para visualizar com seu plano.",
  planSelecctionTypesNote: "Uma vez selecionado e adquirido o plano, você não poderá alterar suas opções.",

  navlinkLogout: "Fechar Sessão",

  productListAdminTooltipTitle: "Status da descrição",
  productDetailsTitle: "Detalhes do Produto",

  storesAddButtonText: "Adicionar Loja",
  stores: "Lojas",
  seeTutorialButtonText: "veja o tutorial",
  seeStore: "Ver loja",
  storesAddShopifyButton: "Loja shopify",
  storesAddShopifyTitle: "Adicionar Loja Shopify",
  storesAddWooButton: "Loja Woocommerce",
  storesAddWooTitle: "Adicionar Loja Woocommerce",
  storeAddShopifyValidationStoreNameMin: "O nome da loja deve ter pelo menos 10 caracteres.",
  storeAddShopifyValidationStoreKeyMin: "A Chave da Loja deve ter pelo menos 20 caracteres.",
  storeAddShopifyValidationStoreSecretMin: "O Token de acesso à API deve ter no mínimo 20 caracteres.",
  storeAddShopifyValidationStoreNameRequired: "O nome da loja é obrigatório.",
  storeAddShopifyValidationStoreKeyRequired: "A chave de armazenamento é necessária.",
  storeAddShopifyValidationStoreSecretRequired: "O Token de acesso à API é necessária.",

  storeAddShopifyStoreNameLabel: "Nome da loja",
  storeAddShopifyStoreKeyLabel: "Chave de loja",
  storeAddShopifyStoreSecretLabel: "Token de acesso à API",

  productCardNoAccessText: "Você não tem acesso para visualizar este produto. Se você deseja ter acesso, você deve atualizar seu plano para um nível superior clicando no botão abaixo.",

  storeDisableTitle: "Desativar Loja",
  storeDisableMessageOne: "Você está prestes a desativar a loja: ",
  storeDisableButton: "Desativar",
  storeListTitle: "Lista de lojas",

  storeExportTitle: "Exportar Produto",
  storeUpdateProductExported: "Atualizar produto exportado",
  storeUpdateProductExportedAlertMessage: "Ao atualizar este projeto, perderá as alterações feitas na sua loja.",
  storeExportFirstMessage: "Você vai exportar o produto:",
  storeExportSecondMessage: "por favor selecione o idioma e a loja onde será exportado",
  storeExportButtonText: "Exportar",

  productListSelectLang: "Idioma dos produtos",
  productListSelectLangDefault: "Predeterminado",
  productListModalStoreAdded: "Na sua loja",

  productBlockModalTitle: "Acesse Novos Catálogos de Produtos",
  productBlockModalSubTitle: "Atualize sua assinatura para ter acesso a um catálogo inteiro com atualizações constantes de produtos adicionais.",

  menuItemMyProducts: "Minhas listas",
  productDetailAddToFolder: "Adicionar a lista",
  productDetailRemoveFromFolder: "Remover da lista",
  productDetailAddNewListButton: "Adicionar nova lista",
  productDetailCreateListInput: "Lista de nomes",

  myListsNoFolderSelected: "Nenhuma pasta selecionada para pesquisar",
  modalDeleteItemTitleFolder: "Excluir Lista",
  modalDeleteItemTitleProduct: "Remover Produto",

  libraryAdsTitle: "Pesquisar anúncios",
  libraryAdsSearchTypeTitle: "Tipo de busca",
  libraryAdsSearchTypeUnordered: "Não ordenado",
  libraryAdsSearchTypeExact: "Frase exata",
  libraryAdsSearchTerms: "Termos de pesquisa (máximo de 100 caracteres)",
  libraryAdsStatusTitle: "Status",
  libraryAdsStatusAll: "Tudo",
  libraryAdsStatusActive: "Ativo",
  libraryAdsStatusInactive: "Inativo",
  libraryAdsMediaTypeTitle: "Tipo de mídia",
  libraryAdsMediaTypeNone: "Nenhum",
  libraryAdsMediaTypeImage: "Imagem",
  libraryAdsCountryTitle: "Pesquisar por países",
  libraryAdsPublisher: "Plataformas de editores",
  libraryAdsPublisherAudience: "Rede de público-alvo",
  libraryAdsLanguages: "Pesquisar por idiomas",
  libraryAdsPagesIds: "IDs de página do Facebook (separados por vírgula)",
  libraryAdsStartDate: "Data de entrega máx. (DD/MM/YYYY)",
  libraryAdsEndDate: "Data de entrega mín. (DD/MM/YYYY)",

  menuItemDevices: "Solicitações de Dispositivos",
  menuItemWithdrawals: "Pedido de Retirada",

  solveCaseModalTitle: "Resolver Caso",
  solveCaseModalStatus: "Solução",
  solveCaseModalStatusPlaceholder: "Selecione uma solução",
  solveCaseModalObservation: "Observação",
  solveCaseModalReason: "Justificação",
  solveCaseModalVoucher: "Anexar documento de apoio",
  uploadFile: 'Carregar ficheiro',
  fileSelected: 'Não seleccionou um ficheiro',
  solveCaseModalObservationPlaceholder: "Descreva brevemente por que a solicitação foi negada ao usuário (máximo de 255 caracteres)",

  solveCaseOptionAprove: "APROVAR",
  solveCaseOptionReject: "DECLÍNIO",
  requestStatusAll: "Status da solicitação (todos)",
  requestStatusRevision: "Revisão",
  requestStatusAllowed: "Permitido",
  requestStatusRejected: "Rejeitado",
  
  statusAll: "Todos",
  statusPending: "Pendente",
  statusDeny: "Negado",
  statusApproved: "Aprovado",

  addAdminTitle: "Adicionar usuário administrador",

  clientEmailInputPlaceholder: "Correio do cliente",

  supplierListTitle: "Provedores",
  supplierListNoteTitle: "Observação",
  supplierListNoteText: "A Viraly não tem contato com nenhum fornecedor nem atua como intermediária, as sugestões apresentadas nesta seção são baseadas nos critérios selecionados pela equipe.",
  supplierCardYears: "Anhos",
  supplierCardResponse: "Tempo de resposta",
  supplierCardTransactions: "Transações",
  supplierCardDelivery: "Taxa de entrega",
  supplierCardPrice: "Preço",

  adShowFilters: "Mostrar filtros",
  adHideFilters: "Ocultar filtros",
  adCardFirstSeen: "Visto pela primeira vez",
  adCardLastSeen: "Visto pela última vez",
  adCardComments: "Comentários",
  adCardShares: "Ações",

  adsActionsSave: "Salvar pesquisa atual",
  adsModalInputPlaceholder: "Pesquisar nome personalizado",
  adsActionsSearchSelect: "Selecionar pesquisa",
  adsActionsFavorites: "Mostrar meus favoritos",

  adDetailsTitle: "Detalhe do anúncio",
  adDetailsSeeProduct: "Ver Produto",
  adDetailsSeePost: "Ver anúncio",
  adDetailsCopyTitle: "Cópia do anúncio do Facebook",
  adDetailsEngageTitle: "Engagement",
  adDetailsAgeTitle: "Idade do Público",
  adDetailsGenderTitle: "Gênero do público-alvo",
  adDetailsGenderMale: "macho",
  adDetailsGenderFemale: "fêmeo",
  adDetailsCountryTitle: "Países do público-alvo",
  adDetailsRunTitle: "Pista do anúncio",
  adDetailsDays: "dias",

  adListFrom: "A partir de",
  adListTo: "para",
  adListOrderTotalReactions: "Reações Totais",
  
  // Retiros
  createWithdrawalAccount: "Conta",
  createWithdrawalButton: "Retirar",
  createWithdrawalPlatform: "plataforma",
  createWithdrawalSuccess: "Pedido de retirada bem sucedido",
  createWithdrawalTitle: "Pedido de retirada",
  createWithdrawalValue: "Valor",
  unordered: "Sem ordem",
  voucherText: "Descarregar voucher",
  withdrawalOrderByAsc: "Ascendente",
  withdrawalOrderByAscUpdate: "Data de actualização antiga",
  withdrawalOrderByDesc: "Descendente",
  withdrawalOrderByDescUpdate: "Data de actualização recente",

  // Referidos
  cancelled: "Cancelado",
  payOut: "Disponível",
  pending: "Pendente",
  finalized: "Finalizado",
  referralFilter: "Filtrar",
  referralFilterEndDate: "Hasta la fecha",
  referralFilterStartDate: "De la fecha",
  referralFilterStatus: "Filtrar por estado",
  referralHeaderActiveUntil: "Ativo Ate",
  referralHeaderBillAmount: "Quantidade",
  referralHeaderCommission: "Comissão",
  referralHeaderCreatedAt: "Criado em",
  referralHeaderDate: "Data",
  referralHeaderName: "Nome",
  referralHeaderPayoutAt: "Paga em",
  referralHeaderStatus: "Status",
  referralCodeValidationMin: "O código de referência deve ter pelo menos 3 caracteres",
  referralCodeValidationEmpty: "O código de referência é obrigatório",
  referralTotalAvailableBalance: "Total Saldo Disponível",
  referralRecordsFound: "registros encontrados",
  referralTotalPendingBalance: "Total Saldo Pendente",
  modifyReferralCodeTitle: "Alterar Código de Referência",

  // Balanço
  referralBalanceTooltip: "Saldo disponível",
  referralTotalBalanceTooltip: "Saldo total",

  // Platform
  messageEmptyPlatform: "Deve seleccionar uma plataforma",
  messageEmptyIdentifier: "Deve introduzir pelo menos um identificador",
  messagePlatformDuplicate: "Esta plataforma já foi acrescentada no passado",
  platformNameField: "Nome",
  platformSiteUrlField: "URL do sítio",
  platformImageUrlField: "Imagem URL",
  productsFilterPlatformField: "Filtrar por Plataforma",

  // CTA Filter
  shopNow: "Compre agora",
  learnMore: "Saiba mais",
  messagePage: "Página de Mensagem",
  signUp: "Inscrever-se",
  getOffer: "Receber Oferta:",
  watchMore: "Veja mais",
  bookNow: "Livro agora",
  downloadUndefined: "Descarregar indefinido",
  download: "Descarregar",
  open: "Abrir",
  applyNow: "Aplicar agora",
  likePagelike: "Gosto dela como uma página",
  subscribeUndefined: "Subscrever Indefinido",
  whatsappMessage: "Mensagem whatsapp",
  getQuote: "Obter Cotação",
  eventRsvp: "Evento rsvp",
  donateNow: "Doe agora",
  playGame: "Jogar jogo",
  contactUs: "Contacte-nos",
  getDirections: "Obter Direcções",
  seeMenu: "Ver menu",
  listenNow: "Oiça agora",
  installApp: "Instalar app",
  buyTickets: "Comprar bilhetes",
  buyNow: "Comprar agora",
  orderNow: "Encomendar agora",
  getShowtime: "Obter tempo de espectáculo",
  callNow: "Telefonar agora",
  watchVideo: "Ver vídeo",
  openLink: "Link aberto",
  requestTime: "Tempo de solicitação",
  findYour: "Encontre o seu",
  useApp: "Usar App",
  likePage: "Gosto desta página",

  // Terms and conditions
  termsTitle: "Termos e condições de uso",
  termsIntro: "Bem-vindo ao Viraly, o site da ECOM BOOSTERS localizado nos Estados Unidos. Ao usar nossa plataforma, você concorda com os termos e condições estabelecidos abaixo.",
  termsValidity: "Data de vigência: 8 de maio de 2023",
  termsItemOne: "Coleta de Informações do Usuário: A Viraly coleta informações pessoais do usuário, incluindo nome, nome de usuário, número de telefone, e-mails, número de cartão de débito/crédito e senha. Ao utilizar nossa plataforma, o usuário concorda com a coleta dessas informações. As informações coletadas são usadas para melhorar a experiência do usuário e processar pagamentos on-line. Estamos empenhados em proteger a sua privacidade e segurança online.",
  termsItemTwo: "O Viraly cancelará contas que serão verificadas como tendo mais de 3 usuários diferentes usando a mesma conta, já que o Viraly foi criado para atender esse número máximo de usuários por conta. A violação deste termo resultará no cancelamento da conta automaticamente sem direito a reembolso.",
  termsItemThree: "Publicidade: A Viraly não exibe anúncios na plataforma.",
  termsItemFour: "Tecnologias de rastreamento: usamos tecnologias, como o Google Analytics, para coletar informações sobre seu uso de nossa plataforma. Essas informações são usadas para melhorar sua experiência de usuário e para fins analíticos internos. Não usamos publicidade ou redirecionamento em nossa plataforma. Além disso, usamos dois pixels do Facebook para nos ajudar a melhorar a qualidade de nossos produtos e serviços. Também usamos armazenamento local para salvar informações de seus dispositivos",
  termsItemFive: "Provedores de mídia social: A Viraly não usa provedores de mídia social na plataforma.",
  termsItemSix: "Conteúdo: A Viraly gera conteúdo para produtos dropshipping e e-commerce, que podem ser utilizados pelo usuário para publicá-lo em seus respectivos sites ou lojas e/ou para utilizar essas informações a seu gosto.",
  termsItemSeven: "Restrições de idade: Viraly não é oferecido a crianças menores de 13 anos.",
  termsItemEight: "Contato: O usuário pode entrar em contato com a Viraly pelo manager.viraly@gmail.com, pelo telefone +57 3215330715 ou visitando o site informativo https://viraly.ai/.",
  termsItemNine: "Pagamentos online: A Viraly aceita pagamentos online através do gateway de pagamento da Stripe. Ao usar este gateway de pagamento, o usuário concorda com os termos e condições do Stripe.",
  termsItemTen: "Pagamentos recorrentes: A Viraly aceita pagamentos online através do gateway de pagamento da Stripe. Pagamentos mensais recorrentes são aceitos em nossa plataforma. Ao usar nossos serviços de pagamento on-line, você concorda em ficar vinculado aos Termos e Condições da Stripe. Para mais informações, visite o site.",
  termsItemEleven: "Responsabilidade: É importante ressaltar que a Viraly não tem contato com nenhum fornecedor ou intermediário. As sugestões apresentadas neste website são baseadas nos critérios selecionados pela equipe da Viraly e não garantem resultados específicos.",
  termsFinalNote: "Ao usar nossa plataforma, você concorda com os termos e condições estabelecidos. Se você tiver alguma dúvida ou preocupação, não hesite em contactar-nos.",

  // Frequent questions
  questionOne: "A minha tenda não foi carregada corretamente?",
  answerOne: 'Certifique-se de que segue as instruções passo a passo explicadas no tutorial em vídeo no separador "as minhas lojas". Verifique se o nome da sua loja está correto " será sempre o nome do domínio myshopify. Deve também validar se, ao copiar e colar os valores, não existem espaços.',
  questionTwo: "Não me permite importar os meus produtos para a minha loja shopify?",
  answerTwo: 'Depois de registar com êxito a sua loja seguindo o vídeo tutorial passo a passo no separador "as minhas lojas", verifique se a importação foi bem sucedida e, em seguida, aceda ao seu perfil shopify e torne o produto PÚBLICO para que possa ser visto na sua página.',
  questionThree: "Não consigo importar os produtos porque não tenho o shopify?",
  answerThree: "Neste caso, o nosso algoritmo permite-lhe copiar, colar e descarregar todo o conteúdo de uma forma conveniente para qualquer página que tenha, e só o pode fazer se não tiver uma loja shopify registada.",
  questionFour: "Não consigo encontrar os contactos do serviço de apoio ao cliente?",
  answerFour: "Pode contactar-nos através do whatsapp +57 321 5330715 ou manager.viraly@gmail.com.",
  questionFive: "Não consigo apagar a minha loja ou os meus dispositivos?",
  answerFive: `Para eliminar qualquer dispositivo registado, deve ir ao separador "dispositivos" e encontrará cada um deles registado, basta clicar no ícone e já está. No caso da loja, o procedimento é o mesmo, bastando ir à secção "as minhas lojas" e clicar no mesmo ícone. Se este processo não for eficaz, o que deve fazer é pedir ajuda ao serviço de apoio ao cliente do WhatsApp. O ícone deve ter o seguinte aspeto`,
  whyLeaveUs: "Qual é o motivo que o leva a deixar-nos?",
  doesNotSatisfy: "Não satisfaz as suas necessidades.",
  fewProducts: "Muito poucos produtos semanais.",
  doNotLikeTheContent: "Não gosta do conteúdo que é carregado.",
  other: "Outro.",
  writeTheReason: "Escrever o motivo.",
  YouMustWriteValidation: "Não escreveu nada.",
  findProduct: "Este produto pode ser encontrado em plataformas locais de dropshipping na Colômbia.",
};

export default ptTranslations;