import { atom } from 'recoil';

export interface Folder {
  id: string;
  name: string;
};

export interface UsersListsStateProps {
  limit: number;
  page: number;
  totalResults: number;
  totalPages: number;
  statusFilter?: string;
  roleIdFilter?: number;
  emailFilter?: string;
  subscriptionFilter?: string;
  users: UserItem[]
};

export interface ReferralsByUserListStateProps {
  limit: number;
  page: number;
  totalResults: number;
  totalPages: number;
  users: UserItem[]
};

export interface ReferralsByUserListCardItem {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  avatarUrl: string;
  role: string;
  subscription: string;
  totalReferrals: string;
  activeReferrals: string;
};

export interface UserItem {
  userId: string;
  fullName: string;
  email: string;
  status: string;
  avatarUrl: string;
  role: string;
  subscription: string;
  countryCode: string;
  referralCode: string;
  totalReferrals: string;
  activeReferrals: string;
};

export interface UserModalProps {
  isOpen: boolean;
  modal: 'none' | 'new-user' | 'block-user' | 'update-referral-code';
  userId?: string;
  userName?: string;
};

const usersListDefaultValue: UsersListsStateProps = {
  limit: 15,
  page: 1,
  totalResults: 0,
  totalPages: 1,
  users: []
};

const newUserModalDefaultValue: UserModalProps = {
  isOpen: false,
  modal: 'none'
};

export const userProductFoldersState = atom<Folder[]>({
  key: 'userProductFolders',
  default: []
});

export const usersListsState = atom<UsersListsStateProps>({
  key: 'usersList',
  default: usersListDefaultValue
});

export const userModalState = atom<UserModalProps>({
  key: 'userModal',
  default: newUserModalDefaultValue
});

export const referralsByUserListState = atom<ReferralsByUserListStateProps>({
  key: 'referralsByUserList',
  default: usersListDefaultValue
});