import { FetchRest } from "@config/httpClient";

interface LoginUserServiceProps {
  email: string;
  password: string;
  deviceId: string;
  lang: string;
};

export const loginUserService = async (props: LoginUserServiceProps) => {
  let { deviceId, email, lang, password } = props;
  
  const emailKeyStorage = `device${email}`;
  
  if (localStorage.getItem(emailKeyStorage) === null) {
    localStorage.setItem(emailKeyStorage, deviceId);
  }else{
    if(localStorage.getItem('deviceId') != localStorage.getItem(emailKeyStorage))
      localStorage.setItem('deviceId', localStorage.getItem(emailKeyStorage) || '')
      deviceId = localStorage.getItem('deviceId') || '';  
  }
  const deviceIdStorage = localStorage.getItem(emailKeyStorage);

  const response = await FetchRest<any>({
    uri: '/users/login',
    method: 'POST',
    requireAuth: true,
    body: { email, password, deviceId }
  });

  if (response.error) {
    const message = response.messages[lang];
    throw new Error(message);
  };

  const data = response.data;
  return data;
};