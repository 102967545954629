import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  description?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const PageMetadata = ({ title, description, children }: Props) => {
  return (
    <Helmet>
      <title>Viraly - {title}</title>
      {description && <meta name="description" content={description} />}
      {children}
    </Helmet>
  );
};
