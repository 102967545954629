import { ReactText } from 'react';
import { NavLink } from 'react-router-dom';
import { Flex, FlexProps, Icon, useColorModeValue } from '@chakra-ui/react';

import { IconType } from 'react-icons';

interface Props extends FlexProps {
  icon: IconType;
  children: ReactText;
  path: string;
  isMenuCollapsed: boolean;
}

export const NavItem = ({
  icon,
  children,
  path,
  isMenuCollapsed,
  ...rest
}: Props) => {
  const bgHoverItem = useColorModeValue('brand.600', 'brand.300');
  const colorItem = useColorModeValue('brand.600', 'whiteAlpha.900');
  const colorHoverItem = useColorModeValue(
    'rgb(124 148 166 / 10%)',
    'rgb(159 178 193 / 10%)'
  );

  return (
    <NavLink to={path}>
      {({ isActive }) => (
        <Flex
          _hover={{
            bg: colorHoverItem,
            borderRightColor: bgHoverItem,
            borderRightWidth: '5px',
            color: colorItem,
          }}
          align="center"
          bg={isActive ? colorHoverItem : 'transparent'}
          borderRightColor={bgHoverItem}
          borderRightWidth={isActive ? '5px' : 0}
          color={isActive ? colorItem : undefined}
          cursor="pointer"
          fontSize={{ base: 'xl', md: 'inherit' }}
          justify={isMenuCollapsed ? 'center' : 'inherit'}
          p="4"
          pl={isMenuCollapsed ? 4 : 8}
          role="group"
          transition="all .2s ease"
          {...rest}
        >
          {icon && (
            <Icon
              mr={isMenuCollapsed ? 0 : 4}
              fontSize={{ base: 'xl', md: '20' }}
              as={icon}
            />
          )}
          {!isMenuCollapsed && children}
        </Flex>
      )}
    </NavLink>
  );
};
