import * as Yup from 'yup';
import registerFormModel from './registerFormModel';

const { formField: { firstName, lastName, email, password, confirm, terms, phoneNumber } } = registerFormModel;

const registerFormSchema = (
  firstErrors: string[], 
  lastErrors: string[], 
  emailErrors: string[], 
  passwordErrors: string[]
) => ([
  Yup.object().shape({}),
  Yup.object().shape({
    [firstName.name]: Yup.string()
      .required(firstErrors[0])
      .min(3, firstErrors[1])
      .max(50, firstErrors[2]),
    [lastName.name]: Yup.string()
      .required(lastErrors[0])
      .min(3, lastErrors[1])
      .max(50, lastErrors[2])
  }),
  Yup.object().shape({
    [email.name]: Yup.string()
      .email(emailErrors[0])
      .required(emailErrors[1]),
    [password.name]: Yup.string().required(passwordErrors[0]).min(8, passwordErrors[1]),
    [confirm.name]: Yup.string().required(passwordErrors[2]).oneOf([Yup.ref(password.name), null], passwordErrors[3]),
    [phoneNumber.name]: Yup.string().required().min(5).max(11),
    [terms.name]: Yup.bool().oneOf([true], passwordErrors[4]),
  })
]);

export default registerFormSchema;