import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Icon,
  IconButton,
  Portal,
  useBreakpointValue,
  Tooltip,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';

import { IoLanguageOutline } from 'react-icons/io5';

export const SelectLanguaje = () => {
  const { t, i18n } = useTranslation();

  const languages = [
    { value: 'en', code: 'US', label: t('english') },
    { value: 'es', code: 'ES', label: t('spanish') },
    { value: 'pt', code: 'PT', label: t('portuguese') },
  ];

  const handleChangeLanguage = (lang: any) => {
    i18n.changeLanguage(lang);
  };

  const isMenuIconButton = useBreakpointValue({ base: true, md: false });

  return (
    <Menu closeOnSelect>
      {!isMenuIconButton && (
        <Tooltip hasArrow label={t('language')} bg='gray.600' borderRadius="md" color="white">
          <MenuButton
            display="flex"
            alignItems="center"
            size="sm"
            as={Button}
            colorScheme="brand"
            variant="outline"
          >
            <Icon as={IoLanguageOutline} fontSize="lg" />
          </MenuButton>
        </Tooltip>
      )}
      {isMenuIconButton && (
        <Tooltip hasArrow label={t('language')} bg='gray.600' borderRadius="md" color="white">
          <MenuButton
            as={IconButton}
            aria-label={t('language')}
            size="sm"
            colorScheme="brand"
            variant="outline"
            icon={<Icon as={IoLanguageOutline} />}
          />
        </Tooltip>
      )}
      <Portal>
        <MenuList zIndex={4}>
          <MenuOptionGroup
            onChange={handleChangeLanguage}
            value={i18n.language}
            title={t('language')}
            type="radio"
          >
            {languages.map((lang) => (
              <MenuItemOption key={lang.value} value={lang.value}>
                <ReactCountryFlag svg countryCode={lang.code} />
                <span style={{ marginLeft: 5 }}>{lang.label}</span>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
};
