const esTranslations = {
  language: "Idioma",
  english: "Inglés",
  spanish: "Español",
  portuguese: "Portugués",
  loginWelcome: "Bienvenidos a",
  loginTitle: "Iniciar sesión",
  emailValidation: "Debe ser un correo valido",
  emailValidationEmpty: "El correo es un campo obligatorio",
  passValidation: "La contraseña es un campo obligatorio",
  passValidationMin: "La contraseña debe contener minimo 8 caracteres",
  confirmValidation: "Las contraseñas deben coincidir",
  confirmValidationRequired: "La confirmación de contraseña es obligatoria",
  emailField: "Correo electronico",
  passField: "Contraseña",
  confirmField: "Confirmación de contraseña",
  phoneField: "celular",
  selectCountryCode: "Selecciona correctamente el indicativo de tu país para garantizarte una buena comunicación.",
  loginButtonText: "Ingresar",
  forgotPassText: "¿Olvidaste tu contraseña?",
  noAccountText: "¿No tienes una cuenta?",
  noAccountLink: "Registrarme",
  haveAccountText: "¿Tienes una cuenta?",
  haveAccountLink: "ingresar",
  rememberUser: "Recordar usuario",
  registerTitle: "Nuevo Registro",
  firstNameField: "Nombres",
  lastNameField: "Apellidos",
  firstNameValiationEmpty: "los nombres son un un campo obligatorio",
  firstNameValiationMin: "Nombres no pueden contener menos de 3 caracteres",
  firstNameValiationMax: "Nombres no pueden contener más de 50 caracteres",
  lastNameValiationEmpty: "El apellido es un campo obligatorio",
  lastNameValiationMin: "El apellido no puede contener menos de 3 caracteres",
  lastNameValiationMax: "El apellido no puede contener más de 50 caracteres",
  referralCodeField: "Código de referido",
  copyReferralCode: "Copiar código de referido",
  copyReferralLink: "Copiar enlace de referido",
  withoutReferralCode: "Sin código de referido",
  termsTextOne: "Al registrarte aceptas que estas de acuerdo con nuestros",
  registerButtonText: "Registrarme",
  successUserRegister: "Usuario registrado con exito, tendrás totalmente gratis el acceso a nuestros productos por 24 horas",
  verifyingAuthOne: "Estamos verificando tu usuario.",
  verifyingAuthTwo: "Esto tomara unos segundos ..",
  userSignUpWithSocial: "Estamos creando tu usuario en Viraly.",
  requiredFieldsText: "Los campos marcados con * son requeridos",
  backButtonText: "Regresar",
  continueButtonText: "Continuar",
  signupGoogle: "Registrarse con Google",
  signupFacebook: "Registrarse con Facebook",
  signupEmail: "Registrarse con email",
  orOption: "O",
  signinGoogle: "Ingresar con Google",
  signinFacebook: "Ingresar con Facebook",
  linkToMentorForm: "¿Quieres unirte a nuestro programa de afiliados?",
  joinHere: "Unete aquí",
  affiliateTag: "AFILIADO",
  recoveryPassTitle: "Recuperación de contraseña",
  rememberPassTextLink: "¿Recordaste tu contraseña?",
  returnToHome: "Regresar al inicio",
  emailSendedForRecoveryOne: "Si el email coincide con una cuenta existente, enviaremos un correo electrónico de restablecimiento de contraseña en algunos segundos.",
  emailSendedForRecoveryTwo: "Si no recibiste el correo electrónico, revisa tu carpeta de spam o",
  emailSenderForRecoverySupport: "contacta a Soporte al cliente.",
  messageVerifyingToken: "Estamos verificando el token, por favor espera.",
  updatePassButtonText: "Actualizar",
  paymentFormTitle: "Formulario de pago",
  paymentDate: "Fecha de pago",
  paymentFormPrice: "Precio",
  paymentFormPlan: "Subscripción",
  paymentFormPayButton: "Pagar",
  paymentFormCardNumberField: "Número de tarjeta",
  paymentFormExpirationDateField: "Fecha de caducidad",
  paymentFormStripeProtect: "Todos tus pagos están protegidos y gestionados por",
  paymentFormTerms: "al continuar aceptas que estas de acuerdo con nuestros",
  paymentFormTermsLink: "Términos y condiciones",
  paymentFormMessageProcessing: "Estamos procesando tu pago, se paciente por favor.",
  invoiceDetailsPaymentTitle: "Pago Exitoso",
  invoiceDetailsID: "ID Factura",
  invoiceDetailsSummary: "Resumen",
  invoiceDetailsDownload: "Descargar Factura",
  pricingTitle: "Planes que se ajustan a tu necesidad",
  pricingButton: "Elegir Plan",
  mostPopularText: "Más Popular",
  menuItemDashboard: "Dashboard",
  menuItemProducts: "Productos Viraly",
  menuItemFrequentQuestions: "Preguntas frecuentes",
  menuItemPlatforms: "Plataformas",
  menuItemStores: "Mis Tiendas",
  menuItemAds: "Viraly Ads",
  menuItemArticles: "Artículos",
  menuItemUsers: "Usuarios",
  menuItemProfile: "Perfil",
  menuItemReferrals: "Referidos",
  menuItemSuggestProducts: "Sugerir productos",
  menuItemSuggestedProducts: "Productos sugeridos",
  month: "Mes",
  topProductsTitle: "Productos top de la semana",
  referralTagTransactions: "Transacciones",
  referralTagList: "Referidos",
  totalReferrals: "Total de referidos",
  activeReferrals: "Total activos",
  referralTagWithdraws: "Retiros",
  copiedCode: "Código copiado",
  profileTabPersonalInfo: "Datos personales",
  profileTabSubscription: "Suscripción",
  profileTabConfig: "Dispositivos",
  documentTypeField: "Tipo de documento",
  documentNumberField: "Número de documento",
  phoneNumberField: "Número de teléfono",
  countryField: "País",
  cityField: "Ciudad",
  zipCodeField: "Código postal",
  profileTextNonEditableFields: "En los campos deshabilitados no es posible modificar la información con el fin de mantener la integridad de la misma, si deseas actualizar esta información por favor comunicate con soporte.",
  textButtonSave: "Guardar",
  profileSubCurrentBill: "Factura actual",
  profileSubNextPayment: "Próximo pago",
  profileSubPlan: "Plan actual",
  discountCoupon: "Tienes un cupon de descuento del",
  profileSubPMTitle: "Métodos de pago",
  profileSubAddPM: "Agregar método de pago",
  profileSubBillingText: "Historial de facturación",
  profileSubBillingMessage: "Unicamente cargamos las ultimas 8 facturas",
  profileSubIsDefault: "Predefinido",
  profileSubEdit: "Editar",
  profileSubExpires: "Expira",
  profileSubInvoiceDate: "Fecha",
  profileAddPMPerson: "Nombre del titular de la tarjeta",
  profileAddPMisDefault: "Establecer por defecto",
  profileAddPMTitle: "Agregar Método de Pago",
  profileUpdatePMTitle: "Actualizar Método de Pago",
  profilePMNote: "Nota",
  profilePMNoteMessage: "Al agregar un método de pago no podemos asegurar que funcione correctamente para pagos futuros, por favor asegurate de que tu tarjeta funcione correctamente.",
  prfilePMCancelButton: "Cancelar",
  profilePMTitleToast: "Datos actualizados",
  maintenanceTitle: "Estamos en mantenimiento",
  maintenanceMessage: "Estamos realizando mejoras en nuestro sistema, se podría presentar algunas fallas durante el sábado 13 y domingo 14 de abril. Agradecemos tu comprensión.",
  communityInvitationTitle: "¡Únete a nuestra comunidad Skool!",
  communityMessageInvitation: "¡Te invitamos a unirte a nuestra comunidad Skool! Allí encontrarás capacitaciones, preguntas frecuentes, recomendaciones y toda la información relevante sobre Viraly para mejorar tu proceso de ventas.",
  goToCommunity: "Ir a la comunidad",

  // Default
  close: "Cerrar",
  actions: "Acciones",
  income: "Ingresos",
  incomeSingle: "Ingreso",
  expenses: "Egresos",
  expenseSingle: "Egreso",
  type: "Tipo",
  amount: "Monto",
  description: "Descripción",
  motive: "Motivo",
  date: "Fecha",
  name: "Nombre",
  deleteTitle: "Eliminar",
  deleteBody: "¿Está seguro de que desea eliminar este elemento?",
  deleteIdentifier: "¿Está seguro de que desea remover el identificador?",
  deleteCancelButton: "Cancelar",
  titleAcquirePlan: "Adquiere tu plan",
  bodyAcquirePlan: "Adquiere nuestro plan y descubre nuestro catálogo completo",
  headerEmail: "Correo",
  headerStatus: "Estado",
  referralStatusRegistered: "Registrado",
  referralStatusActived: "Suscrito",
  referralStatusCancelled: "Cancelado",
  commission: "Comisión",
  withdrawal: "Retiro",
  accepted: "Aceptado",
  confirmationMessage: "Por favor confirme la acción",
  thankYouTitle: "gracias por ser parte de nuestra comunidad",
  thankYouBody: "Con tu suscripción, todos los servicios premium han sido desbloqueados, para acceder a estos puedes utilizar el panel principal de la izquierda.",
  termsAndConditions: "Términos y condiciones",
  themeColor: "Color del tema",
  new: "Nuevo",
  contact: "Contacto",
  contactModalDescription: "Si tienes alguna pregunta, no dudes en contactarnos.",
  community: "Comunidad Skool",

  /* Validaciones para el formulario de personal Info dentro del perfil */
  piDocumentTypeOneOf: "Debe seleccionar una de las opciones.",
  piDocumentNumberMin: "El campo debe contener al menos 5 caracteres.",
  piDocumentNumberMax: "El campo debe contener un máximo de 15 caracteres.",
  piCountryMin: "El campo debe contener al menos 4 caracteres.",
  piCountryMax: "El campo debe contener un máximo de 50 caracteres.",
  piCityMin: "El campo debe contener al menos 4 caracteres.",
  piCityMax: "El campo debe contener un máximo de 50 caracteres.",
  piZipCodeMin: "El campo debe contener al menos 3 caracteres.",
  piZipCodeMax: "El campo debe contener un máximo de 6 caracteres.",
  piPhoneNumberCodeMin: "El campo debe contener al menos 1 caracteres.",
  piPhoneNumberCodeMax: "El campo debe contener un máximo de 3 caracteres.",
  piPhoneNumberCodeReq: "Se requiere el código de país si se proporciona un número de teléfono.",
  piPhoneNumberMin: "El campo debe contener al menos 8 caracteres.",
  piPhoneNumberMax: "El campo debe contener un máximo de 14 caracteres.",
  billingBoxesTimezone: "Conversión horaria",
  billingBoxesChangePlan: "Cambiar Plan",
  changeSubscriptionAsAdminMessageHelper: "Desde aquí podrás cambiar la subscripción de los usuarios. En caso de no poner fecha de finalización, la subscripción será permanente.",
  subscriptionRequired: "Debes seleccionar una subscripción",
  subscriptionActiveUntil: "Fecha de finalización",
  subscriptionActiveUntilMinDateValidation: "La fecha de finalización debe ser mayor a la fecha actual.",
  subscriptionActiveUntilHelper: "Dejar este campo vacío indicará que la subscripción será permanente.",
  billingBoxesChangePlanAsAdmin: "Cambiar plan como Administrador",
  billingBoxedActiveFrom: "Activo desde",

  profileHeaderTitle: "Perfil",
  profileHeaderActiveFrom: "Miembro desde",
  profileHeaderAuthType: "Registrado con",
  profileHeaderActiveSessions: "Sesiones activas",
  profileHeaderUserRole: "Rol del usuario",
  profileHeaderChangeAvatar: "Cambiar avatar",
  profileHeaderChangePassword: "Cambiar contraseña",
  profileHeaderBlockUser: "Bloquear usuario",
  profileViewReferralPayments: "Ver pagos",

  /* Products */
  productsFilterOrderField: "Ordenar Por",
  productsFilterCategoryField: "Filtrar por Categoría",
  productsFilterSearchField: "Buscar Por Título",
  productsFilterLangField: "Filtrar por Idioma",
  productsFilterStatusField: "Filtrar por Estado",
  productsFilterAnyLang: "Cualquier idioma",
  productsFilterAllLang: "Todos",
  productsFilterAlertMessage: "Si un producto te gusta y no se encuentra el ID de tu pais, puedes buscarlo por el nombre; seguramente también está disponible en la plataforma",

  /* Toolbar Tooltips editor */
  toolbarParagraph: "Párrafo",
  toolbarHeading: "Título",
  toolbarBold: "Negrita",
  toolbarItalic: "Itálica",
  toolbarStrike: "Tachado",
  toolbarHighlight: "Destacar",
  toolbarBulletList: "Lista de viñetas",
  toolbarOrderedList: "Lista ordenada",
  toolbarBlockQuote: "Bloque de Cita",
  toolbarHorizontalRule: "Regla horizontal",
  toolbarLink: "Enlace",
  toolbarImage: "Agregar Imagen",
  toolbarUndo: "Deshacer",
  toolbarRedo: "Rehacer",
  toolbarAlignLeft: "Alinear a la izquierda",
  toolbarAlignCenter: "Alinear al centro",
  toolbarAlignRight: "Alinear a la derecha",
  toolbarAlignJustify: "Justificar",

  /* Profile Header Extras */
  profileHeaderPassRecoveryTitle: "Cambiar Contraseña",
  profileHeaderPassRecoveryCurrent: "Contraseña actual",
  profileHeaderPassRecoveryNew: "Nueva contraseña",
  profileHeaderPassRecoveryNewConfirm: "Confirmación nueva contraseña",
  profileHeaderPassRecoveryNewMessageHelper: "Por seguridad de la información tu sesión actual finalizara una vez actualizada la contraseña.",
  profileHeaderConfirmEmailTextLink: "Enviar correo electrónico de confirmación",
  profileHeaderConfirmEmailIsSending: "Estamos enviando el código a tu correo.",
  profileHeaderConfirmEmailIsCodeSended: "Ingresa el código de 9 digitos enviado a tu correo",
  profileHeaderConfirmEmailIsCodeSendedTip: "puedes copiar y pegar el código.",

  contentNotFoundTitle: "Página no encontrada",
  contentNotFoundDesc: "La página que buscas no parece existir",
  contentNotFoundButton: "Ir al Dashboard",

  renewPlanBarTitle: "Estás usando Viraly de manera gratuita, actualiza tu plan para obtener todos los beneficios",
  renewPlanBarTitleMBA: "Por ser miembro del MBA tienes acceso a viraly durante 10 dias gratis. Actualiza tu plan cuando esta oferta expire",
  renewPlanBarTitleTrial: "Disfruta todos los beneficios de viraly durante 24 horas",
  renewPlanBarButton: "Actualiza tu plan",
  frequencyPlan: "Seleccione la frecuencia del plan",
  monthlyPlan: "Mensual",
  biannualPlan: "Semestral",
  annualPlan: "Anual",
  saveYourselfTextBegin: "Ahórrate",
  saveYourselfTextEnd: "pagando el año completo",

  pricingPageTitleCancel: "Cancelar Plan",
  pricingSelectPlanRecurrency: "mes",
  pricingSelectPlanSelected: "Seleccionado",
  pricingSelectPlanCurrent: "Plan Actual",
  bronze: "Bronce",
  silver: "Plata",
  gold: "Oro",

  checkoutFormCreatingPaymentIntent: "Estamos generando tu orden de pago, ten paciencia ...",
  checkoutFormPaymentSuccessfuly: "Tu pago fue exitoso, actualizaremos la página para obtener tus datos actualizados. ✅",

  changePlanResume: "Resumen",
  changePlanInfoText: "Al cambiar de plan se aplica un prorrateo para ajustar el balance con el cambio de subscripción.",
  changePlanItem: "Descripción del Artículo",
  changePlanAmount: "Monto",
  changePlanNextPayment: "Próxima fecha de pago",
  chnagePlanSelectCard: "Selecciona un método de pago",
  changePlanNoteText: "La nueva suscripción se activara inmediatamente y los prorrateos se cobraran en la próxima fecha de pago.",

  profileModalDeleteCardTitle: "Eliminar Tarjeta",
  profileModalDeleteCardMessageOne: "¿Está seguro de eliminar la tarjeta: ",
  profileModalDeleteCardMessageTwo: "? No puedes deshacer esta acción después.",
  profileModalDeleteCardMessageIsDefault: "Para eliminar esta tarjeta debes seleccionar antes un nuevo método de pago por defecto.",
  profileModalDeleteCardButton: "Eliminar",

  profileCancelSubTitle: "Cancelar suscripción",
  profileCancelSubMessage: "Al cancelar tu suscripción dejaras de tener acceso a las funcionalidades que Viraly te ofrece.",

  devicesCardTitle: "Dispositivos registrados",
  devicesCardClient: "Cliente",
  devicesCardClientV: "Versión del cliente",
  devicesCardInUse: "Actual en uso",

  registerDeviceTitle: "Registrar dispositivo",
  registerDeviceMessageSend: "Ingresa el código de 6 dígitos enviado a tu email.",
  registerDeviceSendButtonText: "Enviar",
  registerDeviceSuccessMessageOne: "El registro del dispositivo ha finalizado",
  registerDeviceSuccessMessageTwo: "Ya puedes iniciar sesión nuevamente",

  profileEmptyResultsPM: "Aún no tienes métodos de pago asociados.",
  profileEmptyResultsInvoices: "Aún no tienes facturas a tu nombre.",

  cancelSubCancelText: "Gracias por pasar tiempo con nosotros. Siempre eres bienvenido de vuelta. Termina tu cancelación a continuación.",
  cancelSubListOne: "La cancelación será efectiva al final de su período de facturación actual el",
  cancelSubListSecond: "Vuelve cuando quieras. todas tus preferencias, productos, dispositivos te estarán esperando.",
  cancelWarningAd: "Recuerda que al cambiar el plan, se excluyen los días restantes del plan actual.",
  cancelSubFinishButtonText: "Finalizar cancelación",
  cancelSubChangePlanTitle: "Cambiar de plan",
  cancelSubChangePlanText: "Cambiar de plan es fácil, puedes cambiar tu plan a un precio más bajo a continuación.",
  cancelSubNoPlansText: "¿Quieres continuar con tu suscripción?",

  reactivationMessageText: "Tu suscripción finaliza la proxima fecha de corte, puedes reactivar tu suscripción y continuar con tus pagos mensuales.",
  reactivationMessageButtonText: "Reactivar",
  reactivationMessageLoadingText: "Estamos reactivando tu suscripción",

  /* Products Orden */
  productsFilterOrderNewer: "Más nuevo",
  productsFilterOrderOldest: "Más antiguo",
  productsFilterOrderTitleAsc: "Título - Ascendente",
  productsFilterOrderTitleDesc: "Título - Descendente",

  productsFilterNoResults: "No hay opciones",

  productsListTitleResults: "Resultados",
  productsListFiltersTitleCategory: "Categoria",
  productsListFiltersTitleLang: "Idioma",
  productsListFiltersTitleSort: "Orden",

  productsHeaderProductType: "Tipo Producto",

  productsFiltersKeyFormatedSortBy: "Orden",
  productsFiltersKeyFormatedCategory: "Categoria",
  productsFiltersKeyFormatedLang: "Idioma",

  productsAdminButtonMyProductsText: "Mis productos",
  productsAdminButtonChangeViewText: "Alternar vista",
  productsAdminButtonCategoriesText: "Categorias",
  productsAdminButtonNewProductText: "Agregar producto",

  listResultsOf: "de",

  productCategoriesAddTitle: "Agregar Categoría",
  productCategoriesAddCategoryName: "Nombre de la categoría",

  productEditTitleNew: "Nuevo Producto",
  productEditTitleUpdate: "Actualizar Producto",
  productEditProductType: "Tipo de Producto (Viraly)",
  productEditProductCategory: "Categoría del Producto (Viraly)",
  dropshippingOrEcommerceType: "Tipo de producto",
  addIdentifiers: "Agregar identificadores",
  identifier: "Identificador",
  productEditTitleProductDesc: "Descripción del Producto",
  productEditTitleProductDescText: "Puedes crear diferentes descripciones del producto, por cada uno de los idiomas disponibles, a su vez puedes indicar cual de las versiones estara publica y cual no.",
  productEditControlsStatus: "Estado del Producto",
  productEditControlsStatusPublicText: "El producto será visible para los usuarios.",
  productEditMediaElementsTitle: "Elementos Multimedia",
  productEditMediaElementsByLang: "Por idioma",
  productEditMediaElementsForEveryone: "El mismo en todos los idiomas",
  productEditPreviewButtonText: "Previsualizar",
  productEditSaveAllButtonText: "Guardar Todo",
  productEditDescStatusLabel: "ESTADO",
  productEditDescChangeStatus: "Cambiar Estado",
  productEditDescChangeStatusDraft: "Borrador",
  productEditDescChangeStatusPublic: "Público",
  productEditDescProductName: "Nombre del Producto",
  productEditDescProductDesc: "Descripción del cuerpo del Producto",
  productEditMediaElementsTitleDnD: "Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos",
  productEditMediaElementsTitleDnDDrop: "Suelta los archivos aquí...",
  productEditMediaElementsUploadingError: "Error subiendo archivo",
  productEditMediaElementsUploadRejected: "Solo se permiten los siguientes tipos de archivos",
  productEditProductOrg: "Organización de Producto",
  productEditProductOrgType: "Tipo de Producto",
  productEditProductOrgTags: "Etiquetas del Producto",
  productEditProductOrgTagsPla: "Agrega nuevas etiquetas",
  productEditProductSEOTitle: "SEO del Producto",
  productEditProductSEOTitlePage: "Título de la Página",
  productEditProductSEOCharsUsed: "caracteres usados",
  productEditProductSEOMetaDesc: "Meta Descripción",

  productEditMediaElementsDeleteFileTitle: "Eliminar archivo",
  productEditMediaElementsDeleteFileDesc: "¿Está seguro? No puede deshacer esta acción después.",

  productEditTranferMediaTitle: "Transferir archivos",
  productEditTranferMediaText: "Se detecto que tienes elementos multimedia en alguna de las descripciones, los siguientes archivos seran transferidos.",
  productEditTranferMediaButtonText: "Transferir",

  productEditVariantsTitle: "Variantes del producto",
  productEditVariantsCheckbox: "Este producto tiene variantes",
  productEditVariantsOptionsTitle: "Opciones",
  productEditVariantsOptionsButtonAdd: "Agregar otra opción",
  productEditVariantsOptionsInfoMessage: "Cada valor de cada opción debe ir separado por coma",
  productEditVariantsOptionsAddName: "Nombre de la opción",
  productEditVariantsOptionsAddNamePlaceholder: "Color",
  productEditVariantsOptionsAddValues: "Valores de la opción",
  productEditVariantsOptionsAddValuesPlaceholder: "Rojo, Verde, Naranja, etc.",
  productEditVariantsListTitle: "Variantes",
  productEditVariantsListMessage: "Despues de guardar el producto podrás agregar imagen a cada variante.",

  productEditCopyTitle: "Copy / Elementos de Marketing",
  productEditCopyDescription: "Texto - Descripción",
  productEditCopyDescriptionPlaceholder: "Escribe el texto aquí, puedes usar emojis.",
  productEditCopyMediaTitle: "Miniaturas / Videos",

  productEditUnsavedChangesMessage: "Tienes cambios sin guardar, ¿quieres salir de la página?",

  productEditInfoModalButtonTextTitle: "Ayuda - Información",
  productEditInfoModalWarning: "Las descripciones con estado Blanco o que no contengan los campos: Título, Cuerpo - Descripción, Multimedia (En caso necesario) serán ignoradas y no se guardarán.",
  productEditInfoModalPrimaryText: "En este apartado encontrarás la información necesaria para crear un producto de manera adecuada. Al guardar un producto se deben cumplir ciertos parámetros indicados a continuación.",
  productEditInfoModalFirstTitle: "Producto General - Digital",
  productEditInfoModalSecondTitle: "Producto Google",
  productEditInfoModalThirdsTitle: "Producto Facebook",
  productEditInfoModalFirstText: "Todos los productos deben contener los siguientes campos llenos para poder guardar dicho producto",
  productEditInfoModalFirstItem1: "Tipo de producto, Categoría",
  productEditInfoModalFirstItem2: "Al menos una de las descripciones debe contener: Título, Cuerpo - Descripción, mínimo un archivo de multimedia",
  productEditInfoModalFirstItem3: "El caso de querer publicar el producto para el acceso de los clientes, una de las descripciones con las características mencionadas debe tener su estado en",
  productEditInfoModalFirstItem4: "En caso de que la multimedia sea única para todos los idiomas, debe contener un mínimo de un archivo multimedia",
  productEditInfoModalSecondText1: "Para guardar un producto de tipo Google en modo",
  productEditInfoModalSecondText2: "debe contener las características de un producto general, en caso de querer publicarlo se debe contar con algunas características adicionales.",
  productEditInfoModalTextOthersItems: "Todas las características de un producto general",
  productEditInfoModalSecondItem: "Los datos deben estar completos",
  productEditInfoModalThirdText1: "Para guardar un producto de tipo Facebook en modo",
  productEditInfoModalThirdItem: "Los datos deben estar completos",

  productSavingMessage: "Estamos guardando la información del producto ...",

  productEditTitle: "Editar Producto",
  productEditionModeLabel: "Modo Edición",
  productEditMetadataTitle: "Metadata del Producto",
  productEditMetadataCreatedAt: "Creado El",
  productEditMetadataUpdatedAt: "Última Actualización",
  productEditMetadataCreatedBy: "Creado Por",
  productEditMetadataUpdatedBy: "Última actualización por",
  productEditDescMetadataTitle: "Metadata de la Descripción",

  productEditNotFoundButton: "Ir a productos",

  productEditVariantsSelectImageTitle: "Actualizar imagen de variante",
  productEditVariantsSelectImageButtonSelect: "Seleccionar",
  productEditVariantsWarningText: "Al cambiar los valores de las opciones, se reconstruyen las variantes. Las imágenes agregadas serán borradas.",

  productDetailsCopyButtonText: "Copiar Texto",
  productDetailsCopiedButtonText: "Copiado",

  productListFilterButtonText: "Filtros",
  productListEmptyResults: "No se obtuvieron resultados, por favor intenta con otro tipo de filtros.",
  productListCardSeeDetails: "Ver Detalles",

  productDetailsLoadingText: "Estamos cargando el producto ...",
  productDetailsReportError: "reportar un problema con el producto",
  productDetailsButtonTextAddToMyStores: "Agregar a mi tienda",
  productDetailsButtonTextRemoveToMyStores: "Remover de mis tiendas",
  productDetailsCopyTitle: "Copy para Facebook",
  productDetailsCopyVideos: "Vídeos promocionales",
  productDetailsCopyThumbs: "Miniaturas",

  // Productos sugeridos
  suggestProduct: "Sugerir producto",
  suggestProductProductName: "Nombre del producto",
  suggestProductProductUrl: "URL del producto",
  suggestProductProductImages: "Imagenes del Producto",
  suggestProductProductNameIsRequired: "El nombre del producto es obligatorio",
  suggestProductProductUrlIsRequired: "La URL del producto es obligatoria",
  suggestProductProductUrlIsInvalid: "La URL del producto debe ser una URL válida",
  suggestProductProductImagesIsRequired: "Las imagenes del producto son obligatorias",
  suggestProductProductImagesMinOne: "Se requiere al menos una imagen del producto",
  suggestProductSuggestSendedSuccess: "Sugerencia enviada correctamente. ¡Muchas gracias por tu sugerencia!",
  suggestProductMustIncludeMinOne: "Debe incluir al menos una imagen del producto",
  suggestProductSuggestSendedError: "Hubo un error al enviar la sugerencia. Por favor, intenta de nuevo.",
  suggestProductClassifedBy: "Clasificado por",
  suggestProductMarkAsResolved: "Marcar como revisado",

  planSelecctionTypesTitle: "Seleccionar tipos",
  planSelecctionTypesFirstTextOneType: "Selecciona 1 tipo de producto a visualizar con tu plan.",
  planSelecctionTypesFirstTextTwoType: "Selecciona 2 tipos de productos a visualizar con tu plan.",
  planSelecctionTypesNote: "Una vez seleccionados y adquirido el plan no podrás cambiar tus opciones.",

  navlinkLogout: "Cerrar sesión",

  productListAdminTooltipTitle: "Estado de la descripción",
  productDetailsTitle: "Detalles del Producto",

  storesAddButtonText: "Agregar nueva tienda",
  stores: "Tiendas",
  seeTutorialButtonText: "Ver Tutorial",
  seeStore: "Ver tienda",
  storesAddShopifyButton: "Tienda de Shopify",
  storesAddShopifyTitle: "Agregar tienda de Shopify",
  storesAddWooButton: "Tienda de WooCommerce",
  storesAddWooTitle: "Agregar tienda de WooCommerce",
  storeAddShopifyValidationStoreNameMin: "El nombre de la tienda debe tener al menos 10 caracteres.",
  storeAddShopifyValidationStoreKeyMin: "La Key de la tienda debe tener al menos 20 caracteres.",
  storeAddShopifyValidationStoreSecretMin: "El Token de acceso de la API debe tener al menos 20 caracteres.",
  storeAddShopifyValidationStoreNameRequired: "El nombre de la tienda es requerido.",
  storeAddShopifyValidationStoreKeyRequired: "La Key de la tienda es requerida.",
  storeAddShopifyValidationStoreSecretRequired: "El Token de acceso de la API es requerido.",

  storeAddShopifyStoreNameLabel: "Nombre de la tienda",
  storeAddShopifyStoreKeyLabel: "Key de la tienda",
  storeAddShopifyStoreSecretLabel: "Token de acceso de la API",

  productCardNoAccessText: "No tienes acceso a la visualización de este producto. Si deseas tener acceso deber actualizar tu plan a uno de mayor nivel haciendo clic al botón de abajo.",

  storeDisableTitle: "Desactivar tienda",
  storeDisableMessageOne: "Estás a punto de desactivar la tienda: ",
  storeDisableButton: "Desactivar",
  storeListTitle: "Listado de tiendas",

  storeExportTitle: "Exportar Producto",
  storeUpdateProductExported: "Actualizar producto exportado",
  storeUpdateProductExportedAlertMessage: "Al actualizar este producto, perderás las modificaciones que hayas realizado en tu tienda.",
  storeExportFirstMessage: "Vas a exportar el producto:",
  storeExportSecondMessage: "por favor selecciona el idioma y la tienda donde sera exportado",
  storeExportButtonText: "Exportar",

  productListSelectLang: "Idioma Productos",
  productListSelectLangDefault: "Predeterminado",
  productListModalStoreAdded: "En tu tienda",

  productBlockModalTitle: "Accede a Nuevos Catálogos de Productos",
  productBlockModalSubTitle: "Actualiza tu suscripción para obtener acceso a todo un catálogo con actualizaciones constantes de productos adicionales.",

  menuItemMyProducts: "Mis Listas",
  productDetailAddToFolder: "Agregar a lista",
  productDetailRemoveFromFolder: "Eliminar de lista",
  productDetailAddNewListButton: "Agregar nueva lista",
  productDetailCreateListInput: "Nombre de la lista",

  myListsNoFolderSelected: "No se ha seleccionado ninguna carpeta a buscar",
  modalDeleteItemTitleFolder: "Eliminar lista",
  modalDeleteItemTitleProduct: "Remover Producto",

  libraryAdsTitle: "Buscar anuncios",
  libraryAdsSearchTypeTitle: "Tipo de búsqueda",
  libraryAdsSearchTypeUnordered: "Desordenado",
  libraryAdsSearchTypeExact: "Frase exacta",
  libraryAdsSearchTerms: "Términos de búsqueda (máximo 100 caracteres)",
  libraryAdsStatusTitle: "Estado",
  libraryAdsStatusAll: "Todos",
  libraryAdsStatusActive: "Activo",
  libraryAdsStatusInactive: "Inactivo",
  libraryAdsMediaTypeTitle: "Tipo de medio",
  libraryAdsMediaTypeNone: "Ninguno",
  libraryAdsMediaTypeImage: "Imagen",
  libraryAdsCountryTitle: "Buscar por países",
  libraryAdsPublisher: "Plataformas de editores",
  libraryAdsPublisherAudience: "Red de audiencia",
  libraryAdsLanguages: "Buscar por Idiomas",
  libraryAdsPagesIds: "ID de la página de Facebook (separados por comas)",
  libraryAdsStartDate: "Fecha de entrega máx. (DD/MM/YYYY)",
  libraryAdsEndDate: "Fecha de entrega mín. (DD/MM/YYYY)",

  menuItemDevices: "Solicitudes de Dispositivos",
  menuItemWithdrawals: "Solicitudes de Retiros",

  solveCaseModalTitle: "Resolver caso",
  solveCaseModalStatus: "Solución",
  solveCaseModalStatusPlaceholder: "Seleccione una solución",
  solveCaseModalObservation: "Observación",
  solveCaseModalReason: "Motivo",
  solveCaseModalVoucher: "Anexe documento de respaldo",
  uploadFile: 'Cargar archivo',
  fileSelected: 'No ha seleccionado archivo',
  solveCaseModalObservationPlaceholder: "Describa brevemente por qué se deniega la solicitud al usuario (255 caracteres como máximo)",

  solveCaseOptionAprove: "APROBAR",
  solveCaseOptionReject: "RECHAZAR",
  requestStatusAll: "Estado de la solicitud (Todos)",
  requestStatusRevision: "Revisión",
  requestStatusAllowed: "Permitido",
  requestStatusRejected: "Rechazado",
  
  statusAll: "Todos",
  statusPending: "Pendiente",
  statusDeny: "Denegado",
  statusApproved: "Aprobado",

  addAdminTitle: "Agregar Usuario Admin",
  clientEmailInputPlaceholder: "Correo del cliente",

  supplierListTitle: "Proveedores",
  supplierListNoteTitle: "Nota",
  supplierListNoteText: "Viraly no tiene contacto con ningún proveedor ni hace de intermediario, las sugerencias que se muestran en este apartado son basadas en los criterios seleccionados por el equipo.",
  supplierCardYears: "Años",
  supplierCardResponse: "Tiempo de respuesta",
  supplierCardTransactions: "Transacciones",
  supplierCardDelivery: "Tasa de entrega",
  supplierCardPrice: "Precio",

  adShowFilters: "Mostrar Filtros",
  adHideFilters: "Ocultar Filtros",
  adCardFirstSeen: "Visto por primera vez",
  adCardLastSeen: "Ultima vez visto",
  adCardComments: "Comentarios",
  adCardShares: "Compartidos",

  adsActionsSave: "Guardar búsqueda actual",
  adsModalInputPlaceholder: "Buscar nombre personalizado",
  adsActionsSearchSelect: "Seleccionar búsqueda",
  adsActionsFavorites: "Mostrar mis favoritos",

  adDetailsTitle: "Detalle del anuncio",
  adDetailsSeeProduct: "Ver Producto",
  adDetailsSeePost: "Ver Anuncio",
  adDetailsCopyTitle: "Copy del anuncio de Facebook",
  adDetailsEngageTitle: "Engagement",
  adDetailsAgeTitle: "Edad de la audiencia",
  adDetailsGenderTitle: "Género de la audiencia",
  adDetailsGenderMale: "masculino",
  adDetailsGenderFemale: "femenino",
  adDetailsCountryTitle: "Países de audiencia",
  adDetailsRunTitle: "Pasarela del anuncio",
  adDetailsDays: "días",

  adListFrom: "Desde",
  adListTo: "hasta",
  adListOrderTotalReactions: "Total de Reacciones",
  
  // Retiros
  createWithdrawalAccount: "Cuenta",
  createWithdrawalButton: "Retirar",
  createWithdrawalPlatform: "Plataforma",
  createWithdrawalSuccess: "Solicitud de retiro exitosa",
  createWithdrawalTitle: "Solicitar Retiro",
  createWithdrawalValue: "Valor",
  unordered: "Sin ordenar",
  voucherText: "Descargar vale",
  withdrawalOrderByAsc: "Ascendente",
  withdrawalOrderByAscUpdate: "Fecha antigua de actualizacion",
  withdrawalOrderByDesc: "Descendente",
  withdrawalOrderByDescUpdate: "Fecha reciente de actualizacion",

  // Referrals
  cancelled: "Cancelado",
  payOut: "Disponible",
  pending: "Pendiente",
  finalized: "Finalizado",
  referralFilter: "Filtrar",
  referralFilterEndDate: "Hasta la fecha",
  referralFilterStartDate: "Desde la fecha",
  referralFilterStatus: "Filtrar por estado",
  referralHeaderActiveUntil: "Activo hasta",
  referralHeaderBillAmount: "Monto",
  referralHeaderCommission: "Comisión",
  referralHeaderCreatedAt: "Fecha de creación",
  referralHeaderDate: "Fecha",
  referralHeaderName: "Nombre usuario",
  referralHeaderPayoutAt: "Fecha de pago",
  referralHeaderStatus: "Estado",
  referralCodeValiationMin: "El código de referido debe tener al menos de 3 caracteres",
  referralCodeValidationEmpty: "El código de referido es obligatorio",
  referralTotalAvailableBalance: "Total saldo disponible",
  referralRecordsFound: "registros encontrados",
  referralTotalPendingBalance: "Total saldo pendiente",
  modifyReferralCodeTitle: "Modificar código de Referido",

  // Balance
  referralBalanceTooltip: "Saldo disponible",
  referralTotalBalanceTooltip: "Saldo total",

  // Platform
  messageEmptyPlatform: "Debes seleccionar una plataforma",
  messageEmptyIdentifier: "Debes ingresar al menos un identificador",
  messagePlatformDuplicate: "Esta plataforma ya fue agregada anteriormente",
  platformNameField: "Nombre",
  platformSiteUrlField: "URL del sitio",
  platformImageUrlField: "URL de la imagen",
  productsFilterPlatformField: "Filtrar por Plataforma",

  // CTA Filter
  shopNow: "Comprar ahora",
  learnMore: "Más información",
  messagePage: "Página de mensajes",
  signUp: "Registrarse",
  getOffer: "Obtener oferta",
  watchMore: "Ver más",
  bookNow: "Reservar ahora",
  downloadUndefined: "Descarga indefinida",
  download: "Descargar",
  open: "Abrir",
  applyNow: "Aplicar ahora",
  likePagelike: "Me gusta como página",
  subscribeUndefined: "Suscribirse sin definir",
  whatsappMessage: "Mensaje de Whatsapp",
  getQuote: "Obtener cotización",
  eventRsvp: "Evento rsvp",
  donateNow: "Donar ahora",
  playGame: "Juega al juego",
  contactUs: "Contáctenos",
  getDirections: "Obtener direcciones",
  seeMenu: "Ver menú",
  listenNow: "Escuchar ahora",
  installApp: "Instalar app",
  buyTickets: "Comprar entradas",
  buyNow: "Comprar ahora",
  orderNow: "Pide ahora",
  getShowtime: "Obtener hora de presentación",
  callNow: "Llamar ahora",
  watchVideo: "Ver vídeo",
  openLink: "Abrir enlace",
  requestTime: "Solicitar hora",
  findYour: "Encuentra tu",
  useApp: "Usar app",
  likePage: "Me gusta página",

  // Terms and conditions
  termsTitle: "Términos y condiciones de uso",
  termsIntro: "Bienvenido a Viraly, el sitio web de ECOM BOOSTERS ubicado en los Estados Unidos. Al utilizar nuestra plataforma, aceptas los términos y condiciones establecidos a continuación.",
  termsValidity: "Fecha de vigencia: 8 de mayo del 2023",
  termsItemOne: "Recopilación de información del usuario: Viraly recopila información personal del usuario, incluyendo nombre, nombre de usuario, número de teléfono, correos electrónicos, número de tarjeta de débito/crédito y contraseña. Al utilizar nuestra plataforma, el usuario acepta la recopilación de esta información. La información recopilada se utiliza para mejorar la experiencia del usuario y para procesar pagos en línea. Nos comprometemos a proteger su privacidad y seguridad en línea.",
  termsItemTwo: "Viraly dará de baja las cuentas que se comprueben tienen más de 3 diferentes usuarios usando la misma cuenta, pues viraly esta creado para brindar servicio a este máximo de usuarios por cuenta. La violación de esté termino desencadenará en la cancelación de la cuenta de forma automática, sin derecho a devolución de dinero",
  termsItemThree: "Publicidad: Viraly no muestra anuncios en la plataforma.",
  termsItemFour: "Tecnologías de seguimiento: Utilizamos tecnologías, como Google Analytics, para recopilar información sobre su uso de nuestra plataforma. Esta información se utiliza para mejorar su experiencia de usuario y para fines analíticos internos. No utilizamos publicidad ni retargeting en nuestra plataforma. Además, utilizamos dos píxeles de Facebook para ayudarnos a mejorar la calidad de nuestros productos y servicios. También utilizamos almacenamiento local para guardar información de sus dispositivos.",
  termsItemFive: "Proveedores de redes sociales: Viraly no utiliza proveedores de redes sociales en la plataforma.",
  termsItemSix: "Contenido: Viraly genera contenido para los productos dropshipping y e-commerce, que puede ser utilizado por el usuario para publicarlo en sus respectivos sitios o tiendas y/o para utilizar esta información a su gusto.",
  termsItemSeven: "Restricciones de edad: Viraly no se ofrece a menores de 13 años.",
  termsItemEight: "Contacto: El usuario puede contactarse con Viraly al correo manager.viraly@gmail.com, al número de teléfono +57 3215330715 o visitando el sitio informativo https://viraly.ai/.",
  termsItemNine: "Pagos en línea: Viraly acepta pagos en línea a través de la pasarela de pago de Stripe. Al utilizar esta pasarela de pago, el usuario acepta los términos y condiciones de Stripe.",
  termsItemTen: "Pagos recurrentes: Viraly acepta pagos en línea a través de la pasarela de pago de Stripe. Los pagos recurrentes mensuales son aceptados en nuestra plataforma. Al utilizar nuestros servicios de pago en línea, acepta estar sujeto a los Términos y Condiciones de Stripe. Para obtener más información, visite su sitio web.",
  termsItemEleven: "Responsabilidad: Es importante tener en cuenta que Viraly no tiene contacto con ningún proveedor ni hace de intermediario. Las sugerencias que se muestran en este sitio web son basadas en los criterios seleccionados por el equipo de Viraly y no garantizan resultados específicos.",
  termsFinalNote: "Al utilizar nuestra plataforma, aceptas los términos y condiciones establecidos. Si tienes alguna pregunta o inquietud, no dudes en contactarnos.",

  // Frequent questions
  questionOne: "¿No quedó cargada correctamente mi tienda?",
  answerOne: 'Asegúrate de seguir el paso a paso correctamente que se explica en el video tutorial en la pestaña “mis tiendas”. Verifica que el nombre de tu tienda sea el correcto “ siempre será el nombre que tiene el dominio myshopify. También debes validar que al momento de copiar y pegar los valores no quede ningún espacio.',
  questionTwo: "¿No me deja importar mis productos a mi tienda shopify?",
  answerTwo: 'Una vez hayas registrado tu tienda correctamente siguiendo el paso a paso del video tutorial en la pestaña “mis tiendas” verifica que la importación quede exitosa, y dirígete a tu perfil shopify y pon el producto PÚBLICO para que pueda verse en tu página.',
  questionThree: "¿No puedo importar los productos porque no tengo shopify?",
  answerThree: "En este caso, nuestro algoritmo te permite copiar, pegar y descargar todo el contenido de una manera práctica hacia cualquier página que tengas, y solo podras hacerlo si no tienes registrada una tienda shopify.",
  questionFour: "¿No encuentro los contactos de servicio al cliente?",
  answerFour: "Puedes comunicarte con nosotros vía whatsapp al número +57 321 5330715 o al correo manager.viraly@gmail.com.",
  questionFive: "¿No puedo eliminar mi tienda o mis dispositivos?",
  answerFive: "Para eliminar cualquier dispositivo registrado debes dirigirte a la pestaña “dispositivos” y encontrarás cada uno de los mismos registrados, solamente debes darle click al icono y listo. En el caso de la tienda es el mismo procedimiento solo que te diriges a la seccion “mis tiendas” y das click en el mismo icono. Si este proceso no es efectivo lo que  debes hacer es solicitar ayuda al WhatsApp de atención al cliente. El ícono deberá tener la apariencia que se muestra a continuación",
  whyLeaveUs: "¿Cuál es el motivo por el que nos abandonas?",
  doesNotSatisfy: "No satisface tus necesidades.",
  fewProducts: "Muy pocos productos semanales.",
  doNotLikeTheContent: "El contenido que se sube no te gusta.",
  other: "Otro.",
  writeTheReason: "Escribe el motivo.",
  YouMustWriteValidation: "No has escrito nada.",
  findProduct: "Este producto se puede encontrar en plataformas de dropshipping locales Colombia.",
};

export default esTranslations;