import * as React from "react";

import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertStatus,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { Formik, Form, Field, FormikHelpers } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { PasswordInput } from "@components/PasswordInput/PasswordInput";
import { LoadingFormPage } from "@components/LoadingFormPage";

import useStepperForm from "@hooks/useStepperForm";

import {
  loginFormInitialValues,
  loginFormModel,
  loginFormSchema,
} from "@forms/login";

import { useAuth } from "@context/AuthContext";
import { loginUserAction } from "@actions/authActions";
import { PageMetadata } from "@components/PageMetadata";
import { useSetRecoilState } from "recoil";
import { userProductFoldersState } from "@store/users/atoms";
import { useMutateLoginUser } from "@hooks/useUsers";
import { formatErrorMessageQuery } from "@config/httpClient";

const { useState, useEffect, useRef } = React;

const toastId = "error-toast";

interface AlertState {
  type: AlertStatus;
  message: string;
}

export const LoginPage = () => {
  const { dispatch } = useAuth();
  const { t, i18n } = useTranslation();

  const timeoutRef = useRef<any>(null);
  const lang = i18n.language.substring(0, 2);

  const navigate = useNavigate();
  const toast = useToast();

  const { activeStep, isLastStep, handleBackStep, handleNextStep } =
    useStepperForm({ steps: ["emailStep", "passwordStep"] });

  const [hasAlert, setHasAlert] = useState<null | AlertState>(null);
  const [searchParams] = useSearchParams();

  const setUserProductFoldersState = useSetRecoilState(userProductFoldersState);

  const currentSchema = loginFormSchema(
    [t("emailValidation"), t("emailValidationEmpty")],
    [t("passValidation")]
  )[activeStep];

  const { mutate, isLoading } = useMutateLoginUser();

  const handleLoginUser = (data: any) => {
    const deviceId = localStorage.getItem("deviceId") || "";

    const mutateParams = {
      deviceId,
      email: data.email,
      lang,
      password: data.password,
    };

    mutate(mutateParams, {
      onSuccess: (data: any) => {
        if (!data?.continue) {
          const errorMsg = data?.messages[lang];

          setHasAlert({
            type: "error",
            message: errorMsg,
          });

          if (data?.redirectNewDevice) {
            timeoutRef.current = setTimeout(() => {
              navigate(`/register-device/${data?.userId},${mutateParams.email}`);
            }, 3000);
          }

          return;
        }

        setHasAlert({
          type: "success",
          message: t("successUserRegister"),
        });

        const dataResponse = data?.data;

        const userToken = {
          "@token": dataResponse?.userToken || "",
          "@user_email": dataResponse?.userData?.email || "",
        };

        localStorage.setItem("__token__", JSON.stringify(userToken));
        localStorage.setItem("__authtype__", "password");
        // localStorage.setItem("LoadRandomPage", "false");

        setUserProductFoldersState(dataResponse?.userData.folders || []);
        dispatch(loginUserAction(dataResponse?.userData, "password"));
      },
      onError: (error: any) => {
        const message = formatErrorMessageQuery(error, lang);

        setHasAlert({ type: "error", message });
      },
    });
  };

  const onSubmitForm = (data: any, helpers: FormikHelpers<any>) => {
    handleNextStep({
      values: data,
      actions: helpers,
      submitForm: handleLoginUser,
    });
  };

  const handleCleanAlert = () => setHasAlert(null);

  useEffect(() => {
    if (searchParams.get("error")) {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: "Error",
          description: decodeURI(searchParams.get("error") || ""),
          status: "error",
          duration: 9000,
          isClosable: true,
          variant: "top-accent",
          position: "top-right",
        });
      }
    }

    if (searchParams.get("newDevice")) {
      timeoutRef.current = setTimeout(() => {
        navigate(`/register-device/${searchParams.get("newDevice")}`);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [toast, searchParams, navigate]);

  return (
    <Box mt={4}>
      <PageMetadata title={t("loginTitle")} />
      <Text mb={2} as="h2" fontSize="3xl" fontWeight="bold">
        {t("loginTitle")}
      </Text>
      <Box>
        <Formik
          initialValues={loginFormInitialValues}
          validationSchema={currentSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, setFieldValue }) => (
            <Form id={loginFormModel.formId}>
              {isLoading && <LoadingFormPage />}

              {_renderStepContent(activeStep, values, setFieldValue)}

              <Collapse in={!!hasAlert} animateOpacity>
                <Alert
                  status={hasAlert?.type || "info"}
                  borderRadius={4}
                  mt={2}
                >
                  <AlertIcon />
                  <AlertDescription fontSize="sm" lineHeight={1.2}>
                    {hasAlert?.message}
                  </AlertDescription>
                  <Icon
                    as={AiOutlineClose}
                    position="absolute"
                    right="8px"
                    top="8px"
                    cursor="pointer"
                    onClick={handleCleanAlert}
                  />
                </Alert>
              </Collapse>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={4}
              >
                {activeStep !== 0 && (
                  <Button
                    variant="outline"
                    onClick={handleBackStep}
                    size="md"
                    mr={4}
                    disabled={isLoading}
                  >
                    {t("backButtonText")}
                  </Button>
                )}

                <Button
                  size="md"
                  type="submit"
                  colorScheme="brand"
                  isFullWidth={!isLastStep}
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  {isLastStep ? t("loginButtonText") : t("continueButtonText")}
                </Button>
              </Box>

              {/* <SeparatorWithText my={[8, 4]} text="O" />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                flexDirection="column"
              >
                <Button
                  mb={[4, 2]}
                  leftIcon={<FcGoogle />}
                  variant="outline"
                  onClick={() => signInWithSocial("google")}
                  isFullWidth
                >
                  {t("signinGoogle")}
                </Button>
              </Box> */}

              <Text mb={1} mt={6} align="center">
                {t("noAccountText")}
                <Link
                  as={RouterLink}
                  to="/register?registerType=viraly"
                  ml={1}
                  color="blue.300"
                >
                  {t("noAccountLink")}
                </Link>
              </Text>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

type SetFieldValue = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined
) => void;

const _renderStepContent = (
  step: number,
  values: any,
  setFieldValue: SetFieldValue
) => {
  switch (step) {
    case 0:
      return <EmailForm values={values} setFieldValue={setFieldValue} />;
    case 1:
      return <PasswordForm values={values} />;
    default:
      return <div>Step not found</div>;
  }
};

interface EmailFormProps {
  values: { [x: string]: string };
  setFieldValue: SetFieldValue;
}

const EmailForm = ({ values, setFieldValue }: EmailFormProps) => {
  const { t } = useTranslation();

  const [rememberUser, setRememberUser] = useState(false);

  const handleSaveUserEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      localStorage.setItem("user_email", values.email);
    } else {
      localStorage.removeItem("user_email");
    }

    setRememberUser((prevState) => !prevState);
  };

  useEffect(() => {
    const isSavedUserEmail = localStorage.getItem("user_email");

    if (isSavedUserEmail) {
      setRememberUser(true);
      setFieldValue("email", isSavedUserEmail, true);
    }
  }, [setFieldValue]);

  return (
    <>
      <Field name="email">
        {({ field, form }: any) => (
          <FormControl
            isInvalid={form.errors.email && form.touched.email}
            mb={2}
          >
            <FormLabel htmlFor="email">{t("emailField")}</FormLabel>
            <Input {...field} id="email" placeholder={t("emailField")} />
            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
          </FormControl>
        )}
      </Field>

      <Checkbox
        isDisabled={values.email === ""}
        onChange={handleSaveUserEmail}
        isChecked={rememberUser}
        mt={2}
        colorScheme="brand"
      >
        {t("rememberUser")}
      </Checkbox>
    </>
  );
};

const PasswordForm = (values: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Box mb={1}>
        <Text fontSize="sm" fontWeight="bold">
          {t("emailField")}:
        </Text>
        <Text fontSize="sm" fontWeight="light">
          {values?.values.email}
        </Text>
      </Box>

      <Field name="password">
        {({ field, form }: any) => (
          <FormControl
            isInvalid={form.errors.password && form.touched.password}
          >
            <FormLabel htmlFor="password">{t("passField")}</FormLabel>
            <PasswordInput
              {...field}
              id="password"
              placeholder={t("passField")}
            />
            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
          </FormControl>
        )}
      </Field>

      <Text my={2} align="right">
        <Link as={RouterLink} to="/pass-recovery" color="blue.300">
          {t("forgotPassText")}
        </Link>
      </Text>
    </>
  );
};
