import {
  BoxProps,
  Flex,
  Icon,
  IconButton,
  useColorModeValue,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';

import { FiMenu } from 'react-icons/fi';
import { Header } from '@components/Header';

interface Props extends BoxProps {
  onOpen: () => void;
  handleCollapseMenu: () => void;
  isCollapsedMenu: boolean;
}

export const SidebarMobile = ({
  onOpen,
  handleCollapseMenu,
  isCollapsedMenu,
  ...rest
}: Props) => {
  const MenuInconHandle = useBreakpointValue({
    base: onOpen,
    md: handleCollapseMenu,
  });

  const mlHeader = isCollapsedMenu ? '95px' : '250px';
  const bgSideBar = useColorModeValue('white', 'gray.700');

  return (
    <Box {...rest} px={{ base: 4, md: 8 }} height="70px" pt={2.5}>
      <Flex
        alignItems="center"
        bg={bgSideBar}
        borderRadius={6}
        height="100%"
        justifyContent={{ base: 'space-between', md: 'flex-end' }}
        ml={{ base: 0, md: mlHeader }}
        px={{ base: 4, md: 2 }}
        transition="margin .3s ease"
      >
        <IconButton
          display="flex"
          onClick={MenuInconHandle}
          variant="ghost"
          aria-label="open menu"
          icon={<Icon as={FiMenu} />}
        />
        <Header />
      </Flex>
    </Box>
  );
};
