import * as React from 'react';
import { FormikHelpers } from 'formik';

interface Props {
  steps: string[];
};

export interface HanldeSubmitProps {
  values: any;
  actions?: FormikHelpers<any>;
  sendFormInmediatly?: boolean;
  submitForm?: (values: any, actions?: FormikHelpers<any>) => void;
};

const { useState, useCallback } = React;

const useStepperForm = ({ steps }: Props) => {

  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep === steps.length - 1;

  const _handleChangeStep = useCallback((step: number) => setActiveStep(step), [setActiveStep]);

  const _handleSubmit = ({ values, actions, sendFormInmediatly, submitForm }: HanldeSubmitProps) => {
    if (isLastStep || sendFormInmediatly) {
      if (submitForm) submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions?.setTouched({});
      actions?.setSubmitting(false);
    }
  }

  const _handleBack = () => setActiveStep(activeStep - 1);

  return {
    activeStep,
    isLastStep,
    
    handleBackStep: _handleBack,
    handleNextStep: _handleSubmit,
    handleChangeActiveStep: _handleChangeStep
  };

};

export default useStepperForm;