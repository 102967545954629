import { FetchRest } from "@config/httpClient";

interface DeleteProductInFolderServiceProps {
  userId: string;
  folderId: string;
  productId: string;
  lang: string;
};

export const deleteProductInFolderService = async (props: DeleteProductInFolderServiceProps) => {
  const { userId, folderId, productId, lang } = props;

  const params = `userId=${userId}&folderId=${folderId}`;
  const uriAddFolder = `/products/delete_product_in_folder/${productId}?${params}`;

  const response = await FetchRest<{}>({
    uri: uriAddFolder,
    requireAuth: true,
    method: 'DELETE'
  });

  if (response.error) {
    const message = response.messages[lang];
    throw { message, status: response.status };
  }

  return response.data;
};