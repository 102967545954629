import { ChakraProvider } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AuthProvider } from '@context/AuthContext';
import { StripeContext } from '@context/StripeContext';
import { Navigation } from './routers/Navigation';

import theme from './theme';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-253995817-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const queryClient = new QueryClient();

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <AuthProvider>
        <StripeContext>
          <ChakraProvider theme={theme}>
            <Navigation />
          </ChakraProvider>
        </StripeContext>
      </AuthProvider>
    </RecoilRoot>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
