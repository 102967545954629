import httpClient from "@config/httpClient";
import handleHttpClientErrors from "@utils/handleHttpClientErrors";
import { getUserTokenAndHeader } from "@context/AuthContext";

interface RegisterUserProps {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirm: string;
  countryCode: string;
  phoneNumber: number;
};

const registerUser = async (userData: RegisterUserProps) => {

  try {

    const token = await getUserTokenAndHeader();

    const response = await httpClient('/users/register', {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${token}` },
      data: userData
    });

    const data = response.data;

    return {
      error: false,
      data
    };

  } catch (error: any) {
    const { messages } = handleHttpClientErrors(error);
    return {
      error: true,
      messages
    };
  }

};

export default registerUser;