import { addAndDeleteProductInFolderService } from '@services/products/addAndDeleteProductInFolder';
import { addProductToFolderService } from '@services/products/addProductToFolder';
import { createFolderService } from '@services/products/createFolder';
import { createOrUpdateProduct } from '@services/products/createOrUpdateProduct';
import { deleteProductFromStoreService } from '@services/products/deleteProductFromStore';
import { deleteProductInFolderService } from '@services/products/deleteProductInFolder';
import { deleteProductsFolderService } from '@services/products/deleteProductsFolder';
import { exportProductToStoreService } from '@services/products/exportProductToStore';
import { getFoldersService } from '@services/products/getFolders';
import { getProductService } from '@services/products/getProduct';
import { getProductMetadata } from '@services/products/getProductMetadata';
import { getAllProducts, ProductDataFilters } from '@services/products/getProducts';
import { updatedExportedProductToStore } from '@services/products/updatedExportedProductToStore';
import { useMutation, useQuery } from 'react-query';

const mutationKey = 'product';
const mutationExportKey = 'export_product';
const mutationUpdateExportedProductKey = 'update_exported_product';
const queryProductMetadataKey = 'product_metadata';
const queryProductKey = 'product_details';
const queryAllProductKey = 'products';

const queryGetFoldersKey = 'folders';
const mutationCreateFolderKey = 'add_folder';
const mutationAddToFolderKey = 'product_folder';
const mutationAddAndDeleteInFolderKey = 'add_delete_product_folder';
const mutationDeleteFolderKey = 'delete_folder';
const mutationDeleteProductInFolderKey = 'delete_product_folder';
const mutationDeleteProductInFolderStoreKey = 'delete_product_folder_store';

interface UseQueryProductMetadataProps {
  userId: string;
  productId: string;
  lang: string;
};

interface UseQueryProductProps {
  userId: string;
  productId: string;
  lang: string;
  isEnabled: boolean;
  staleTime?: number;
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
};

interface UseQueryAllProductsProps {
  userId: string;
  lang: string;
  page: number;
  limit?: number;
  filters?: ProductDataFilters
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
};

interface UseQueryGetFoldersProps {
  userId: string;
  lang: string;
};

export const useMutateProduct = () => {
  return useMutation(createOrUpdateProduct, {
    mutationKey: [mutationKey],
    retry: false
  });
};

export const useMutateExportProduct = () => {
  return useMutation(exportProductToStoreService, {
    mutationKey: [mutationExportKey],
    retry: false,
  });
};

export const useMutateUpdateExportedProduct = () => {
  return useMutation(updatedExportedProductToStore, {
    mutationKey: [mutationUpdateExportedProductKey],
    retry: false,
  });
};

export const useQueryProductMetadata = (props: UseQueryProductMetadataProps) => {
  return useQuery({
    queryKey: [queryProductMetadataKey, { productId: props.productId }],
    queryFn: () => getProductMetadata(props),
    staleTime: Infinity
  });
};

export const useQueryProduct = (props: UseQueryProductProps) => {
  const { onSuccess, onError, staleTime, isEnabled, lang, ...QueryVars } = props;

  return useQuery({
    queryKey: [queryProductKey, QueryVars],
    queryFn: () => getProductService({ lang, ...QueryVars }),
    staleTime: staleTime ? staleTime : Infinity,
    enabled: isEnabled,
    onSuccess: (data) => onSuccess ? onSuccess(data) : null,
    onError
  });
};

export const useQueryAllProducts = (props: UseQueryAllProductsProps) => {
  const { onSuccess, onError, ...QueryVars } = props;
  const { lang, ...rest } = QueryVars;
  const { userId, page, filters, limit } = QueryVars;
  
  return useQuery({
    queryKey: [queryAllProductKey, rest],
    queryFn: () => getAllProducts(userId, lang, page, limit, filters),
    staleTime: 60000 * 10,
    onSuccess: (data) => onSuccess ? onSuccess(data) : {},
    onError
  });
}

export const useQueryGetFolders = (props: UseQueryGetFoldersProps) => {
  const { userId, lang } = props;

  return useQuery({
    queryKey: [queryGetFoldersKey, { userId }],
    queryFn: () => getFoldersService({ userId, lang }),
    staleTime: Infinity
  });
};

export const useMutateNewFolder = () => {
  return useMutation(createFolderService, {
    mutationKey: [mutationCreateFolderKey],
    retry: false,
  });
};

export const useMutateAddProductToFolder = () => {
  return useMutation(addProductToFolderService, {
    mutationKey: [mutationAddToFolderKey],
    retry: false,
  });
};

export const useMutateAddAndDeleteProductInFolder = () => {
  return useMutation(addAndDeleteProductInFolderService, {
    mutationKey: [mutationAddAndDeleteInFolderKey],
    retry: false
  });
};

export const useMutateDeleteFolder = () => {
  return useMutation(deleteProductsFolderService, {
    mutationKey: [mutationDeleteFolderKey],
    retry: false
  });
};

export const useMutateDeleteProductInFolder = () => {
  return useMutation(deleteProductInFolderService, {
    mutationKey: [mutationDeleteProductInFolderKey],
    retry: false
  });
};

export const useMutateDeleteProductFromStoreFolder = () => {
  return useMutation(deleteProductFromStoreService, {
    mutationKey: [mutationDeleteProductInFolderStoreKey],
    retry: false
  });
};