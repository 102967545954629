import { FetchRest } from '@config/httpClient';

export interface AddAdminUserServiceProps {
  userId: string;
  lang: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirm: string;
};

export const addAdminUserService = async (props: AddAdminUserServiceProps) => {

  const { userId, lang, ...userData } = props;

  let uri = `users/register_admin`;

  const response = await FetchRest({
    requireAuth: true,
    uri,
    method: 'POST',
    body: { userId, ...userData }
  });

  if (response.error) {
    const message = response.messages[lang];
    throw new Error(message);
  }

  const data = response.data;
  return data;

};