import { FetchRest } from '@config/httpClient';

interface UpdateReferralCodeProps {
  adminUserId: string;
  userId: string;
  referralCode: string;
  lang: string;
};

export const updateReferralCodeService = async (props: UpdateReferralCodeProps) => {

  const { userId, adminUserId, referralCode, lang } = props;

  const uri = `/referrals/${userId}`;

  const response = await FetchRest({
    requireAuth: true,
    uri,
    method: 'PATCH',
    body: { modifiedBy: adminUserId, referralCode }
  });

  if (response.error) {
    const message = response.messages[lang];
    throw new Error(message);
  }

  const data = response.data;
  return data;
};