import { FetchRest } from '@config/httpClient';

interface BlockUserServiceProps {
  adminUserId: string;
  userId: string;
  lang: string;
};

export const blockUserService = async (props: BlockUserServiceProps) => {

  const { userId, adminUserId, lang } = props;

  const uri = `/users/block_user`;

  const response = await FetchRest({
    requireAuth: true,
    uri,
    method: 'PATCH',
    body: { userId, adminUserId }
  });

  if (response.error) {
    const message = response.messages[lang];
    throw new Error(message);
  }

  const data = response.data;
  return data;

};