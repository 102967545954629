import { FetchRest, FetchRestEBS } from "@config/httpClient";
import { DescriptionByLangObjectToSend, ProductEditionStateInterface, ProductSaveData } from "@interfaces/ProductEditionInterface";

interface ProductDataProps {
  userId: string;
  productId: string;
  payload: ProductSaveData;
  lang: string;
  actionType?: string;
  type: string;
  platformsDropshipping: 
  { 
    id: string;
    platformName: string;
    productIdentifier: string;
    sku: string;
  }[];
};

const EBS_URI = 'https://suppliers.viraly.ai/api/v1/';
const EBS_ENDPOINT = 'suppliers/create_supplier';

export const createOrUpdateProduct = async (productData: ProductDataProps) => {
  const { userId, productId, payload, actionType, type, lang, platformsDropshipping } = productData;

  const platformsFormatted = platformsDropshipping.map((item: any) => ({
    platformId: item.id,
    identifier: item.productIdentifier,
    sku: item.sku,    
  }));
  
  const responseFile = await FetchRest({
    uri: '/products/upload_product_payload',
    method: 'POST',
    requireAuth: true,
    body: { userId, productId }
  });

  if (responseFile.error) {
    const message = responseFile.messages[lang];
    throw new Error(message);
  }

  const { url, key } = responseFile.data?.data;  

  const requestsOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'x-amz-acl': 'bucket-owner-full-control',
    },
    body: JSON.stringify(payload),
  };

  const responseFileUpload = await fetch(url, requestsOptions);

  if (!responseFileUpload.ok) throw new Error('Error uploading file');
  
  let body = { 
      userId, 
      productId, 
      s3Key: key, 
      actionType: actionType ?? 'new', 
      type, 
      platforms: platformsFormatted || []
  }
  
  const responseCreate = await FetchRest({
    uri: '/products/create_or_update_product',
    method: 'POST',
    requireAuth: true,
    body: body
  });
  
  if (responseCreate.error) {
    const message = responseCreate.messages[lang];
    throw new Error(message);
  }

  const suppliersMap = payload.suppliers.map(supp => supp.value);
  const filteredSuppliers = suppliersMap.filter(supp => supp !== '');

  if (filteredSuppliers.length > 0) {
    const responseAddSuppliers = await FetchRestEBS({
      uri: `${EBS_URI}${EBS_ENDPOINT}`,
      method: 'POST',
      requireAuth: true,
      body: { userId, productId, pages: filteredSuppliers }
    });

    if (responseAddSuppliers.error) {
      const message = responseAddSuppliers.messages[lang];
      throw new Error(message);
    }
  }

  return responseCreate.data;

};

export const tranformProductToSend = (state: ProductEditionStateInterface) => {

  const payloadData: ProductSaveData = {} as any;

  payloadData.productType = state.productType ?? 'digital';
  payloadData.category = state.category ?? { label: '', value: '' };
  payloadData.status = state.status;
  payloadData.multimediaElementsOption = state.multimediaElementsOption;
  payloadData.suppliers = state.suppliers;

  if (state.multimediaElementsOption === 'only-one' && state.uniqueMultimediaElements.length > 0)
    payloadData.uniqueMultimediaElements = state.uniqueMultimediaElements;

  const descriptionsUpdated = [];

  for (const key of Object.keys(state.descriptions)) {
    const desc = state.descriptions[key];
    const hasTheBasics = desc.title && desc.body && desc.status !== 'blank';

    if (desc.hasBeenUpdate) {

      if (hasTheBasics) {
        if (state.multimediaElementsOption === 'by-lang' && desc.multimediaElements.length === 0)
          continue;
      } else continue;

      const newDescToAdd: DescriptionByLangObjectToSend = {} as any;

      newDescToAdd.language = key;
      newDescToAdd.title = desc.title;
      newDescToAdd.body = desc.body as string;
      newDescToAdd.status = desc.status;

      if (state.multimediaElementsOption === 'by-lang')
        newDescToAdd.multimediaElements = desc.multimediaElements;

      if (desc.productType)
        newDescToAdd.customType = desc.productType;

      if (desc.productTags.length > 0)
        newDescToAdd.tags = (desc.productTags.map(tag => ({ id: tag.id, label: tag.label }))) as any;

      if (desc.seoTitle)
        newDescToAdd.titleSeo = desc.seoTitle;

      if (desc.seoDescription && state.productType === 'google')
        newDescToAdd.descriptionSeo = desc.seoDescription;

      if (desc.productCopy && state.productType === 'facebook') {
        const copyData = {} as any;

        if (desc.productCopy.text)
          copyData.text = desc.productCopy.text.map(copy => copy.copyText);

        if (desc.productCopy.mediaElements && desc.productCopy.mediaElements.length > 0)
          copyData.thumbnails = desc.productCopy.mediaElements;

        if (Object.keys(copyData).length > 0)
          newDescToAdd.copyData = copyData

      }

      const variantsToAdd = {} as any;

      if (desc.ProductVariants.options.length > 0)
        if (desc.ProductVariants.options.filter(o => o.values !== '').length > 0) {
          variantsToAdd.options = desc.ProductVariants.options;
          variantsToAdd.variantsValue = desc.ProductVariants.variants.map(v => ({
            id: v.id,
            values: v.values.join(','),
            imageSrc: v.image?.src,
            imageUniqueId: v.image?.uniqueId,
          }));
        }

      if (Object.keys(variantsToAdd).length > 0)
        newDescToAdd.variants = variantsToAdd;

      descriptionsUpdated.push(newDescToAdd);
    }
  }

  payloadData.descriptions = descriptionsUpdated;
  payloadData.dropshippingPlatforms = state.dropshippingPlatforms || [];

  return payloadData;

};