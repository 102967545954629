import * as React from "react";
import { Field } from "formik";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { registerFormModel } from "@forms/register";

const { useEffect, useState } = React;

interface Props {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const ReferralCodeField = ({ setFieldValue }: Props) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [hasCode, setHasCode] = useState(false);

  useEffect(() => {
    if (searchParams.get("referralCode")) {
      setFieldValue("referralCode", searchParams.get("referralCode"));
      setHasCode(true);
    }
  }, [searchParams, setFieldValue]);

  return (
    <Field name="referralCode">
      {({ field, form }: any) => (
        <FormControl
          isInvalid={form.errors.referralCode && form.touched.referralCode}
          display={hasCode ? "none" : ""}
        >
          <FormLabel htmlFor="referralCode">
            {t(registerFormModel.formField.referralCode.label)}
          </FormLabel>
          <Input
            {...field}
            isReadOnly={hasCode}
            id="referralCode"
            placeholder={t(registerFormModel.formField.referralCode.label)}
          />
          <FormErrorMessage>{form.errors.referralCode}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
