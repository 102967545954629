import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Select,
  Text
} from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import ReactFlagsSelect from "react-flags-select";

import { registerFormModel } from "@forms/register";
import { PasswordInput } from "@components/PasswordInput/PasswordInput";
import { ReferralCodeField } from "./ReferralCodeField";

import { useState } from "react";
interface Props {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const UserEmailInfoForm = ({ setFieldValue }: Props) => {
  const { t } = useTranslation();

  const countries = {
    AF: "AF",
    AL: "AL",
    DZ: "DZ",
    AS: "AS",
    AD: "AD",
    AO: "AO",
    AI: "AI",
    AG: "AG",
    AR: "AR",
    AM: "AM",
    AW: "AW",
    AU: "AU",
    AT: "AT",
    AZ: "AZ",
    BS: "BS",
    BH: "BH",
    BD: "BD",
    BB: "BB",
    BY: "BY",
    BE: "BE",
    BZ: "BZ",
    BJ: "BJ",
    BM: "BM",
    BT: "BT",
    BO: "BO",
    BA: "BA",
    BW: "BW",
    BR: "BR",
    IO: "IO",
    BG: "BG",
    BF: "BF",
    BI: "BI",
    KH: "KH",
    CM: "CM",
    CA: "CA",
    CV: "CV",
    KY: "KY",
    CF: "CF",
    TD: "TD",
    CL: "CL",
    CN: "CN",
    CO: "CO",
    KM: "KM",
    CG: "CG",
    CD: "CD",
    CK: "CK",
    CR: "CR",
    CI: "CI",
    HR: "HR",
    CU: "CU",
    CW: "CW",
    CY: "CY",
    CZ: "CZ",
    DK: "DK",
    DJ: "DJ",
    DM: "DM",
    DO: "DO",
    EC: "EC",
    EG: "EG",
    SV: "SV",
    GQ: "GQ",
    ER: "ER",
    EE: "EE",
    ET: "ET",
    FK: "FK",
    FO: "FO",
    FJ: "FJ",
    FI: "FI",
    FR: "FR",
    PF: "PF",
    GA: "GA",
    GM: "GM",
    GE: "GE",
    DE: "DE",
    GH: "GH",
    GI: "GI",
    GR: "GR",
    GL: "GL",
    GD: "GD",
    GU: "GU",
    GT: "GT",
    GG: "GG",
    GN: "GN",
    GW: "GW",
    HT: "HT",
    HN: "HN",
    HK: "HK",
    HU: "HU",
    IS: "IS",
    IN: "IN",
    ID: "ID",
    IR: "IR",
    IQ: "IQ",
    IE: "IE",
    IM: "IM",
    IL: "IL",
    IT: "IT",
    JM: "JM",
    JP: "JP",
    JE: "JE",
    JO: "JO",
    KZ: "KZ",
    KE: "KE",
    KI: "KI",
    KP: "KP",
    KR: "KR",
    KW: "KW",
    KG: "KG",
    LA: "LA",
    LV: "LV",
    LB: "LB",
    LS: "LS",
    LR: "LR",
    LY: "LY",
    LI: "LI",
    LT: "LT",
    LU: "LU",
    MO: "MO",
    MK: "MK",
    MG: "MG",
    MW: "MW",
    MY: "MY",
    MV: "MV",
    ML: "ML",
    MT: "MT",
    MH: "MH",
    MQ: "MQ",
    MR: "MR",
    MU: "MU",
    MX: "MX",
    FM: "FM",
    MD: "MD",
    MC: "MC",
    MN: "MN",
    ME: "ME",
    MS: "MS",
    MA: "MA",
    MZ: "MZ",
    MM: "MM",
    NA: "NA",
    NR: "NR",
    NP: "NP",
    NL: "NL",
    NZ: "NZ",
    NI: "NI",
    NE: "NE",
    NG: "NG",
    NU: "NU",
    NF: "NF",
    MP: "MP",
    NO: "NO",
    OM: "OM",
    PK: "PK",
    PW: "PW",
    PS: "PS",
    PA: "PA",
    PG: "PG",
    PY: "PY",
    PE: "PE",
    PH: "PH",
    PN: "PN",
    PL: "PL",
    PT: "PT",
    PR: "PR",
    QA: "QA",
    RO: "RO",
    RU: "RU",
    RW: "RW",
    KN: "KN",
    LC: "LC",
    WS: "WS",
    SM: "SM",
    ST: "ST",
    SA: "SA",
    SN: "SN",
    RS: "RS",
    SC: "SC",
    SL: "SL",
    SG: "SG",
    SX: "SX",
    SK: "SK",
    SI: "SI",
    SB: "SB",
    SO: "SO",
    ZA: "ZA",
    SS: "SS",
    ES: "ES",
    LK: "LK",
    SD: "SD",
    SR: "SR",
    SZ: "SZ",
    SE: "SE",
    CH: "CH",
    SY: "SY",
    TW: "TW",
    TJ: "TJ",
    TZ: "TZ",
    TH: "TH",
    TG: "TG",
    TK: "TK",
    TO: "TO",
    TT: "TT",
    TN: "TN",
    TR: "TR",
    TM: "TM",
    TC: "TC",
    TV: "TV",
    UG: "UG",
    UA: "UA",
    AE: "AE",
    GB: "GB",
    US: "US",
    UY: "UY",
    UZ: "UZ",
    VU: "VU",
    VE: "VE",
    VN: "VN",
    VI: "VI",
    YE: "YE",
    ZM: "ZM",
    ZW: "ZW",
  };
  const [isInit, setIsnit] = useState(false);
  const [selected, setSelected] = useState("CO");

  if (!isInit) {
    setCountry("CO", "countryCode");
    setIsnit(true);
  }

  function setCountry(country: string, field: any): void {
    setSelected(country);
    setFieldValue("countryCode", selected);
  }

  return (
    <>
      <Field name="email">
        {({ field, form }: any) => (
          <FormControl
            isInvalid={form.errors.email && form.touched.email}
            mb={2}
          >
            <FormLabel htmlFor="email">
              {t(registerFormModel.formField.email.label) + "*"}
            </FormLabel>
            <Input
              {...field}
              id="email"
              placeholder={t(registerFormModel.formField.email.label)}
            />
            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="password">
        {({ field, form }: any) => (
          <FormControl
            isInvalid={form.errors.password && form.touched.password}
            mb={2}
          >
            <FormLabel htmlFor="password">
              {t(registerFormModel.formField.password.label) + "*"}
            </FormLabel>
            <PasswordInput
              {...field}
              id="password"
              placeholder={t(registerFormModel.formField.password.label)}
            />
            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <Field name="confirm">
        {({ field, form }: any) => (
          <FormControl
            isInvalid={form.errors.confirm && form.touched.confirm}
            mb={2}
          >
            <FormLabel htmlFor="confirm">
              {t(registerFormModel.formField.confirm.label) + "*"}
            </FormLabel>
            <PasswordInput
              {...field}
              id="confirm"
              placeholder={t(registerFormModel.formField.confirm.label)}
            />
            <FormErrorMessage>{form.errors.confirm}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <FormLabel htmlFor="phoneNumber">
        {t(registerFormModel.formField.phoneNumber.label) + "*"}
      </FormLabel>

      <InputGroup size="md" p={0}>
        <InputLeftAddon w="40%" p={0}>
          <Field name="countryCode">
            {({ field, form }: any) => (
              <ReactFlagsSelect
                searchPlaceholder={"País"}
                customLabels={countries}
                searchable={true}
                selected={selected}
                selectedSize={14}
                optionsSize={14}
                onSelect={(code) => setCountry(code, field)}
                variant="filled"
                id="countryCode"
                name="countryCode"
                value={selected}
                {...field}
              />
            )}
          </Field>
        </InputLeftAddon>
        <Field name="phoneNumber">
          {({ field, form }: any) => (
            <FormControl
              isInvalid={form.errors.phoneNumber && form.touched.phoneNumber}
              mb={2}
            >
              <Input
                {...field}
                id="phoneNumber"
                type="number"
                placeholder={t(registerFormModel.formField.phoneNumber.label)}
              />

              <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
      </InputGroup>
      <Text fontSize='xs' textAlign='justify' mb={2}>{t('selectCountryCode')}</Text>
      <ReferralCodeField setFieldValue={setFieldValue} />
    </>
  );
};
