import httpClient from "@config/httpClient";
import handleHttpClientErrors from "@utils/handleHttpClientErrors";

import { getUserTokenAndHeader } from "@context/AuthContext";

interface UpdatePasswordProps {
  userId: string;
  currentPass?: string;
  type?: string;
  password: string;
  confirm: string;
};

const updatePassword = async (userData: UpdatePasswordProps) => {

  try {

    const headerAuth = await getUserTokenAndHeader();

    const response = await httpClient('/users/update_password', {
      method: 'PATCH',
      headers: { ...headerAuth },
      data: userData
    });

    const data = response.data;

    return { error: false, data };

  } catch (error: any) {
    const { messages, status } = handleHttpClientErrors(error);
    return { error: true, messages, status };
  }

};

export default updatePassword;