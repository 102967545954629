import { FetchRest } from "@config/httpClient";

interface ExportProductToStoreProps extends ServiceProps {
  interfaceLang: string;
};

interface ServiceProps {
  userId: string;
  productId: string;
  storeId: string;
  language: string;
};

export const exportProductToStoreService = async (props: ExportProductToStoreProps) => {

  const { interfaceLang, ...serviceProps } = props;

  const uri = `/shopify/export_product`;
  const response = await FetchRest<ServiceProps>({
    uri,
    method: 'POST',
    requireAuth: true,
    body: serviceProps
  });

  if (response.error) {
    const message = response.messages[interfaceLang];
    throw new Error(message);
  }

  return response.data;

};