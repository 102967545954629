export interface RecoveryFormModelInterface {
  formId: string;
  formField: {
    [x: string]: { [y: string]: any }
  }
};

const recoveryFormModel = {
  formId: 'recovery_pass_one',
  formField: {
    email: {
      name: 'email',
      label: 'emailField'
    },
    password: {
      name: 'password',
      label: 'passField'
    },
    confirm: {
      name: 'confirm',
      label: 'confirmField'
    }
  }
};

export default recoveryFormModel;