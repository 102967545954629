import * as React from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Collapse,
  Link,
  Text,
  Icon,
  Checkbox,
  AlertStatus,
  useColorModeValue,
  Tag,
  TagLabel,
  TagLeftIcon,
  useToast,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { Formik, Form, FormikHelpers, FormikProps, Field } from "formik";

import { AiOutlineClose } from "react-icons/ai";
import { InfoIcon } from "@chakra-ui/icons";

import { SeparatorWithText } from "@components/SeparatorWithText";
import { LoadingFormPage } from "@components/LoadingFormPage";

import {
  SelectRegisterOption,
  UserEmailInfoForm,
  UserPersonalInfoForm,
} from "@components/RegisterOptionsForm";

import {
  registerFormInitialValues,
  registerFormModel,
  registerFormSchema,
} from "@forms/register";

import useStepperForm, { HanldeSubmitProps } from "@hooks/useStepperForm";
import registerUser from "@services/user/registerUser";

import { useAuth } from "@context/AuthContext";
import { loginUserAction } from "@actions/authActions";
import { PageMetadata } from "@components/PageMetadata";
import sendReferralEmail from "@services/user/sendReferralEmail";

const { useState, useEffect } = React;

const toastId = "error-in-register";

interface AlertState {
  type: AlertStatus;
  message: string;
}

export const RegisterPage = () => {
  const { t, i18n } = useTranslation();
  const { dispatch } = useAuth();

  const toast = useToast();

  const { activeStep, isLastStep, handleBackStep, handleNextStep } =
    useStepperForm({ steps: ["Select Register", "Basic Info", "Email Info"] });

  const [searchParams] = useSearchParams();
  const [hasError, setHasError] = useState<null | AlertState>(null);

  const currentSchema = registerFormSchema(
    [
      t("firstNameValiationEmpty"),
      t("firstNameValiationMin"),
      t("firstNameValiationMax"),
    ],
    [
      t("lastNameValiationEmpty"),
      t("lastNameValiationMin"),
      t("lastNameValiationMax"),
    ],
    [t("emailValidation"), t("emailValidationEmpty")],
    [
      t("passValidation"),
      t("passValidationMin"),
      t("confirmValidationRequired"),
      t("confirmValidation"),
    ]
  )[activeStep];

  const handleSendForm = async (data: any, helpers?: FormikHelpers<any>) => {
    helpers?.setSubmitting(true);

    try {
      const typeUser = searchParams.get("registerType")
        ? searchParams.get("registerType") === "affiliate_program"
          ? "mentor"
          : "client"
        : "client";

      const referralCode =
        typeUser === "client" ? "" : searchParams.get("referralCode") || "";

      const formatedData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        confirm: data.confirm,
        userType: typeUser,
        referralCode: data.referralCode,
        countryCode: data.countryCode,
        phoneNumber: data.phoneNumber,
      };

      const response = await registerUser(formatedData);

      if (response.error) {
        setHasError({
          type: "error",
          message: response.messages[i18n.language.substring(0, 2)],
        });

        helpers?.setSubmitting(false);
        return;
      }

      setHasError({
        type: "success",
        message: t("successUserRegister"),
      });

      const dataResponse = response.data?.data;

      const userToken = {
        "@token": dataResponse?.userToken || "",
        "@user_email": dataResponse?.userData?.email || "",
      };

      const emailKeyStorage = `device${dataResponse?.userData?.email}`;

      if (
        localStorage.getItem(emailKeyStorage) === null ||
        localStorage.getItem(emailKeyStorage) == ""
      ) {
        localStorage.setItem(emailKeyStorage, dataResponse?.deviceId);
      }

      localStorage.setItem("deviceId", dataResponse?.deviceId);
      localStorage.setItem("__token__", JSON.stringify(userToken));
      localStorage.setItem("__authtype__", "password");

      //Send referral email
      const referralEmailResponse = await sendReferralEmail({
        userId: response.data?.data?.userData?.userId || "",
        lang: i18n.language.substring(0, 2)
      });

      if (referralEmailResponse.error) {
        setHasError({
          type: "error",
          message: "Error sending referral email",
        });

        helpers?.setSubmitting(false);
        return;
      }

      dispatch(loginUserAction(dataResponse?.userData, "password"));
    } catch (error) {
      const customMessages: any = {
        en: `An unexpected error occurred, please try again later.`,
        es: `Ocurrio un error inesperado, por favor intentalo de nuevo más tarde.`,
        pt: `Ocorreu um erro inesperado, tente novamente mais tarde.`,
      };

      setHasError({
        type: "error",
        message: customMessages[i18n.language.substring(0, 2) || "en"],
      });

      helpers?.setSubmitting(false);
    }
  };

  const onSubmitForm = (data: any, helpers: FormikHelpers<any>) => {
    console.log("INFO DEL REGISTRO: ", data);
    handleNextStep({
      values: data,
      actions: helpers,
      submitForm: handleSendForm,
    });
  };

  const handleCleanAlert = () => setHasError(null);

  const textColorRequiredFields = useColorModeValue("gray", "gray.300");

  useEffect(() => {
    if (searchParams.get("error")) {
      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          title: "Error",
          description: decodeURI(searchParams.get("error") || ""),
          status: "warning",
          duration: 9000,
          isClosable: true,
          variant: "left-accent",
          position: "top-right",
        });
      }
    }
  }, [searchParams, toast]);

  return (
    <Box mt={4}>
      <PageMetadata title={t("registerTitle")} />
      <Text as="h2" fontSize="3xl" fontWeight="bold">
        {t("registerTitle")}
      </Text>
      {searchParams.get("registerType") === "affiliate_program" && (
        <Tag size="sm" variant="solid" colorScheme="teal">
          <TagLeftIcon boxSize="12px" as={InfoIcon} />
          <TagLabel>{t("affiliateTag")}</TagLabel>
        </Tag>
      )}
      {activeStep !== 0 && (
        <Text
          mb={4}
          mt={1}
          as="p"
          color={textColorRequiredFields}
          fontSize="small"
          fontWeight="medium"
        >
          {t("requiredFieldsText")}
        </Text>
      )}
      <Box>
        <Formik
          initialValues={registerFormInitialValues}
          validationSchema={currentSchema}
          onSubmit={onSubmitForm}
        >
          {({ isValid, isSubmitting, setFieldValue }: FormikProps<any>) => (
            <Form id={registerFormModel.formId}>
              {isSubmitting && <LoadingFormPage />}

              {_renderStepContent({
                step: activeStep,
                handleNextStep,
                setFieldValue,
              })}

              {activeStep === 2 && (
                <Field name="terms">
                  {({ field }: any) => (
                    <Checkbox mt={4} size="md" colorScheme="brand" {...field}>
                      {t("termsTextOne")}{" "}
                      <Box as="span" display="inline" color="#039BE5">
                        <Link
                          href="/terms_and_conditions_of_use"
                          target="_blank"
                        >
                          {t("termsAndConditions")}.
                        </Link>
                      </Box>
                    </Checkbox>
                  )}
                </Field>
              )}

              <Collapse in={!!hasError} animateOpacity>
                <Alert
                  status={hasError?.type || "info"}
                  borderRadius={4}
                  mt={2}
                >
                  <AlertIcon />
                  <AlertDescription fontSize="sm" lineHeight={1.2}>
                    {hasError?.message}
                  </AlertDescription>
                  <Icon
                    as={AiOutlineClose}
                    position="absolute"
                    right="8px"
                    top="8px"
                    cursor="pointer"
                    onClick={handleCleanAlert}
                  />
                </Alert>
              </Collapse>

              {activeStep !== 0 && (
                <>
                  <Box
                    mt={4}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      isDisabled={isSubmitting}
                      variant="outline"
                      onClick={handleBackStep}
                    >
                      {t("backButtonText")}
                    </Button>
                    <Button
                      type="submit"
                      variant="solid"
                      colorScheme="brand"
                      isDisabled={!isValid || isSubmitting}
                      isLoading={isSubmitting}
                    >
                      {isLastStep
                        ? t("registerButtonText")
                        : t("continueButtonText")}
                    </Button>
                  </Box>
                </>
              )}

              <SeparatorWithText my={6} text="" />

              <Text align="center">
                {t("haveAccountText")}
                <Link as={RouterLink} to="/login" ml={1} color="blue.300">
                  {t("haveAccountLink")}
                </Link>
              </Text>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

interface RenderStepContentProps {
  step: number;
  handleNextStep: ({ values, actions, submitForm }: HanldeSubmitProps) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const _renderStepContent = ({
  step,
  handleNextStep,
  setFieldValue,
}: RenderStepContentProps) => {
  switch (step) {
    case 0:
      return <SelectRegisterOption handleNextStep={handleNextStep} />;
    case 1:
      return <UserPersonalInfoForm />;
    case 2:
      return <UserEmailInfoForm setFieldValue={setFieldValue} />;
    default:
      return <div>Step not found</div>;
  }
};
