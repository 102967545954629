import { Dispatch } from "react";

import logoutUser from "@services/user/logoutUser";

import { AuthAction } from "@interfaces/AuthStateInterface";
import { UserDataInterface } from "@interfaces/UserDataInterface";

export const LOGIN_USER = '[AUTH] Login An User';
export const UPDATE_USER = '[AUTH] Update the user logged ing';
export const LOGOUT_USER = '[AUTH] Logout An User';
export const SET_CHECKING_SESSION = '[AUTH] Set Checking session to true';
export const REMOVE_CHECKING_SESSION = '[AUTH] Set Checking session to false';

export const loginUserAction = (user: UserDataInterface | null, authType: string): AuthAction => ({
  type: LOGIN_USER,
  payload: { user, authType }
});

export const updateUserAction = (userData: any): AuthAction => ({
  type: UPDATE_USER,
  payload: userData
});

export const logoutUserAction = async (dispatch?: Dispatch<any>): Promise<AuthAction> => {

  if (dispatch)
    dispatch(setCheckingSessionAction());

  try {

    const authType = localStorage.getItem('__authtype__');

    if (authType !== 'social' && authType !== 'password') return { type: 'ERROR_LOGOUT' };

    if (authType === 'social') {

      const email = localStorage.getItem('__useremail__');
      if (!email) return { type: 'ERROR_LOGOUT' };

      await logoutUser(email);

      /* Data cleansing */
      localStorage.removeItem("labelFilterCategories");
      localStorage.removeItem("valueFilterCategories");
      localStorage.removeItem("labelFilterDropshippingCategory");
      localStorage.removeItem("valueFilterDropshippingCategory");
      // localStorage.removeItem("LoadRandomPage");
      localStorage.removeItem("filterCategory");
      localStorage.removeItem('__useremail__');
      localStorage.removeItem('__authtype__');
      localStorage.removeItem("oldPage");

      return { type: LOGOUT_USER };

    } else {

      const userInfo = localStorage.getItem('__token__') || '';
      const parsedData = JSON.parse(userInfo);

      const email = parsedData['@user_email'];
      await logoutUser(email);

      /* Data cleansing */
      localStorage.removeItem("labelFilterCategories");
      localStorage.removeItem("valueFilterCategories");
      localStorage.removeItem("labelFilterDropshippingCategory");
      localStorage.removeItem("valueFilterDropshippingCategory");
      // localStorage.removeItem("LoadRandomPage");
      localStorage.removeItem("filterCategory");
      localStorage.removeItem('__token__');
      localStorage.removeItem('__authtype__');
      localStorage.removeItem("oldPage");

      return { type: LOGOUT_USER };
    }

  } catch (error) {
    return { type: 'ERROR_LOGOUT' };
  } finally {
    if (dispatch)
      dispatch(removeCheckingSessionAction());
  }

};

export const setCheckingSessionAction = (): AuthAction => ({ type: SET_CHECKING_SESSION });

export const removeCheckingSessionAction = (): AuthAction => ({ type: REMOVE_CHECKING_SESSION });