import ReactDOM from 'react-dom';
import { ColorModeScript } from '@chakra-ui/react';

import { App } from './App';
import theme from './theme';
import './i18n';

ReactDOM.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </>,
  document.getElementById('root')
);
