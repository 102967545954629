import * as React from 'react';

import {
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  InputProps,
} from '@chakra-ui/react';

import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

interface Props extends InputProps {}

const { useState } = React;

export const PasswordInput = (props: Props) => {
  const [show, setShow] = useState<boolean>(false);

  const handleToggleVisibility = () => setShow((show) => !show);

  return (
    <InputGroup>
      <Input type={show ? 'text' : 'password'} {...props} />
      <InputRightElement>
        <IconButton
          width="100%"
          onClick={handleToggleVisibility}
          variant="ghost"
          aria-label={show ? 'Hide password' : 'Show password'}
          icon={show ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
        />
      </InputRightElement>
    </InputGroup>
  );
};
