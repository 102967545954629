import {
  LinkBox,
  LinkOverlay,
  Icon,
  useTheme,
  Tooltip,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import { SiShopify } from 'react-icons/si';
import { useRecoilValue } from 'recoil';

import { userMainStoreState, userStoresState } from '@store/stores/atoms';

export const UserStore = () => {
  const theme = useTheme();

  const userMainStore = useRecoilValue(userMainStoreState);
  const userStores = useRecoilValue(userStoresState);

  const greenColorTheme = String(
    theme.__cssVars['--chakra-colors-green-500']
  ).substring(1);

  const colorWithOpacity = `#${greenColorTheme}1A`;

  if (!userMainStore) return null;

  return (
    <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(5, 1fr)" }} gap={1}>
      {userStores.stores.map((store: any) =>
        <GridItem
          key={store.id}
        >
          <Tooltip
            label={store.storeUrl}
            bg={"gray.600"}
            borderRadius={"6"}
            hasArrow
          >
            <LinkBox
              bg={colorWithOpacity}
              borderColor="green.500"
              borderRadius={6}
              borderWidth="1px"
              cursor="pointer"
              p={2}
              mx={1}
            >
                <LinkOverlay
                  display="flex"
                  alignItems="center"
                  target="_blank"
                  href={`https://${store.storeUrl}`}
                  rel="noopener noreferrer"
                >
                  <Icon
                    as={SiShopify}
                    color="green.500"
                    w={{ base: '7', md: '5' }}
                    h={{ base: '7', md: '5' }}
                  />
                </LinkOverlay>
            </LinkBox>
          </Tooltip>
        </GridItem>
      )}
    </Grid>
  );
};
