import { IconType } from 'react-icons';
import { GiPayMoney, GiPlatform } from 'react-icons/gi';
import { BiHelpCircle } from 'react-icons/bi';

import { RiLuggageCartFill } from 'react-icons/ri';
import { FiUsers } from 'react-icons/fi';
import { IoStorefrontOutline, IoLibraryOutline, IoPeopleCircleOutline } from 'react-icons/io5';
import { CgProfile, CgUserList } from 'react-icons/cg';
import { MdOutlineDevices, MdPlaylistAdd } from 'react-icons/md';
import { FaBoxes } from "react-icons/fa";
import { AiOutlineUsergroupAdd } from 'react-icons/ai';

interface MenuItemInterface {
  name: string;
  i18Key: string;
  isAdmin: boolean;
  path: string;
  showToAdmin: boolean;
  subsCanAccess: string[];
  Icon: IconType;
};

export const MenuItems: MenuItemInterface[] = [
  {
    name: 'Products',
    i18Key: 'menuItemProducts',
    isAdmin: false,
    path: '/app/products',
    showToAdmin: true,
    subsCanAccess: ['all'],
    Icon: RiLuggageCartFill
  },
  {
    name: 'suggest_product',
    i18Key: 'menuItemSuggestProducts',
    isAdmin: false,
    path: '/app/suggest/product',
    showToAdmin: false,
    subsCanAccess: ['all'],
    Icon: MdPlaylistAdd
  },
  {
    name: 'suggested_products_list',
    i18Key: 'menuItemSuggestedProducts',
    isAdmin: true,
    path: '/app/suggested/products',
    showToAdmin: true,
    subsCanAccess: ['all'],
    Icon: FaBoxes
  },
  {
    name: 'Platforms',
    i18Key: 'menuItemPlatforms',
    isAdmin: false,
    path: '/app/platforms',
    showToAdmin: true,
    subsCanAccess: ['none'],
    Icon: GiPlatform
  },
  {
    name: 'my_list',
    i18Key: 'menuItemMyProducts',
    isAdmin: false,
    path: '/app/my_lists',
    showToAdmin: true,
    subsCanAccess: ['all'],
    Icon: CgUserList
  },
  {
    name: 'store',
    i18Key: 'menuItemStores',
    isAdmin: false,
    path: '/app/stores',
    showToAdmin: false,
    subsCanAccess: ['all'],
    Icon: IoStorefrontOutline
  },
  {
    name: 'fads',
    i18Key: 'menuItemAds',
    isAdmin: false,
    path: '/app/library_ads',
    showToAdmin: true,
    subsCanAccess: ['all'],
    Icon: IoLibraryOutline
  },
  {
    name: 'community',
    i18Key: 'community',
    isAdmin: false,
    path: '',
    showToAdmin: true,
    subsCanAccess: ['all'],
    Icon: IoPeopleCircleOutline
  },
  {
    name: 'profile',
    i18Key: 'menuItemProfile',
    isAdmin: false,
    path: '/app/profile',
    showToAdmin: true,
    subsCanAccess: ['all'],
    Icon: CgProfile
  },
  {
    name: 'referrals',
    i18Key: 'menuItemReferrals',
    isAdmin: false,
    path: '/app/referrals',
    showToAdmin: false,
    subsCanAccess: ['all'],
    Icon: AiOutlineUsergroupAdd
  },
  {
    name: 'users',
    i18Key: 'menuItemUsers',
    isAdmin: true,
    path: '/app/users',
    showToAdmin: true,
    subsCanAccess: ['none'],
    Icon: FiUsers
  },
  {
    name: 'request_devices',
    i18Key: 'menuItemDevices',
    isAdmin: true,
    path: '/app/devices',
    showToAdmin: true,
    subsCanAccess: ['none'],
    Icon: MdOutlineDevices
  }
  ,
  {
    name: 'request_withdrawals',
    i18Key: 'menuItemWithdrawals',
    isAdmin: true,
    path: '/app/withdrawals',
    showToAdmin: true,
    subsCanAccess: ['none'],
    Icon: GiPayMoney
  },
  {
    name: 'frequent_questions',
    i18Key: 'menuItemFrequentQuestions',
    isAdmin: false,
    path: '/app/frequent_questions',
    showToAdmin: true,
    subsCanAccess: ['all'],
    Icon: BiHelpCircle
  },
];


