const enTranslations = {
  language: "Language",
  english: "English",
  spanish: "Spanish",
  portuguese: "Portuguese",
  loginWelcome: "Welcome to",
  loginTitle: "Login",
  emailValidation: "Must be a valid email",
  emailValidationEmpty: "The email is a required field",
  passValidation: "The password is a required field",
  passValidationMin: "The password must contain at least 8 characters",
  confirmValidation: "Passwords must match",
  confirmValidationRequired: "Password confirmation is required",
  emailField: "Email",
  passField: "Password",
  confirmField: "Password confirmation",
  phoneField: "phone",
  selectCountryCode: "Select your country code correctly to ensure good communication.",
  loginButtonText: "Log in",
  forgotPassText: "Forgot password?",
  noAccountText: "Don't have an account?",
  noAccountLink: "Sign up",
  haveAccountText: "you have an account?",
  haveAccountLink: "login",
  rememberUser: "Remember user",
  registerTitle: "New Register",
  firstNameField: "First Name",
  lastNameField: "Last Name",
  firstNameValiationEmpty: "The first name is a required field",
  firstNameValiationMin: "the first name cannot contain less than 3 characters",
  firstNameValiationMax: "the first name cannot contain more than 50 characters",
  lastNameValiationEmpty: "The last name is a required field",
  lastNameValiationMin: "the last name cannot contain less than 3 characters",
  lastNameValiationMax: "the last name cannot contain more than 50 characters",
  referralCodeField: "Referral code",
  copyReferralCode: "Copy referral code",
  copyReferralLink: "Copy link referral code",
  withoutReferralCode: "Without referral code",
  termsTextOne: "By signing up you agree to our",
  registerButtonText: "Sign Up",
  successUserRegister: "Registered user successfully",
  verifyingAuthOne: "We are verifying your username.",
  verifyingAuthTwo: "This will take a few seconds ..",
  userSignUpWithSocial: "We are creating your user in Viraly",
  requiredFieldsText: "Fields marked with * are required",
  backButtonText: "Go Back",
  continueButtonText: "Continue",
  signupGoogle: "Sign up with Google",
  signupFacebook: "Sign up with Facebook",
  signupEmail: "Sign up with email",
  orOption: "Or",
  signinGoogle: "Sign in with Google",
  signinFacebook: "Sign in with Facebook",
  linkToMentorForm: "Do you want to join our affiliate program?",
  joinHere: "Join here",
  affiliateTag: "AFFILIATE",
  recoveryPassTitle: "Password recovery",
  rememberPassTextLink: "Did you remember your password?",
  returnToHome: "Back to login",
  emailSendedForRecoveryOne: "If the email matches an existing account, we'll send a password reset email within a few seconds.",
  emailSendedForRecoveryTwo: "If you did not receive the email, please check your spam folder or",
  emailSenderForRecoverySupport: "contact Customer Support.",
  messageVerifyingToken: "We are verifying the token, please wait.",
  updatePassButtonText: "Update",
  paymentFormTitle: "Payment form",
  paymentDate: "Payment date",
  paymentFormPrice: "Price",
  paymentFormPlan: "Subscription",
  paymentFormPayButton: "Pay",
  paymentFormCardNumberField: "Card number",
  paymentFormExpirationDateField: "Expiration date",
  paymentFormStripeProtect: "All your payments are protected and managed by",
  paymentFormTerms: "By continuing you agree to our",
  paymentFormTermsLink: "Terms and conditions",
  paymentFormMessageProcessing: "We are processing your payment, please be patient.",
  invoiceDetailsPaymentTitle: "Payment Successful",
  invoiceDetailsID: "Invoice ID",
  invoiceDetailsSummary: "Summary",
  invoiceDetailsDownload: "Download Invoice",
  Page: "Plans that fit your need",
  pricingButton: "Choose Plan",
  mostPopularText: "Most Popular",
  menuItemDashboard: "Dashboard",
  menuItemProducts: "Viraly products",
  menuItemFrequentQuestions: "Frequent questions",
  menuItemPlatforms: "Platforms",
  menuItemStores: "My Stores",
  menuItemAds: "Viraly Ads",
  menuItemArticles: "Articles",
  menuItemUsers: "Users",
  menuItemProfile: "Profile",
  menuItemReferrals: "Referrals",
  menuItemSuggestProducts: "Suggest products",
  menuItemSuggestedProducts: "Suggested products",
  month: "Month",
  topProductsTitle: "Top products of the week",
  referralTagTransactions: "Transactions",
  referralTagList: "Referrals",
  totalReferrals: "Total referrals",
  activeReferrals: "Total assets",
  referralTagWithdraws: "Withdraws",
  copiedCode: "Copied code",
  profileTabPersonalInfo: "Personal information",
  profileTabSubscription: "Subscription",
  profileTabConfig: "Devices",
  documentTypeField: "Document type",
  documentNumberField: "Document number",
  phoneNumberField: "Phone number",
  countryField: "Country",
  cityField: "City",
  zipCodeField: "ZIP Code",
  profileTextNonEditableFields: "In the disabled fields it is not possible to modify the information in order to maintain its integrity, if you wish to update this information please contact support.",
  textButtonSave: "Save",
  profileSubCurrentBill: "Current bill",
  profileSubNextPayment: "Next payment",
  profileSubPlan: "Current plan",
  discountCoupon: "You have a discount coupon of",
  profileSubPMTitle: "Payment Methods",
  profileSubAddPM: "Add Payment Method",
  profileSubBillingText: "Billing History",
  profileSubBillingMessage: "We only upload the last 8 invoices",
  profileSubIsDefault: "Default",
  profileSubEdit: "Edit",
  profileSubExpires: "Expires",
  profileSubInvoiceDate: "Date",
  profileAddPMPerson: "Cardholder Name",
  profileAddPMisDefault: "Set as default",
  profileAddPMTitle: "Add Payment Method",
  profileUpdatePMTitle: "Update Payment Method",
  profilePMNote: "Note",
  profilePMNoteMessage: "When adding a payment method we cannot guarantee that it will work correctly for future payments, please make sure that your card works correctly.",
  prfilePMCancelButton: "Cancel",
  profilePMTitleToast: "Updated data",
  maintenanceTitle: "Maintenance",
  maintenanceMessage: "We are making improvements to our system, some failures may occur during Saturday, April 13 and Sunday, April 14. We appreciate your understanding.",
  communityInvitationTitle: "Join our Skool community",
  communityMessageInvitation: "We invite you to join our Skool community! There you will find training, FAQs, recommendations and all the relevant information about Viraly to improve your sales process.",
  goToCommunity: "Go to community",

  // Default
  close: "Close",
  actions: "Actions",
  income: "income",
  incomeSingle: "income",
  expenses: "expenses",
  expenseSingle: "expense",
  type: "Type",
  amount: "Amount",
  description: "Description",
  motive: "Motive",
  date: "Fecha",
  name: "Name",
  deleteTitle: "Delete",
  deleteBody: "¿Are you sure you want to delete this item?",
  deleteIdentifier: "¿Are you sure you want to remove the identifier?",
  deleteCancelButton: "Cancel",
  titleAcquirePlan: "Acquire your plan",
  bodyAcquirePlan: "Acquire our plan and discover our complete catalog",
  headerEmail: "Email",
  headerStatus: "Status",
  referralStatusRegistered: "Registered",
  referralStatusActived: "Subscribed",
  referralStatusCancelled: "cancelled",
  commission: "Commission",
  withdrawal: "Withdrawal",
  accepted: "Accepted",
  confirmationMessage: "Please confirm the action",
  thankYouTitle: "thank you for being part of our community",
  thankYouBody: "With your subscription, all premium services have been unlocked, to access these you can use the main panel on the left.",
  termsAndConditions: "Terms and conditions",
  themeColor: "Theme color",
  new: "New",
  contact: "Contact",
  contactModalDescription: "If you have any questions, please do not hesitate to contact us.",
  community: "Skool Community",

  /* Validaciones para el formulario de personal Info dentro del perfil */
  piDocumentTypeOneOf: "You must select one of the options.",
  piDocumentNumberMin: "The field must contain at least 5 characters.",
  piDocumentNumberMax: "The field must contain a maximum of 15 characters.",
  piCountryMin: "The field must contain at least 4 characters.",
  piCountryMax: "The field must contain a maximum of 50 characters.",
  piCityMin: "The field must contain at least 4 characters.",
  piCityMax: "The field must contain a maximum of 50 characters.",
  piZipCodeMin: "The field must contain at least 3 characters.",
  piZipCodeMax: "The field must contain a maximum of 6 characters.",
  piPhoneNumberCodeMin: "The field must contain at least 1 characters.",
  piPhoneNumberCodeMax: "The field must contain a maximum of 3 characters.",
  piPhoneNumberCodeReq: "The country code is required if a phone number is indicated.",
  piPhoneNumberMin: "The field must contain at least 8 characters.",
  piPhoneNumberMax: "The field must contain a maximum of 14 characters.",
  billingBoxesTimezone: "Time conversion",
  billingBoxesChangePlan: "Change Plan",
  billingBoxesChangePlanAsAdmin: "Change Plan as Admin",
  changeSubscriptionAsAdminMessageHelper: "From where you can change the subscription of users. If you don't indicate an end date, the subscription will be permanent.",
  subscriptionRequired: "You must select a subscription",
  subscriptionActiveUntil: "End date",
  subscriptionActiveUntilMinDateValidation: "The end date must be greater than the current date.",
  subscriptionActiveUntilHelper: "Leave this field empty if the subscription will be permanent.",
  billingBoxedActiveFrom: "Active From",

  profileHeaderTitle: "Profile",
  profileHeaderActiveFrom: "Member since",
  profileHeaderAuthType: "Registered with",
  profileHeaderActiveSessions: "Active sessions",
  profileHeaderUserRole: "User Role",
  profileHeaderChangeAvatar: "Change avatar",
  profileHeaderChangePassword: "Change password",
  profileHeaderBlockUser: "Block user",
  profileViewReferralPayments: "View Payments",

  /* Products */
  productsFilterOrderField: "Sort by",
  productsFilterCategoryField: "Filter by Category",
  productsFilterSearchField: "Search By Title",
  productsFilterLangField: "Filter by Lang",
  productsFilterStatusField: "Filter by State",
  productsFilterAnyLang: "Any Lang",
  productsFilterAllLang: "All",
  productsFilterAlertMessage: "If you like and do not find the ID of your country, you can search by name; it may be available on the platform",

  /* Toolbar Tooltips editor */
  toolbarParagraph: "Paragraph",
  toolbarHeading: "Heading",
  toolbarBold: "Bold",
  toolbarItalic: "Italic",
  toolbarStrike: "Strikethrough",
  toolbarHighlight: "Highlight",
  toolbarBulletList: "Bullet List",
  toolbarOrderedList: "Ordered List",
  toolbarBlockQuote: "Block Quote",
  toolbarHorizontalRule: "Horizontal Rule",
  toolbarLink: "Link",
  toolbarImage: "Add Image",
  toolbarUndo: "Undo",
  toolbarRedo: "Redo",
  toolbarAlignLeft: "Align Left",
  toolbarAlignCenter: "Align Center",
  toolbarAlignRight: "Align Right",
  toolbarAlignJustify: "Justify",

  /* Profile Header Extras */
  profileHeaderPassRecoveryTitle: "Change Password",
  profileHeaderPassRecoveryCurrent: "Current Password",
  profileHeaderPassRecoveryNew: "New Password",
  profileHeaderPassRecoveryNewConfirm: "New Password Confirmation",
  profileHeaderPassRecoveryNewMessageHelper: "For information security, your current session will end once the password is updated.",
  profileHeaderConfirmEmailTextLink: "Send confirmation email",
  profileHeaderConfirmEmailIsSending: "We are sending the code to your email.",
  profileHeaderConfirmEmailIsCodeSended: "Enter the 9-digit code sent to your email.",
  profileHeaderConfirmEmailIsCodeSendedTip: "you can copy and paste the code.",

  contentNotFoundTitle: "Page Not Found",
  contentNotFoundDesc: "The page you're looking for does not seem to exist",
  contentNotFoundButton: "Go to Dashboard",

  renewPlanBarTitle: "You are using Viraly for free, upgrade your plan to get all the benefits",
  renewPlanBarTitleMBA: "As an MBA member you have access to viraly for 10 days for free. Upgrade your plan when this offer expires",
  renewPlanBarTitleTrial: "Enjoy all the benefits of viraly for 24 hours",
  renewPlanBarButton: "Upgrade your plan",
  frequencyPlan: "Select plan frequency",
  monthlyPlan: "Monthly",
  biannualPlan: "Biannual",
  annualPlan: "Annual",
  saveYourselfTextBegin: "Save yourself",
  saveYourselfTextEnd: "paying the full year",

  pricingPageTitleCancel: "Cancel Plan",
  pricingSelectPlanRecurrency: "month",
  pricingSelectPlanSelected: "Selected",
  pricingSelectPlanCurrent: "Current Plan",
  bronze: "Bronze",
  silver: "Silver",
  gold: "Gold",

  checkoutFormCreatingPaymentIntent: "We are generating your payment order, be patient...",
  checkoutFormPaymentSuccessfuly: "Your payment was successful, we will refresh the page to get your updated data. ✅",

  changePlanResume: "Resume",
  changePlanInfoText: "When changing plans, a proration is applied to adjust the balance with the subscription change.",
  changePlanItem: "Item description",
  changePlanAmount: "Amount",
  changePlanNextPayment: "Next payment date",
  chnagePlanSelectCard: "Select a payment method",
  changePlanNoteText: "The new subscription will be activated immediately and prorations will be charged on the next payment date.",

  profileModalDeleteCardTitle: "Delete Card",
  profileModalDeleteCardMessageOne: "Are you sure to delete the card:",
  profileModalDeleteCardMessageTwo: "? You can not undo this action later.",
  profileModalDeleteCardMessageIsDefault: "To delete this card you must select a new method of default payment before.",
  profileModalDeleteCardButton: "Delete",

  profileCancelSubTitle: "Cancel subscription",
  profileCancelSubMessage: "When you cancel your subscription you will stop accessing the functionalities that Vialy offers you.",

  devicesCardTitle: "Registered devices",
  devicesCardClient: "Client",
  devicesCardClientV: "Client version",
  devicesCardInUse: "Current in use",

  registerDeviceTitle: "Register device",
  registerDeviceMessageSend: "Enter the 6-digit code sent to your email.",
  registerDeviceSendButtonText: "Send",
  registerDeviceSuccessMessageOne: "Device registration is complete",
  registerDeviceSuccessMessageTwo: "You can now log in again",

  profileEmptyResultsPM: "You do not have associated payment methods yet.",
  profileEmptyResultsInvoices: "You still do not have invoices in your name.",

  cancelSubCancelText: "Thanks for spending time with us. You’re always welcome back. Finish your cancellation below.",
  cancelSubListOne: "Cancellation will be effective at the end of your current billing period on",
  cancelSubListSecond: "Come back when you want. all your preferences, products, devices will be waiting for you.",
  cancelWarningAd: "Remember that when changing the plan, the remaining days of the current plan are excluded.",
  cancelSubFinishButtonText: "Finish Cancellation",
  cancelSubChangePlanTitle: "Switch plan",
  cancelSubChangePlanText: "Switching plans is easy, you can change your plan to a lower price below.",
  cancelSubNoPlansText: "Do you want to continue with your subscription?",

  reactivationMessageText: "Your subscription ends the next cut date, you can reactivate your subscription and continue with your monthly payments.",
  reactivationMessageButtonText: "Reactivate",
  reactivationMessageLoadingText: "We are reactivating your subscription",

  /* Products Orden */
  productsFilterOrderNewer: "Newer",
  productsFilterOrderOldest: "Oldest",
  productsFilterOrderTitleAsc: "Title - Ascending",
  productsFilterOrderTitleDesc: "Title - Descending",

  productsFilterNoResults: "No Options",

  productsListTitleResults: "Results",
  productsListFiltersTitleCategory: "Category",
  productsListFiltersTitleLang: "Lang",
  productsListFiltersTitleSort: "Sort",

  productsHeaderProductType: "Product Type",

  productsFiltersKeyFormatedSortBy: "Sort",
  productsFiltersKeyFormatedCategory: "Category",
  productsFiltersKeyFormatedLang: "Lang",

  productsAdminButtonMyProductsText: "My products",
  productsAdminButtonChangeViewText: "Toggle View",
  productsAdminButtonCategoriesText: "Categories",
  productsAdminButtonNewProductText: "Add Product",

  listResultsOf: "of",

  productCategoriesAddTitle: "Add Category",
  productCategoriesAddCategoryName: "Category name",

  productEditTitleNew: "New Product",
  productEditTitleUpdate: "Update Product",
  productEditProductType: "Product Type (Viraly)",
  productEditProductCategory: "Product Category (Viraly)",
  dropshippingOrEcommerceType: "Product type",
  addIdentifiers: "Add identifiers",
  identifier: "Identifier",
  productEditTitleProductDesc: "Product Description",
  productEditTitleProductDescText: "You can create different descriptions of the product, for each of the available languages, in turn you can indicate which of the versions will be published and which will not.",
  productEditControlsStatus: "Product Status",
  productEditControlsStatusPublicText: "The product will be visible to users.",
  productEditMediaElementsTitle: "Media Elements",
  productEditMediaElementsByLang: "By lang",
  productEditMediaElementsForEveryone: "The same for all languages",
  productEditPreviewButtonText: "Preview",
  productEditSaveAllButtonText: "Save All",
  productEditDescStatusLabel: "STATUS",
  productEditDescChangeStatus: "Change Status",
  productEditDescChangeStatusDraft: "Draft",
  productEditDescChangeStatusPublic: "Public",
  productEditDescProductName: "Product Name",
  productEditDescProductDesc: "Product Body Description",
  productEditMediaElementsTitleDnD: "Drag 'n' drop some files here, or click to select files",
  productEditMediaElementsTitleDnDDrop: "Drop files here ...",
  productEditMediaElementsUploadingError: "Error uploading file",
  productEditMediaElementsUploadRejected: "Only the following file types are allowed",
  productEditProductOrg: "Product Organization",
  productEditProductOrgType: "Product Type",
  productEditProductOrgTags: "Product Tags",
  productEditProductOrgTagsPla: "Add new tags",
  productEditProductSEOTitle: "Product SEO",
  productEditProductSEOTitlePage: "Page Title",
  productEditProductSEOCharsUsed: "characters used",
  productEditProductSEOMetaDesc: "Meta Description",

  productEditMediaElementsDeleteFileTitle: "Delete File",
  productEditMediaElementsDeleteFileDesc: "Are you sure? You can't undo this action afterwards.",

  productEditTranferMediaTitle: "Transfer files",
  productEditTranferMediaText: "It was detected that you have multimedia elements in some of the descriptions, the following files will be transferred.",
  productEditTranferMediaButtonText: "Transfer",

  productEditVariantsTitle: "Product Variants",
  productEditVariantsCheckbox: "This product has variants",
  productEditVariantsOptionsTitle: "Options",
  productEditVariantsOptionsButtonAdd: "Add another option",
  productEditVariantsOptionsInfoMessage: "Each value of each option must be separated by a comma",
  productEditVariantsOptionsAddName: "Option name",
  productEditVariantsOptionsAddNamePlaceholder: "Colour",
  productEditVariantsOptionsAddValues: "Option values",
  productEditVariantsOptionsAddValuesPlaceholder: "Red, Green, Orange, etc.",
  productEditVariantsListTitle: "Variants",
  productEditVariantsListMessage: "After saving the product you will be able to add an image to each variant.",

  productEditCopyTitle: "Copy / Marketing Elements",
  productEditCopyDescription: "Text - Description",
  productEditCopyDescriptionPlaceholder: "Write the text here, you can use emojis.",
  productEditCopyMediaTitle: "Thumbnails / Videos",

  productEditUnsavedChangesMessage: "You have unsaved changes, do you want to leave the page?",

  productEditInfoModalButtonTextTitle: "Help - Information",
  productEditInfoModalWarning: "Descriptions with a Blank status or that do not contain the fields: Title, Body - Description, Multimedia (If necessary) will be ignored and will not be saved.",
  productEditInfoModalPrimaryText: "In this section you will find the necessary information to create a product properly. When saving a product, certain parameters listed below must be met.",
  productEditInfoModalFirstTitle: "General Product - Digital",
  productEditInfoModalSecondTitle: "Google Product",
  productEditInfoModalThirdsTitle: "Facebook Product",
  productEditInfoModalFirstText: "All products must contain the following fields filled in to be able to save said product",
  productEditInfoModalFirstItem1: "Product Type, Category",
  productEditInfoModalFirstItem2: "At least one of the descriptions must contain: Title, Body - Description, at least one multimedia file",
  productEditInfoModalFirstItem3: "In the case of wanting to publish the product for customer access, one of the descriptions with the mentioned characteristics must have its status in",
  productEditInfoModalFirstItem4: "In case the media is unique for all languages, it must contain a minimum of one media file",
  productEditInfoModalSecondText1: "To save a Google type product in",
  productEditInfoModalSecondText2: "mode, it must contain the characteristics of a general product, in case you want to publish it, you must have some additional characteristics.",
  productEditInfoModalTextOthersItems: "All the features of a general product",
  productEditInfoModalSecondItem: "fields must be complete",
  productEditInfoModalThirdText1: "To save a Facebook-type product in",
  productEditInfoModalThirdItem: "fields must be complete",

  productSavingMessage: "We are saving product information ...",

  productEditTitle: "Edit Product",
  productEditionModeLabel: "Edition Mode",
  productEditMetadataTitle: "Product Metadata",
  productEditMetadataCreatedAt: "Created At",
  productEditMetadataUpdatedAt: "Last Update",
  productEditMetadataCreatedBy: "Created By",
  productEditMetadataUpdatedBy: "Last Update By",
  productEditDescMetadataTitle: "Description Metadata",

  productEditNotFoundButton: "Go to products",

  productEditVariantsSelectImageTitle: "Update Variant Image",
  productEditVariantsSelectImageButtonSelect: "Select",
  productEditVariantsWarningText: "Changing option values rebuilds variants. Added images will be deleted.",

  productDetailsCopyButtonText: "Copy Text",
  productDetailsCopiedButtonText: "Copied",

  productListFilterButtonText: "Filters",
  productListEmptyResults: "No results were obtained, please try another type of filter.",
  productListCardSeeDetails: "See Details",

  productDetailsLoadingText: "We are loading the product...",
  productDetailsReportError: "report a problem with the product",
  productDetailsButtonTextAddToMyStores: "Add to my store",
  productDetailsButtonTextRemoveToMyStores: "Remove from my stores",
  productDetailsCopyTitle: "Copy for Facebook",
  productDetailsCopyVideos: "Promotional videos",
  productDetailsCopyThumbs: "Thumbnails",

  // Productos sugeridos
  suggestProduct: "Suggest Product",
  suggestProductProductName: "Product Name",
  suggestProductProductUrl: "Product URL",
  suggestProductProductImages: "Product Images",
  suggestProductProductNameIsRequired: "Product name is required",
  suggestProductProductUrlIsRequired: "Product URL is required",
  suggestProductProductUrlIsInvalid: "Product URL is invalid",
  suggestProductProductImagesIsRequired: "Product images are required",
  suggestProductProductImagesMinOne: "At least one image is required",
  suggestProductSuggestSendedSuccess: "Suggestion sent successfully. Thank you for your suggestion!",
  suggestProductMustIncludeMinOne: "You must include at least one image",
  suggestProductSuggestSendedError: "An error occurred while sending the suggestion. Please try again.",
  suggestProductClassifedBy: "Classified by",
  suggestProductMarkAsResolved: "Mark as resolved",

  planSelecctionTypesTitle: "Select types",
  planSelecctionTypesFirstTextOneType: "Select 1 type of products to view with your plan.",
  planSelecctionTypesFirstTextTwoType: "Select 2 types of product to view with your plan.",
  planSelecctionTypesNote: "Once selected and purchased the plan you will not be able to change your options.",

  navlinkLogout: "Logout",

  productListAdminTooltipTitle: "Description status",
  productDetailsTitle: "Product Details",

  storesAddButtonText: "Add new store",
  stores: "Stores",
  seeTutorialButtonText: "See Tutorial",
  seeStore: "See store",
  storesAddShopifyButton: "Shopify Store",
  storesAddShopifyTitle: "Add Shopify Store",
  storesAddWooButton: "Woocommerce Store",
  storesAddWooTitle: "Add Woocommerce Store",
  storeAddShopifyValidationStoreNameMin: "The store name must be at least 10 characters long",
  storeAddShopifyValidationStoreKeyMin: "The store key must be at least 20 characters long",
  storeAddShopifyValidationStoreSecretMin: "The API Access Token must be at least 20 characters long",
  storeAddShopifyValidationStoreNameRequired: "The store name is required",
  storeAddShopifyValidationStoreKeyRequired: "The store key is required",
  storeAddShopifyValidationStoreSecretRequired: "The API Access Token is required",

  storeAddShopifyStoreNameLabel: "Store Name",
  storeAddShopifyStoreKeyLabel: "Store Key",
  storeAddShopifyStoreSecretLabel: "API Access Token",

  productCardNoAccessText: "You do not have access to view this product. If you wish to have access, you must upgrade your plan to a higher level by clicking the button below.",

  storeDisableTitle: "Disable store",
  storeDisableMessageOne: "Disable named store: ",
  storeDisableButton: "Disable",
  storeListTitle: "Store List",

  storeExportTitle: "Export Product",
  storeUpdateProductExported: "Update product exported",
  storeUpdateProductExportedAlertMessage: "After updating this product, you will lose any changes you have made in your store.",
  storeExportFirstMessage: "You are going to export the product:",
  storeExportSecondMessage: "please select the language and the store where it will be exported",
  storeExportButtonText: "Export",

  productListSelectLang: "Products Language",
  productListSelectLangDefault: "Default",
  productListModalStoreAdded: "In your store",

  productBlockModalTitle: "Access New Product Catalogs",
  productBlockModalSubTitle: "Upgrade your subscription to get access to an entire catalog with constant updates of additional products.",

  menuItemMyProducts: "My Lists",
  productDetailAddToFolder: "Add to list",
  productDetailRemoveFromFolder: "Remove from list",
  productDetailAddNewListButton: "Add new list",
  productDetailCreateListInput: "List name",

  myListsNoFolderSelected: "No folder selected to search",
  modalDeleteItemTitleFolder: "Delete List",
  modalDeleteItemTitleProduct: "Remove Product",

  libraryAdsTitle: "Search for ads",
  libraryAdsSearchTypeTitle: "Search Type",
  libraryAdsSearchTypeUnordered: "Unordered",
  libraryAdsSearchTypeExact: "Exact Phrase",
  libraryAdsSearchTerms: "Search Terms (max 100 characters)",
  libraryAdsStatusTitle: "Status",
  libraryAdsStatusAll: "All",
  libraryAdsStatusActive: "Active",
  libraryAdsStatusInactive: "Inactive",
  libraryAdsMediaTypeTitle: "Media Type",
  libraryAdsMediaTypeNone: "None",
  libraryAdsMediaTypeImage: "Image",
  libraryAdsCountryTitle: "Search by countries",
  libraryAdsPublisher: "Publisher Platforms",
  libraryAdsPublisherAudience: "Audience Network",
  libraryAdsLanguages: "Search by Languages",
  libraryAdsPagesIds: "Facebook Page ID's (separated by comma)",
  libraryAdsStartDate: "Delivery date max (DD/MM/YYYY)",
  libraryAdsEndDate: "Delivery date min (DD/MM/YYYY)",

  menuItemDevices: "Device Requests",
  menuItemWithdrawals: "Withdrawal Request",

  solveCaseModalTitle: "Solve Case",
  solveCaseModalStatus: "Solution",
  solveCaseModalStatusPlaceholder: "Select a solution",
  solveCaseModalObservation: "Observation",
  solveCaseModalReason: "Reason",
  solveCaseModalVoucher: "Attach supporting document",
  uploadFile: 'Upload File',
  fileSelected: 'No file has been selected',
  solveCaseModalObservationPlaceholder: "Briefly describe why the request is denied to the user (255 chars max)",

  solveCaseOptionAprove: "APPROVE",
  solveCaseOptionReject: "DECLINE",
  requestStatusAll: "Request Status (All)",
  requestStatusRevision: "Revision",
  requestStatusAllowed: "Allowed",
  requestStatusRejected: "Rejected",
  
  statusAll: "All",
  statusPending: "Pending",
  statusDeny: "Deny",
  statusApproved: "Approved",

  addAdminTitle: "Add Admin User",
  clientEmailInputPlaceholder: "Customer's email",

  supplierListTitle: "Suppliers",
  supplierListNoteTitle: "Note",
  supplierListNoteText: "Viraly has no contact with any supplier nor does it act as an intermediary, the suggestions shown in this section are based on the criteria selected by the team.",
  supplierCardYears: "Years",
  supplierCardResponse: "Response Time",
  supplierCardTransactions: "Transactions",
  supplierCardDelivery: "Delivery Rate",
  supplierCardPrice: "Price",

  adShowFilters: "Show Filters",
  adHideFilters: "Hide Filters",
  adCardFirstSeen: "First Seen",
  adCardLastSeen: "Last Seen",
  adCardComments: "Comments",
  adCardShares: "Shares",

  adsActionsSave: "Save current search",
  adsModalInputPlaceholder: "Search custom name",
  adsActionsSearchSelect: "Select search",
  adsActionsFavorites: "Show my favorites",

  adDetailsTitle: "Ad Detail",
  adDetailsSeeProduct: "See Product",
  adDetailsSeePost: "See AD",
  adDetailsCopyTitle: "Facebook Ad Copy",
  adDetailsEngageTitle: "Engagement",
  adDetailsAgeTitle: "Audience Age",
  adDetailsGenderTitle: "Audience Gender",
  adDetailsGenderMale: "male",
  adDetailsGenderFemale: "female",
  adDetailsCountryTitle: "Audience Countries",
  adDetailsRunTitle: "Ad's runway",
  adDetailsDays: "days",

  adListFrom: "From",
  adListTo: "to",
  adListOrderTotalReactions: "Total Reactions",

  // Retiros
  createWithdrawalAccount: "Account",
  createWithdrawalButton: "Withdraw",
  createWithdrawalPlatform: "platform",
  createWithdrawalSuccess: "Successful withdrawal request",
  createWithdrawalTitle: "Request withdrawal",
  createWithdrawalValue: "Value",
  unordered: "Unsorted",
  voucherText: "Download voucher",
  withdrawalOrderByAsc: "Ascending",
  withdrawalOrderByAscUpdate: "Old update date",
  withdrawalOrderByDesc: "Descending",
  withdrawalOrderByDescUpdate: "Recent update date",

  // Referidos
  cancelled: "Cancelled",
  payOut: "Available",
  pending: "Pending",
  finalized: "Finalized",
  referralFilter: "Filter",
  referralFilterEndDate: "To date",
  referralFilterStartDate: "From date",
  referralFilterStatus: "Filter by status",
  referralHeaderActiveUntil: "Active Until",
  referralHeaderBillAmount: "Amount",
  referralHeaderCommission: "Commission",
  referralHeaderCreatedAt: "Created At",
  referralHeaderDate: "Date",
  referralHeaderName: "Name",
  referralHeaderPayoutAt: "Payout At",
  referralHeaderStatus: "Status",
  referralCodeValidationMin: "The referral code must be at least 3 characters long",
  referralCodeValidationEmpty: "The referral code is required",
  referralTotalAvailableBalance: "Total available balance",
  referralRecordsFound: "records found",
  referralTotalPendingBalance: "Total pending balance",
  modifyReferralCodeTitle: "Modify referral Code",

  // Balance
  referralBalanceTooltip: "Available balance",
  referralTotalBalanceTooltip: "Total balance",

  // Platform
  messageEmptyPlatform: "You must select a platform",
  messageEmptyIdentifier: "You must enter at least one identifier",
  messagePlatformDuplicate: "This platform was already added earlier",
  platformNameField: "Name",
  platformSiteUrlField: "Site URL",
  platformImageUrlField: "Image URL",
  productsFilterPlatformField: "Filter by Platform",

  // CTA Filter
  shopNow: "Shop now",
  learnMore: "Learn more",
  messagePage: "Message page",
  signUp: "Sign up",
  getOffer: "Get offer",
  watchMore: "Watch more",
  bookNow: "Book now",
  downloadUndefined: "Download undefined",
  download: "Download",
  open: "Open",
  applyNow: "Apply now",
  likePagelike: "Like pagelike",
  subscribeUndefined: "Subscribe undefined",
  whatsappMessage: "Whatsapp message",
  getQuote: "Get quote",
  eventRsvp: "Event rsvp",
  donateNow: "Donate now",
  playGame: "Play game",
  contactUs: "contact us",
  getDirections: "Get directions",
  seeMenu: "See menu",
  listenNow: "Listen now",
  installApp: "Install app",
  buyTickets: "Buy tickets",
  buyNow: "Buy now",
  orderNow: "Order now",
  getShowtime: "Get showtime",
  callNow: "Call now",
  watchVideo: "Watch video",
  openLink: "Open link",
  requestTime: "Request time",
  findYour: "Find your",
  useApp: "Use app",
  likePage: "Like page",

  // Terms and conditions
  termsTitle: "Terms and conditions of use",
  termsIntro: "Welcome to Viraly, the ECOM BOOSTERS website located in the United States. By using our platform, you agree to the terms and conditions set forth below.",
  termsValidity: "Effective Date: May 8, 2023",
  termsItemOne: "Collection of User Information: Viraly collects personal information from the user, including name, username, phone number, emails, debit/credit card number, and password. By using our platform, the user agrees to the collection of this information. The information collected is used to improve the user experience and to process online payments. We are committed to protecting your privacy and security online.",
  termsItemTwo: "Viraly does not sell accounts with more than 3 different users using the same account. The violation of this term will lead to automatic account cancellation without agreement to return the money.",
  termsItemThree: "Advertising: Viraly does not display ads on the platform.",
  termsItemFour: "Tracking Technologies: We use technologies, such as Google Analytics, to collect information about your use of our platform. This information is used to improve your user experience and for internal analytical purposes. We do not use advertising or retargeting on our platform. In addition, we use two Facebook pixels to help us improve the quality of our products and services. We also use local storage to save information from your devices.",
  termsItemFive: "Social Media Providers: Viraly does not use social media providers on the platform.",
  termsItemSix: "Content: Viraly generates content for dropshipping and e-commerce products, which can be used by the user to publish it on their respective sites or stores and / or to use this information to their liking.",
  termsItemSeven: "Age restrictions: Viraly is not offered to children under the age of 13.",
  termsItemEight: "Contact: The user can contact Viraly at manager.viraly@gmail.com, at the telephone number +57 3215330715 or by visiting the informative site https://viraly.ai/.",
  termsItemNine: "Online payments: Viraly accepts online payments through Stripe's payment gateway. By using this payment gateway, the user agrees to Stripe's terms and conditions.",
  termsItemTen: "Recurring payments: Viraly accepts online payments through Stripe's payment gateway. Monthly recurring payments are accepted on our platform. By using our online payment services, you agree to be bound by Stripe's Terms and Conditions. For more information, visit their website.",
  termsItemEleven: "Responsibility: It is important to note that Viraly has no contact with any supplier or intermediary. The suggestions shown on this website are based on the criteria selected by the Viraly team and do not guarantee specific results.",
  termsFinalNote: "By using our platform, you agree to the terms and conditions set forth. If you have any questions or concerns, do not hesitate to contact us.",

  // Frequent questions
  questionOne: "Was my store not loaded correctly?",
  answerOne: 'Make sure you follow the step by step correctly explained in the video tutorial in the "my stores" tab. Verify that the name of your store is correct " it will always be the name of the myshopify domain. You must also validate that when copying and pasting the values there are no spaces left.',
  questionTwo: "It won't let me import my products to my shopify store?",
  answerTwo: 'Once you have registered your store correctly following the step by step video tutorial in the "my stores" tab verify that the import is successful, and go to your shopify profile and put the product PUBLIC so that it can be seen on your page.',
  questionThree: "I can't import the products because I don't have shopify?",
  answerThree: "In this case, our algorithm allows you to copy, paste and download all the content in a practical way to any content in a convenient way to any page you have, and you can only do it if you don't have a registered shopify you don't have a registered shopify store.",
  questionFour: "I can't find the customer service contacts?",
  answerFour: "You can contact us via whatsapp at +57 321 5330715 or by email at email manager.viraly@gmail.com.",
  questionFive: "I can't delete my store or my devices",
  answerFive: `To delete any registered device you must go to the "devices" tab and you will find each one of them registered, just click on the icon and that's it. In the case of the store it is the same procedure only that you go to the "my stores" section and click on the same icon. If this process is not effective what you should do is ask for help to WhatsApp customer service. The icon should look like the one shown below`,
  whyLeaveUs: "Why are you leaving us?",
  doesNotSatisfy: "Does not meet your needs.",
  fewProducts: "Very few weekly products.",
  doNotLikeTheContent: "You don't like the content that is uploaded.",
  other: "Other",
  writeTheReason: "Write the reason.",
  YouMustWriteValidation: "You have not written anything.",
  findProduct: "This product can be found on local dropshipping platforms in Colombia.",
};

export default enTranslations;