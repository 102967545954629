import { Lottie } from '@crello/react-lottie';
import { Box, useColorModeValue } from '@chakra-ui/react';

import loadingAnimation from '@assets/lotties/loading_page_animation.json';

const loadingLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
};

export const LoadingScreen = () => {
  const bgPage = useColorModeValue('gray.100', 'gray.800');

  return (
    <Box
      display="fixed"
      top="0"
      left="0"
      width="100%"
      height="100vh"
      bg={bgPage}
    >
      <Box
        backgroundColor="inherit"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Lottie config={loadingLottieOptions} height="350px" width="350px" />
      </Box>
    </Box>
  );
};
