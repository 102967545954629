import { Outlet, Navigate, useLocation } from "react-router-dom";

import {
  Grid,
  GridItem,
  Box,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import authImage from "@assets/images/test_login_image_2.jpg";
import logoBlack from "@assets/images/logo_black.png";
import logoWhite from "@assets/images/logo_white.png";

import { useAuth } from "@context/AuthContext";

export const AuthLayout = () => {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const { authState } = useAuth();

  const location = useLocation();

  const bgContainer = useColorModeValue("gray.200", "gray.800");
  const bgContent = useColorModeValue("white", "gray.700");

  const logoSrc = colorMode === "dark" ? logoWhite : logoBlack;

  const newLocation = (location.state as any)?.lastLocation
    ? (location.state as any)?.lastLocation
    : "/app/products";

  if (authState.isAuthenticated && !authState.isCheckingSession)
    return <Navigate to={newLocation} />;

  return (
    <Grid
      bg={bgContainer}
      height="100vh"
      templateColumns="repeat(10, 1fr)"
      templateRows="repeat(24, 1fr)"
      width="100%"
    >
      <GridItem
        rowStart={[1, 1, 2]}
        rowEnd={[25, 25, 24]}
        colStart={[1, 1, 2]}
        colEnd={[13, 13, 10]}
      >
        <Box
          bg={bgContainer}
          borderRadius={[0, 0, 4]}
          boxShadow={["base", "base", "xl"]}
          display="flex"
          height="100%"
          overflow="hidden"
          width="100%"
          position="relative"
        >
          <Box
            display={["none", "none", "block"]}
            width={["0", "0", "55%", "65%"]}
            position="relative"
          >
            <Image
              alt="Viraly Background Auth"
              fit="cover"
              height="100%"
              src={authImage}
              width="100%"
            />
            <Box
              alignItems="center"
              bg="rgba(0, 0, 0, 0.6)"
              display="flex"
              flexDirection="column"
              height="100%"
              position="absolute"
              top={0}
              width="100%"
            >
              <Text
                align="center"
                mt={40}
                as="h2"
                fontSize="5xl"
                color="whiteAlpha.900"
              >
                {t("loginWelcome")} <b>Viraly</b>
              </Text>
              <Text
                width="80%"
                align="center"
                as="h2"
                fontSize="2xl"
                color="whiteAlpha.900"
              >
                La herramienta perfecta para potenciar tu negocio!
              </Text>
            </Box>
          </Box>
          <Box
            bg={bgContent}
            overflowY="auto"
            px={12}
            py={4}
            width={["100%", "100%", "45%", "35%"]}
          >
            <Box
              alignItems="center"
              display="flex"
              flexDirection={["column", "row"]}
              height="auto"
              justifyContent={["center"]}
              mt="10px"
              width="100%"
            >
              <Image alt="Logo" maxWidth="200px" src={logoSrc} width="200px" />
            </Box>
            {/* Routes content */}
            <Box mt={[12, 4, 0]}>
              {/* <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  {t('maintenanceMessage')}
                </AlertDescription>
              </Alert> */}
              <Outlet />
            </Box>
          </Box>
        </Box>
      </GridItem>
    </Grid>
  );
};
