import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Auth0Client } from '@auth0/auth0-spa-js';

import { authReducer } from '@reducers/authReducer';
import { AsyncDispatch, wrapAsync } from '@utils/wrapAsync';

import { AuthAction, AuthStateInterface } from '@interfaces/AuthStateInterface';
import getDefaultProducType from '@utils/getDefaultProductType';

const auth0 = new Auth0Client({
  domain: process.env.REACT_APP_AUHT0_DOMAIN || '',
  client_id: process.env.REACT_APP_AUHT0_CLIENTID || '',
  audience: process.env.REACT_APP_AUHT0_AUDIENCE || '',
  redirect_uri: process.env.REACT_APP_AUHT0_REDIRECT_URI || '',
  cacheLocation: 'localstorage',
});

interface AuthContextType {
  auth0: Auth0Client;
  authState: AuthStateInterface;
  isUserAdmin: boolean;
  isCancelled: boolean;
  defaultTypeProduct: string;
  signInWithSocial: (provider: string) => Promise<void>;
  signUpWithSocial: (provider: string) => Promise<void>;
  dispatch: React.Dispatch<AuthAction>;
  asyncDispatch: AsyncDispatch<AuthAction>;
}

interface ValidateAuthProps {
  children: JSX.Element;
  requireAuth?: boolean;
}

const INITIAL_STATE: AuthStateInterface = {
  user: null,
  authType: '',
  isAuthenticated: false,
  isCheckingSession: false,
};

const { createContext, useContext, useEffect, useReducer, useMemo } = React;

export const AuthContext = createContext<AuthContextType>(null!);

export const useAuth = () => useContext(AuthContext);

export const getUserTokenAndHeader = async () => {
  let token = '';

  const authType = localStorage.getItem('__authtype__');

  if (authType === 'social') {
    const isAuthenticated = await auth0.isAuthenticated();

    if (!isAuthenticated) {
      token = '';
    } else {
      const tokenObtained = await auth0.getTokenSilently();
      token = tokenObtained;
    }
  }

  if (authType === 'password') {
    const userTokenInfo = localStorage.getItem('__token__');

    if (!userTokenInfo) {
      token = '';
    } else {
      const parsedData = JSON.parse(userTokenInfo);
      const tokenObtained = parsedData['@token'];
      token = tokenObtained;
    }
  }

  return { Authorization: `Bearer ${token}` };
};

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [authState, dispatch] = useReducer(authReducer, INITIAL_STATE);

  const asyncDispatch = useMemo(() => wrapAsync(dispatch), [dispatch]);

  const signInWithSocial = async (provider: string = 'google') =>
    await auth0.loginWithRedirect({
      connection: provider === 'google' ? 'google-oauth2' : 'facebook',
      display: 'popup',
      prompt: 'login',
      redirect_uri: `${process.env.REACT_APP_AUHT0_REDIRECT_URI}?type=login`,
    });

  const signUpWithSocial = async (
    provider: string = 'google',
    registerType: string = 'viraly'
  ) =>
    await auth0.loginWithRedirect({
      connection: provider === 'google' ? 'google-oauth2' : 'facebook',
      display: 'popup',
      prompt: 'login',
      redirect_uri: `${process.env.REACT_APP_AUHT0_REDIRECT_URI}?type=register&registerType=${registerType}`,
    });

  const isUserAdmin =
    authState.user?.role === 'admin' || authState.user?.role === 'super_admin';

  const isCancelled =
    authState.user?.subscription.statusPayment === 'cancelled';

  const defaultTypeProduct = authState.user
    ? getDefaultProducType(authState.user.subscription.allowedTypes)
    : 'facebook';

  const providerValue = {
    auth0,
    authState,
    isUserAdmin,
    isCancelled,
    defaultTypeProduct,
    signInWithSocial,
    signUpWithSocial,
    dispatch,
    asyncDispatch,
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const ValidateAuth = ({
  children,
  requireAuth = false,
}: ValidateAuthProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { authState } = useAuth();

  const isAuthenticated = authState.isAuthenticated;

  useEffect(() => {
    if (!isAuthenticated && requireAuth)
      navigate('/login', {
        replace: true,
        state: { from: location },
      });

    if (isAuthenticated && !requireAuth)
      navigate('/app/dashboard', {
        replace: true,
        state: { from: location },
      });
  }, [isAuthenticated, requireAuth, navigate, location]);

  return children;
};
