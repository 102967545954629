import recoveryFormModel from './recoveryFormModel';

export interface RecoveryFormInitialValuesInterface {
  [x: string]: any
};

const { formField: { email, password, confirm } } = recoveryFormModel;

const recoveryFormInitialValues: RecoveryFormInitialValuesInterface = {
  [email.name]: '',
  [password.name]: '',
  [confirm.name]: '',
};

export default recoveryFormInitialValues;