import { Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { SeparatorWithText } from "@components/SeparatorWithText";
import { HanldeSubmitProps } from "@hooks/useStepperForm";

import { FcGoogle } from "react-icons/fc";
import { useAuth } from "@context/AuthContext";

interface Props {
  handleNextStep: ({ values, actions, submitForm }: HanldeSubmitProps) => void;
}

export const SelectRegisterOption = ({ handleNextStep }: Props) => {
  const { signUpWithSocial } = useAuth();
  const { t } = useTranslation();

  const handleChangeStep = () => handleNextStep({ values: null });

  return (
    <Box mt={4}>
      <Button
        onClick={handleChangeStep}
        variant="solid"
        colorScheme="brand"
        isFullWidth
      >
        {t("signupEmail")}
      </Button>
    </Box>
  );
};
