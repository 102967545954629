import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const colors = {
  brand: {
    50: '#e4edf6',
    100: '#c2d2dd',
    200: '#9fb2c1',
    300: '#7c94a6',
    400: '#627e92',
    500: '#48687f',
    600: '#3d5b6f',
    700: '#2e485a',
    800: '#213645',
    900: '#10222e',
  },
};

const styles = {
  global: (props: any) => ({
    '::-webkit-scrollbar': {
      width: '10px',
      height: '7px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: mode('gray.200', 'gray.900')(props),
      borderRadius: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: mode('brand.200', 'brand.200')(props),
      borderRadius: '6px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: mode('brand.500', 'brand.500')(props),
    },
    '.ProseMirror': {
      '.ProseMirror-focused': {
        outline: 'none',
        border: 'none',
      },
    },
    label: {
      touchAction: 'none',
    },
  }),
};

const config: ThemeConfig = {
  initialColorMode: 'system',
  useSystemColorMode: false,
};

const theme = extendTheme({ config, colors, styles });

export default theme;
