export interface RegisterFormModelInterface {
  formId: string;
  formField: {
    [x: string]: { [y: string]: any }
  }
};

const registerFormModel = {
  formId: 'registerForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'firstNameField'
    },
    lastName: {
      name: 'lastName',
      label: 'lastNameField'
    },
    email: {
      name: 'email',
      label: 'emailField'
    },
    password: {
      name: 'password',
      label: 'passField'
    },
    confirm: {
      name: 'confirm',
      label: 'confirmField'
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'phoneNumberField'
    },
    
    countryCode: {
      name: 'countryCode',
      label: 'countryCodeField'

    },
    
    referralCode: {
      name: 'referralCode',
      label: 'referralCodeField'
    },
    terms: {
      name: 'terms',
      label: 'termsField'
    }
  }
};

export default registerFormModel;