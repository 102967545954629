import { FetchRest } from "@config/httpClient";

interface GetFoldersServiceProps {
  userId: string;
  lang: string;
};

export const getFoldersService = async (props: GetFoldersServiceProps) => {
  const { userId, lang } = props;

  const uri = `/products/get_folders?userId=${userId}`;

  const response = await FetchRest<GetFoldersServiceProps>({ uri, requireAuth: true });

  if (response.error) {
    const message = response.messages[lang];
    throw new Error(message);
  }

  return response.data;
};